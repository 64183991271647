import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "WB7-U2-P25-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page25/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: "Choose the correct words",
        color: "#00a23b",
        star: 1,
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          "At / First", //0
          "that / then", //1
          "On / At ", //0
          "then / first", //1
          "later / after", //0
          "finally / first ", //1
        ],
        answers: ["0-4", "1-0", "2-4", "3-0", "4-4", "5-0"],
        initialValue: [],
      },
      Layout: `
              <div>
                <div>After four hours, we   <b>first</b> / <b style="border: solid 2px #000000; border-radius: 50%; padding: 5px; "> finally </b>  said goodbye to all our friends.<div>
                <div><b>1.</b> <b><input id='0' type='Circle' /></b> we prepared the sandwiches for the party.</div>
                <div><b>2.</b> After <b><input id='1' type='Circle' /></b> we made some nice cold drinks.</div>
                <div><b>3.</b> <b><input id='2' type='Circle' /></b> seven o’clock our cousins arrived.</div>
                <div><b>4.</b> We ate some food and <b><input id='3' type='Circle' /></b> we had some drinks.</div>
                <div><b>5.</b> I talked to Kate and <b><input id='4' type='Circle' /></b> that I chatted to Mary.</div>
                <div><b>6.</b> At the end of a long evening, everybody <b><input id='5' type='Circle' /></b> went home </div>
              </div>
          `,
    },
  },
  2: {
    unit: "Unit 2",
    id: "WB7-U2-P25-E2",
    component: T6,
    inputSize: 130,
    exerciseKey: "img/FriendsPlus/Page25/E2/Key/answerKey.png",

    stylesTextInput: { fontSize: 25, textAlign: "center" },
    hintBox: [
      {
        src: [
          "After",
          "At",
          "Finally",
          "<s>First</s> ",
          "midnight",
          "that",
          "Then",
        ],
        borderColor: "#00a23b",
        width: "100%",
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title: "  Complete the text with the words.",
        color: "#00a23b",
        star: 2,
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <div><hintbox id="0"></hintbox></div>
                  <div style=" padding: 20px; margin-block: 30px">
                    Last New Year’s Eve, we had a lovely family party 
                    at our house. <i><b style="color: grey">First</b></i> my parents and I 
                    went to the supermarket. After <sup>1</sup> #
                    we came home and we spent the afternoon 
                    making a lot of amazing food.
                    <sup>2</sup> # 7:30 our visitors arrived. There 
                    were a lot of people: all my uncles, aunts and 
                    cousins. <sup>3</sup> # that, we sat at a big 
                    table and we ate all the food. <sup>4</sup> #
                    we played some really funny games.
                    <sup>5</sup> # , at <sup>6</sup> # , we all 
                    said ‘Happy New Year!’ and then we sang a 
                    special song
                  </div>
                </div>
            `,
        answer: ["that", "At", "After", "Then ", "Finally", "midnight"],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "WB7-U3-P25-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page25/E3/Key/answerKey.png",
    maxLength: 13,

    isHiddenCheck: true,
    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title:
          "  Read the notes about an International Day at Tom’s school and complete the text.You need to put the verbs in the past simple.",
        color: "#00a23b",
        star: 2,
      },
    ],
    questions: [
      {
        title: `
          <div style="position: relative; margin: 22px">
            <div><img src='img/FriendsPlus/Page25/E3/1.jpg' /></div>
            <div style="text-align: center">
              <div style=" position: absolute; top: 385px; left: 236px; "><input id='0' /></div>
              <div style=" position: absolute; top: 419px; left: 48px; "><input id='1' /></div>
              <div style=" position: absolute; top: 447px; left: 46px; "><input id='2' /></div>
              <div style=" position: absolute; top: 447px; left: 203px; "><input id='3' /></div>
              <div style=" position: absolute; top: 476px; left: 52px; "><input id='4' /></div>
              <div style=" position: absolute; top: 476px; left: 202px; "><input id='5' /></div>
              <div style=" position: absolute; top: 476px; left: 362px; "><input id='6' /></div>
              <div style=" position: absolute; top: 504px; left: 118px; "><input id='7' /></div>
              <div style=" position: absolute; top: 504px; left: 325px; "><input id='8' /></div>
              <div style=" position: absolute; top: 530px; left: 43px; "><input id='9' /></div>
              <div style=" position: absolute; top: 530px; left: 204px; "><input id='10' /></div>
              <div style=" position: absolute; top: 557px; left: 43px; "><input id='11' /></div>
              <div style=" position: absolute; top: 557px; left: 208px; "><input id='12' /></div>
              <div style=" position: absolute; top: 557px; left: 373px; "><input id='13' /></div>
              <div style=" position: absolute; top: 621px; left: 46px; "><input id='14' /></div>
              <div style=" position: absolute; top: 621px; left: 211px; "><input id='15' /></div>
              <div style=" position: absolute; top: 649px; left: 47px; "><input id='16' /></div>
              <div style=" position: absolute; top: 649px; left: 208px; "><input id='17' /></div>
              <div style=" position: absolute; top: 649px; left: 375px; "><input id='18' /></div>
              <div style=" position: absolute; top: 677px; left: 39px; "><input id='19' /></div>
              <div style=" position: absolute; top: 703px; left: 52px; "><input id='20' /></div>
              <div style=" position: absolute; top: 703px; left: 216px; "><input id='21' /></div>
              <div style=" position: absolute; top: 765px; left: 52px; "><input id='22' /></div>
              <div style=" position: absolute; top: 765px; left: 254px; "><input id='23' /></div>
              <div style=" position: absolute; top: 792px; left: 38px; "><input id='24' /></div>
              <div style=" position: absolute; top: 792px; left: 196px; "><input id='25' /></div>
              <div style=" position: absolute; top: 821px; left: 53px; "><input id='26' /></div>
              <div style=" position: absolute; top: 848px; left: 337px; "><input id='27' /></div>
              <div style=" position: absolute; top: 875px; left: 41px; "><input id='28' /></div>
              <div style=" position: absolute; top: 875px; left: 198px; "><input id='29' /></div>
              <div style=" position: absolute; top: 875px; left: 352px; "><input id='30' /></div>
              <div style=" position: absolute; top: 902px; left: 322px; "><input id='31' /></div>
              <div style=" position: absolute; top: 929px; left: 37px; "><input id='31' /></div>
              <div style=" position: absolute; top: 929px; left: 203px; "><input id='32' /></div>
            </div>
          </div>
        `,
        answer: [
          "Japan",
          "First",
          "Japanese",
          "story",
          "The",
          "Peach",
          "Boy",
          "10:00",
          "studied",
          "music",
          "from",
          "played",
          "an",
          "instrument",
          "After",
          "that",
          "learned",
          "about",
          "Japanese",
          "sport",
          "sumo",
          "wrestling",
          "Then",
          "prepared",
          "Japanese",
          "food",
          "sushi",
          "had",
          "a",
          "good",
          "time",
          "felt",
          "very",
          "happy",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "WB7-U3-P25-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1000, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          " Imagine you have an International Day at your school and you study one country.Write about your day.Use the text in exercise 3 to help you.",
        color: "#00a23b",
        star: 3,
      },
    ],
    questions: [
      {
        title: `
          <div><textarea id="0" rows="11"></textarea></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
