import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: {
    unit: "Unit 4",
    id: "WB7-U4-P27-E1",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page29/E1/Key/answerKey.png",
    maxLength: 16,

    textAlign: "center",
    isHiddenCheck: true,
    hintBox: [{
      src: [
        "reading",
        "was",
        "was",
        "<s>Was</s>",
        "Was",
        "wasn’t",
        "wasn’t",
        "were",
        "Were",
        "weren’t",
      ],
      width: "60%",
      borderColor: "#69d2e4",
    }],
    titleQuestion: [
      {
        num: "1",
        title:
          " Complete the table with the words.",
        color: "#69d2e4",
        star: 1
      },
    ],
    questions: [
      {
        title: `
        <div><hintbox id='0'></hintbox></div>
          <div style="position: relative; margin: 10px">
            <div><img style="width: 60%" src='img/FriendsPlus/Page29/E1/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 67px; left: 45px; "><input id='0' /></div>
              <div style=" position: absolute; top: 95px; left: 45px; "><input id='1' /></div>
              <div style=" position: absolute; top: 55px; left: 323px; "><input id='2' /></div>
              <div style=" position: absolute; top: 194px; left: 5px; "><input id='3' /></div>
              <div style=" position: absolute; top: 222px; left: 97px; "><input id='4' /></div>
              <div style=" position: absolute; top: 276px; left: -41px; "><input id='5' /></div>
              <div style=" position: absolute; top: 194px; left: 245px; "><input id='6' /></div>
              <div style=" position: absolute; top: 222px; left: 340px; "><input id='7' /></div>
              <div style=" position: absolute; top: 276px; left: 200px; "><input id='8' /></div>
            </div>
          </div>
        `,
        answer: ["Was", "Were", "reading", "was", "was", "were", "wasn't", "wasn't", "weren't",]
      }
    ],
  },
  2: {
    unit: "Unit 1",
    id: "WB7-U1-P9-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    inputSize: 575,
    exerciseKey: "img/FriendsPlus/Page29/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          " Order the words to write past continuous questions.<br/>Then write affirmative(✔) or negative(✘) short answers.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
            <div style="font-size: 22px">
              <div>at / looking / this photo / you / last night / Were / ? ✔<br /><u style="text-decoration: dotted underline; color: grey"><i><b>Were you looking at this photo last night? </br>Yes, I was. </b></i>&emsp;</u></div>
              <div><b>1</b> you / Were / yesterday / studying / ? ✘<br />#<br />#</div>
              <div><b>2</b> at 3:30 / raining / Was / it / ? ✔<br />#<br />#</div>
              <div><b>3</b> we / at 7:00 / having / Were / dinner / ? ✘<br />#<br />#</div>
              <div><b>4</b> playing / they / earlier / Were / a game / ? ✔<br />#<br />#</div>
              <div><b>5</b> last year / in / Jim / Was / travelling / India / ? ✘<br />#<br />#</div>
            </div>
        `,
        answer: [
          "Were you studying yesterday?",
          "No, I wasn't.",
          "Was it raining at 3:30?",
          "Yes, it was.",
          "Were we having dinner at 7:00?",
          "No, we weren't.",
          "Were they playing a game earlier?",
          "Yes, they were.",
          "Was Jim travelling in India last year?",
          "No, he wasn't.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "WB7-U4-P27-E1",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 25, borderBottom: "0.5px dotted" },
    inputSize: 180,
    exerciseKey: "img/FriendsPlus/Page29/E3/Key/answerKey.png",
    maxLength: 16,

    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title:
          " Complete the text using the past simple or past continuous form of the verbs.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
          <div style="box-shadow: -5px 5px 5px rgb(141, 139, 139);background: linear-gradient(to right, rgb(254, 239, 208) , rgb(254, 219, 152); ); width: 561px">
            <div><img src='img/FriendsPlus/Page29/E3/1.jpg' /></div>
            <div style="padding: 15px; font-size: 20px">
              This time last year, I <b style="color: grey">was working</b> (work) 
              for a wildlife website. I <span style="display: inline-block"><sup>1</sup> # </span>
              (travel) in Asia with two other photographers and we 
              <span style="display: inline-block"><sup>2</sup> #</span> (look) for wild animals. We <span style="display: inline-block"><sup>3</sup> #</span> (walk) through the 
              rainforest one day when we <span style="display: inline-block"><sup>4</sup> #</span>
              (hear) a noise. We quickly <span style="display: inline-block"><sup>5</sup> #</span>
              (go) behind a tree and I <span style="display: inline-block"><sup>6</sup> #</span>
              (say), ‘Let’s wait here for a minute.’
              While we <span style="display: inline-block"><sup>7</sup> #</span> (wait), a big tiger 
              suddenly <span style="display: inline-block"><sup>8</sup> #</span> (jump) out from 
              some bushes. I quickly <span style="display: inline-block"><sup>9</sup> #</span>
              (take) this photo while it <span style="display: inline-block"><sup>10</sup> #</span>
              (stand) very close to us. It <span style="display: inline-block"><sup>11</sup> #</span>
              (not rain) so it <span style="display: inline-block"><sup>12</sup> #</span> (be) easy to 
              get a good photo. The tiger then 
              <span style="display: inline-block"><sup>13</sup> #</span> (run) back into the 
              rainforest and we <span style="display: inline-block"><sup>14</sup> #</span>
              (not see) it again
            </div>
          </div>
        `,
        answer: [
          "was travelling", "were looking ", "were walking ", "heard", "went", "said", "were waiting",
          "jumped", "took", "was standing ", "wasn’t raining", "was", "ran", "didn’t see",
        ]
      }
    ],
  },
  4: {
    unit: "Unit 4",
    id: "WB7-U4-P27-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1000, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Complete the sentences using the past simple or past continuous and your own ideas.",
        color: "#69d2e4",
        star: 3
      },
    ],
    questions: [
      {
        title: `
          <div>I saw a squirrel while <span style="color: grey; font-weight: bold">I was running in the park.</span></div>
          <div>
            <div style="display: flex"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>1</b></div><div><textarea id="0" rows="2"> I took some nice photos while  </textarea></div></div>
            <div style="display: flex; margin-top: -27px;"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>2</b></div><div><textarea id="1" rows="2"> My friend was walking to school when </textarea></div></div>
            <div style="display: flex; margin-top: -27px;"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>3</b></div><div><textarea id="2" rows="2"> The students found some money while </textarea></div></div>
            <div style="display: flex; margin-top: -27px;"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>4</b></div><div><textarea id="3" rows="2"> I was doing my homework when </textarea></div></div>
            <div style="display: flex; margin-top: -27px;"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>5</b></div><div><textarea id="4" rows="2"> My phone rang while </textarea></div></div>
            <div style="display: flex; margin-top: -27px;"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>6</b></div><div><textarea id="5" rows="2"> We were all laughing when </textarea></div></div>
          </div>
        `,
        answer: [],
      },
    ],
  },



}

export default json;