import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P65-E1",
    component: T6,
    questionImage: [],
    inputSize: 200,
    exerciseKey: "img/FriendsPlus/Page65/E1/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'center' },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Complete the table with the comparative and superlative form of the adjectives.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
              <table style="text-align: center">
                <tr style="border: 1px solid black; padding: 10px">
                  <td style="border: 1px solid black; padding: 10px"><b>Adjective</b></td>
                  <td style="border: 1px solid black; padding: 10px"><b>Comperative</b></td>
                  <td style="border: 1px solid black; padding: 10px"><b>Superlative</b></td>
                </tr>
                <tr style="border: 1px solid black; padding: 10px">
                  <td style="border: 1px solid black; padding: 10px">clean</td>
                  <td style="border: 1px solid black; padding: 10px"><b>cleaner</b></td>
                  <td style="border: 1px solid black; padding: 10px"><b>the cleanest</b></td>
                </tr>
                <tr style="border: 1px solid black; padding: 10px">
                  <td style="text-align: left; border: 1px solid black; padding: 10px"><b>1</b>&emsp; rich</td>
                  <td style="border: 1px solid black; padding: 10px">#</td>
                  <td style="border: 1px solid black; padding: 10px">#</td>
                </tr>
                <tr style="border: 1px solid black; padding: 10px">
                  <td style="text-align: left; border: 1px solid black; padding: 10px"><b>2</b>&emsp; lucky</td>
                  <td style="border: 1px solid black; padding: 10px">#</td>
                  <td style="border: 1px solid black; padding: 10px">#</td>
                </tr>
                <tr style="border: 1px solid black; padding: 10px">
                  <td style="text-align: left; border: 1px solid black; padding: 10px"><b>3</b>&emsp; difficult</td>
                  <td style="border: 1px solid black; padding: 10px">#</td>
                  <td style="border: 1px solid black; padding: 10px">#</td>
                </tr>
                <tr style="border: 1px solid black; padding: 10px">
                  <td style="text-align: left; border: 1px solid black; padding: 10px"><b>4</b>&emsp; bad</td>
                  <td style="border: 1px solid black; padding: 10px">#</td>
                  <td style="border: 1px solid black; padding: 10px">#</td>
                </tr>
                <tr style="border: 1px solid black; padding: 10px">
                  <td style="text-align: left; border: 1px solid black; padding: 10px"><b>5</b>&emsp; exciting</td>
                  <td style="border: 1px solid black; padding: 10px">#</td>
                  <td style="border: 1px solid black; padding: 10px">#</td>
                </tr>
                <tr style="border: 1px solid black; padding: 10px">
                  <td style="text-align: left; border: 1px solid black; padding: 10px"><b>6</b>&emsp; wet</td>
                  <td style="border: 1px solid black; padding: 10px">#</td>
                  <td style="border: 1px solid black; padding: 10px">#</td>
                </tr>
                <tr style="border: 1px solid black; padding: 10px">
                  <td style="text-align: left; border: 1px solid black; padding: 10px"><b>7</b>&emsp; boring</td>
                  <td style="border: 1px solid black; padding: 10px">#</td>
                  <td style="border: 1px solid black; padding: 10px">#</td>
                </tr>
                <tr style="border: 1px solid black; padding: 10px">
                  <td style="text-align: left; border: 1px solid black; padding: 10px"><b>8</b>&emsp; kind</td>
                  <td style="border: 1px solid black; padding: 10px">#</td>
                  <td style="border: 1px solid black; padding: 10px">#</td>
                </tr>
              </table>
          `,
        answer: [
          "richer", "the richest",
          "luckier", "the luckiest",
          "more difficult", "the most difficult",
          "worse", "the worst",
          "more exciting", "the most exciting",
          "wetter", "the wettest",
          "more boring", "the most boring",
          "kinder", "the kindest",
        ],
      },
    ],
  },
  2: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P65-E2",
    component: T6,
    questionImage: [],
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page65/E2/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'center' },
    titleQuestion: [
      {
        num: "2",
        title:
          "Complete the sentences with the most suitable comparison of the form of the adjectives.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <div>Leonardo da Vinci was one of <u style="text-decoration: dotted underline;color: grey">&emsp;<b>the most intelligent</b>&emsp;</u>  (intelligent) people of all time.</div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> Is that # (big) building in the world?</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> This photo is # (good) than that old picture.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> Were you # (fast) swimmer in the race?</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; ">  I’m # (creative) than my sister.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; ">  This is # (bad) book in the library!</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; ">  Maths is # (easy) than French.</div></div>
              </div>
          `,
        answer: [
          "the biggest ",
          "better than ",
          "the fastest ",
          "more creative",
          "the worst",
          "easier",
        ],
      },
    ],
  },


  3: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P65-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 22 },
    inputSize: 625,
    exerciseKey: "img/FriendsPlus/Page65/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "Write sentences with the most suitable comparison of the adjectives.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div>I / tall / my mother <br /><u style="text-decoration: dotted underline; color: grey"><i><b>I’m taller than my mother.</b></i>&emsp;</u></div>
              <div><b>1 &emsp;</b> she / not / fast / the other players in the team  <br /> &emsp; #</div>
              <div><b>2 &emsp;</b> cars / slow / planes  <br /> &emsp; #</div>
              <div><b>3 &emsp;</b> you / friendly / person at this school <br /> &emsp; #</div>
              <div><b>4 &emsp;</b> my brother / polite / pie <br /> &emsp; #</div>
              <div><b>5 &emsp;</b> he / different / the other runners / in the class <br /> &emsp; #</div>
              <div><b>6 &emsp;</b> Jane / not look / her sister Karen / because Karen / tall  <br /> &emsp; #</div>
            </div>
        `,
        answer: [
          "She isn't faster than other players in the team.",
          "Cars are slower than planes.",
          "You're the friendliest person at this school.",
          "My brother is as polite as pie.",
          "He's different from the other runners in the class.",
          "Jane doesn't look like her sister because Karen is taller.",
        ],
      },
    ],
  },

  4: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P65-E4",
    component: T6,
    questionImage: [],
    inputSize: 360,
    exerciseKey: "img/FriendsPlus/Page65/E4/Key/answerKey.png",
    stylesTextInput: { marginInline: '1px', fontSize: 22, textAlign: 'center', transform: 'rotate(-1deg)', borderBottom: "none" },

    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Complete the text with can, can’t, could, couldn’t and the phrases in the table.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div style="position: relative">
                  <div><img src='img/FriendsPlus/Page65/E4/1.jpg' /></div>
                  <div style=" position: absolute; top: 399px; left: 19px; "><input id='0' /></div>
                  <div style=" position: absolute; top: 427px; left: 58px; "><input id='1' /></div>
                  <div style=" position: absolute; top: 484px; left: -39px; "><input id='2' /></div>
                  <div style=" position: absolute; top: 542px; left: -49px; "><input id='3' /></div>
                  <div style=" position: absolute; top: 597px; left: -44px; "><input id='4' /></div>
                </div>
          `,
        answer: [
          "can speak English and French",
          "could play football well",
          "can't run very fast",
          "couldn't cook",
          "can bake cakes",
        ],
      },
    ],
  },

  5: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P65-E5",
    component: T6,
    questionImage: [],
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page65/E5/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'center' },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "5",
        title:
          "Complete the questions with how and one of the adjectives in brackets.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <div><u style="text-decoration: dotted underline;color: grey">&emsp;<b> How far </b>&emsp;</u>  can you swim? (far / high / many)</div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; ">  # are your teachers? (often / strict / long)</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; ">  # cousins have you got? (much / many / old)</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; ">  # tea do you drink? (cold / hot / much)</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; ">  # do they go running? (often / long / heavy)</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; ">  # is your best friend? (far / horrible / tall)</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; ">  # can an elephant run? (fast / heavy / scary) </div></div>
              </div>
          `,
        answer: [
          "How strict ",
          "How many",
          "How much",
          "How often",
          "How tall",
          "How fast",
        ],
      },
    ],
  },
}

export default json;