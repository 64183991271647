import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: {
    unit: "Unit 7",
    id: "WB7-U7-P49-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page49/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Choose the correct words.",
        color: "#00a23b",
        star: 1
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          "Her / She",
          "this / them",
          "us / them",
          "Their / They",
          " it / them",
          "my / me",
          "his / your",
          "Them / They",
        ],
        answers: ['0-4', '1-0', '2-0', '3-0', '4-2', '5-4', '6-0', '7-4'],
        initialValue: [],
      },
      Layout: `
              <div>
                <div>I’m not really into boy bands. I don’t like <b>they</b> / <b style="border: solid 2px #000000; border-radius: 50%; padding: 5px; ">them</b> .<div>
                <div><b>1.</b> Taylor Swift is a fantastic singer. <b><input id='0' type='Circle' /></b> ’s got a wonderful voice.</div>
                <div><b>2.</b> If you like a good folk song, you’ll love <b><input id='1' type='Circle' /></b>. It’s great.</div>
                <div><b>3.</b> Amelia and I are going to start a band. Karen is going to sing with <b><input id='2' type='Circle' /></b>.</div>
                <div><b>4.</b> Those musicians are from Brazil. <b><input id='3' type='Circle' /></b> music is great to dance to.</div>
                <div><b>5.</b> The video wasn’t very exciting. I wasn’t interested in <b><input id='4' type='Circle' /></b></div>
                <div><b>6.</b> Can you buy <b><input id='5' type='Circle' /></b> a ticket for the concert?</div>
                <div><b>7.</b> Mark Ronson is cool and <b><input id='6' type='Circle' /></b> music is always interesting.</div>
                <div><b>8.</b> The fans will like the concert. <b><input id='7' type='Circle' /></b>’ll have a great time.</div>
              </div>
          `,
    },
  },
  2: {
    unit: "Unit 7",
    id: "WB7-U7-P49-E2",
    component: T6,
    questionImage: [],
    inputSize: 100,
    exerciseKey: "img/FriendsPlus/Page49/E2/Key/answerKey.png",

    stylesTextInput: { marginInline: '10px', fontSize: 24, textAlign: 'center' },
    titleQuestion: [
      {
        num: "2",
        title:
          "Complete the sentences with the words.",
        color: "#00a23b",
        star: 2
      },
    ],
    hintBox: [{
      src: [
        "He",
        "her",
        "him",
        "I",
        "it",
        "my",
        "them",
        "<s>We</s>",
        "your",
      ],
      width: "558px",
      borderColor: "#00a23b",
    }],
    questions: [
      {
        title: `
              <div>
                <hintbox id="0"></hintbox>
                <div>Ann and I like Little Mix <u style="text-decoration: dotted underline;color: grey">&emsp;<b>We </b>&emsp;</u>  love their songs.</div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> This hit is good. I’m going to download # </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> Coldplay are a great band. # love their music.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> John is a fantastic DJ. Do you know # ?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> ‘Hi Ed! How are you? Is that # new guitar?’</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> We’re into Bruno Mars. # ’s wonderful. </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> Katy Perry is always amazing. You should watch # new music video.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>7. </b></div><div style="margin-left: 30px; flex: 1; "> Those singers are cool. Do you like # ?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>8. </b></div><div style="margin-left: 30px; flex: 1; "> I’m a big fan of Sam Smith. He’s # favourite singer.</div></div>
              </div>
          `,
        answer: [
          "it",
          "I",
          "him",
          "your",
          "He",
          "her",
          "them",
          "my",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "WB7-U7-P49-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    inputSize: 510,
    exerciseKey: "img/FriendsPlus/Page49/E3/Key/answerKey.png",
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "  Read the notes and complete the text.",
        color: "#00a23b",
        star: 2
      },
    ],
    questions: [
      {
        title: `
          <div style="display: flex;">
            <div style="flex:1;">
              <img src='img/FriendsPlus/Page49/E3/1.jpg' />
              <br/><br/>
              <div><b>1</b>&ensp;#</div>
              <div><b>2</b>&ensp;#,</div>
              <div>&emsp;#</div>
              <div><b>3</b>&ensp;#</div>
              <div><b>4</b>&ensp;#</div>
              <div><b>5</b>&ensp;#</div>
              <div><b>6</b>&ensp;#</div>
              <div><b>7</b>&ensp;#</div>
            </div>
            <div style="flex:1;"><img src='img/FriendsPlus/Page49/E3/2.jpg' /></div>
          </div>
              <div><b>8</b>&ensp;#,#</div>
              <div><b>9</b>&ensp;<input width="600px"/></div>
              <div style="margin-left: -13px;"><b>10</b>&ensp;<input width="600px"/></div>
        `,
        answer: [
          `synthpop`,
          `you like catchy electronic music`, ` you'll love this song`,
          `vocals by Australian singer Conrad Sewell`,
          `want to listen to this song again`,
          `How long will I love you?`,
          `Ellie Goulding`,
          `pop`,
          `you're into gentle pop songs`, ` then this is for you`,
          `Ellie Goulding's amazing vocals and piano playing`,
          `is Ellie Goulding at her best`,
        ]
      }
    ],
  },

  4: {
    unit: "Unit 7",
    id: "WB7-U7-P49-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Write a song review of two different types of song.Use the reviews in exercise 3 to help you.",
        color: "#00a23b",
        star: 3
      },
    ],
    questions: [
      {
        title: `
          <div><textarea id="0" rows="11"></textarea></div>
        `,
        answer: [],
      },
    ],
  },
}

export default json;