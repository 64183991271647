import React from 'react'
import styles from './internetsearch.module.css'
import ReactHtmlParser from "react-html-parser";
export default function InternetSearch({ content }) {
  return (
    <div className={styles.container}>
      <fieldset className={styles.fieldset}>
        <legend className={styles.legend}><i className={["fas fa-mouse-pointer", styles.legend_pointer].join(' ')}></i><span className={styles.legend_internet}>Internet</span > <span className={styles.legend_search}>Search</span></legend>
        {/* <b>Did you know ...</b><br />
        <i>The first vlog, or video blog, appeared on the
          internet in 2000.</i>
        <br />
        Find Zoella’s real name and what she vlogs about. */}
        {ReactHtmlParser(content)}
      </fieldset>
    </div >
  )
}
