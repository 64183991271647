import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import MC2 from '../../components/ExcerciseTypes/MultipleChoice/MC2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: { // Exercise num
    unit: 'Unit 7',
    id: 'WB7-U7-P48-E1',
    audio: '',
    video: '',
    component: MC2,
    exerciseKey: 'img/FriendsPlus/Page48/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: '   Read the article. Choose the correct answer.',
        color: '#dc2c39',
        star: 1
      }
    ],
    titleImage_2: 'img/FriendsPlus/Page48/E1/1.jpg',
    imgSize: '100%',
    isQuestionVertical: true,
    isQuestionVerticalToImage: true,
    questions: [
      {
        answers: [
          { image: "", isCorrect: false, right: "", text: "music experts" },
          { image: "", isCorrect: false, right: "", text: "adults" },
          { image: "", isCorrect: true, right: "", text: "young beginners " },
          { image: "", isCorrect: false, right: "", text: "teachers of teenagers " },
        ],
        title: 'The article is giving advice to # on how to write songs'
      },
    ]
  },
  2: {
    unit: "Unit 7",
    id: "WB7-U7-P48-E2",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page48/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          " Read the text again. Choose the correct words.",
        color: "#dc2c39",
        star: 2
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          "video / tune / verse", //0
          "should / can / mustn’t", //1
          "s before / after / at_the_same_time_as ", //2
          " bridge / chorus / title", //3
          "topic / lyrics / singer. ", //4
        ],
        answers: ['0-4', '1-4', '2-6', '3-6', '4-4'],
        initialValue: [],
      },
      Layout: `
              <div>
                <div><img src="img/FriendsPlus/Page48/E1/1.jpg"/></div>
                <div>When you write a song, you should think of the <b>chorus</b> / <b> poor / </b><b style="border: solid 2px #000000; border-radius: 50%; padding: 5px; ">title</b> <b>/ tune</b> first.</div>
                <div><b>1.</b> After you have got the title, choose a good <b><input id='0' type='Circle' /></b> for the song. </div>
                <div><b>2.</b> When you start writing the verses you <b><input id='1' type='Circle' /></b> start with the topic of the song.</div>
                <div><b>3.</b> You should write the chorus <b><input id='2' type='Circle' /></b> the verses.</div>
                <div><b>4.</b> Always put the <b><input id='3' type='Circle' /></b> one last time at the end. </div>
                <div><b>6.</b> If you aren’t happy with a song, you can always change the <b><input id='4' type='Circle' /></b></div>
              </div>
          `,
    },
  },
  3: {
    unit: "Unit 7",
    id: "WB7-U7-P48-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 23 },
    inputSize: 125,
    exerciseKey: "img/FriendsPlus/Page48/E3/Key/answerKey.png",
    textAlign: 'center',

    titleQuestion: [
      {
        num: "3",
        title:
          "  Read the text again. Correct the words.",
        color: "#dc2c39",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div><img src="img/FriendsPlus/Page48/E1/1.jpg"/></div>
                <div>Before you write the lyrics, you need to think of a <b><s>chorus</s></b>.<u style="text-decoration: dotted underline;">&emsp;<b>title</b>&emsp;</u> </div>
                <div><b>1.</b> The text gives example song titles for the topic of <b>love</b>. # </div>
                <div><b>2.</b> It’s important to have a <b>traditional</b> tune. # </div>
                <div><b>3.</b> It’s OK to begin the song with a small <b>shout</b>.  # </div>
                <div><b>4.</b> You can hear the <b>bridge</b> many times in a song. # </div>
              </div>
          `,
        answer: [" the future", "catchy", "laugh", "chorus"],
      },
    ],
  },

  4: {
    unit: "Unit 7",
    id: "WB7-U7-P48-E4",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { fontSize: 23 },
    inputSize: 800,
    exerciseKey: "img/FriendsPlus/Page48/E4/Key/answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title:
          "Answer the questions. Write complete sentences.",
        color: "#d01435",
        star: 3
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div>How many example titles does the writer give?   </div>
                <div><u style="text-decoration: dotted underline;color: grey">&emsp;<b>The writer gives two examples.</b>&emsp;</u></div>
                <div><b>1.</b> If you play the guitar, how can you find a good tune for your song?  </div>
                <div>#</div>
                <div><b>2.</b> If you play the guitar, how can you find a good tune for your song? </div>
                <div>#</div>
                <div><b>3.</b> How is the ‘bridge’ different from the rest of the song?  </div>
                <div>#</div>
                <div><b>4.</b> Do you think it’s easy to write a song? Why / Why not? </div>
                <div>#</div>
              </div>
          `,
        answer: [
          "You can chose three notes and play them again and again.",
          "The title of the song.",
          "It can say something new about the topic and it can have a different rhythm.",
          "",
        ],
      },
    ],
  },

  5: {
    unit: "Unit 7",
    id: "WB7-U7-P48-E5",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 23 },
    inputSize: 100,
    exerciseKey: "img/FriendsPlus/Page48/E5/Key/answerKey.png",
    textAlign: 'center',

    titleQuestion: [
      {
        num: "5",
        title:
          "<b style='color: #d01435;'>VOCABULARY PLUS </b> Complete the sentences.",
        color: "#d01435",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div>I won the competition! It wasn’t a dream, it was  <u style="text-decoration: dotted underline;">&emsp;<b>reality</b>&emsp;</u> .</div>
                <div><b>1.</b> ‘She isn’t good at dancing.’ ‘No, but she’s an amazing singer. She’s got a beautiful  # </div>
                <div><b>2.</b> ‘It’s very sunny today.’ ‘Yes, it’s too # – I need my sunglasses!’ </div>
                <div><b>3.</b> Two years ago, nobody knew the name of that actress. Now she’s a Hollywood # </div>
                <div><b>4.</b> The band had a big # last summer. The song was really popular.</div>
              </div>
          `,
        answer: [" voice", "bright", "star", "hit"],
      },
    ],
  },
}

export default json;