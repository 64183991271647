import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'WB7-U6-P40-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page40/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: "1",
        title:
          "Look at the photos. Complete the crossword with some of the words in the box. Then find the secret word.",
        color: "rgb(245, 128, 36)",
        star: 1
      },
    ],
    inputSize: 170,
    hintBox: [{
      src: [
        `compass`, `first-aid kit`, `knife`, `lighter`, `<s>map</s>`, `mirror`,
        `rope`, `sleeping bag`, `tent`, `torch`, `water bottle`,
      ],
      width: "530px",
      borderColor: "#f58023",
    }],
    questions: [
      {
        title: `
          <div style="display: flex; margin: 10px">
            <div><img style="width:100%" src='img/FriendsPlus/Page40/E1/1.jpg' /></div>
            <div>
              <div><hintbox id='0'></hintbox></div>
              <div><b>1. &ensp; map</b></div>
              <div><b>2.</b> #</div>
              <div><b>3.</b> #</div>
              <div><b>4.</b> #</div>
              <div><b>5.</b> #</div>
              <div><b>6.</b> #</div>
              <div>The secret word is: #</div>
            </bdiv>
          </div>
        `,
        answer: [
          `lighter`, `torch`, `water bottle`, `compass`, `rope`,
          `mirror`,
        ]
      }
    ],
  },
  2: {
    unit: "Unit 6",
    id: "WB7-U6-P40-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page40/E2/Key/answerKey.png",
    maxLength: 16,

    isHiddenCheck: true,
    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title:
          "  Do the quiz. Use the words from the box in exercise 1.",
        color: "rgb(245, 128, 36)",
        star: 2
      },
    ],
    hintBox: [{
      src: [
        `compass`, `first-aid kit`, `knife`, `lighter`, `<s>map</s>`, `mirror`,
        `rope`, `sleeping bag`, `tent`, `torch`, `water bottle`,
      ],
      width: "630px",
      borderColor: "#f58023",
    }],
    questions: [
      {
        title: `
              <div><hintbox id='0'></hintbox></div>
          <div style="position: relative; margin: 10px ; width: 553px;">
            <div><img src='img/FriendsPlus/Page40/E2/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 102px; left: 285px; "><input id='0' /></div>
              <div style=" position: absolute; top: 159px; left: 24px; "><input id='1' /></div>
              <div style=" position: absolute; top: 216px; left: 25px; "><input id='2' /></div>
              <div style=" position: absolute; top: 272px; left: 56px; "><input id='3' /></div>
              <div style=" position: absolute; top: 301px; left: 328px; "><input id='4' /></div>
              <div style=" position: absolute; top: 330px; left: 285px; "><input id='5' /></div>
              <div style=" position: absolute; top: 358px; left: 303px; "><input id='6' /></div>
              <div style=" position: absolute; top: 410px; left: 22px; "><input id='7' /></div>
            </div>
          </div>
        `,
        answer: [
          "mirror",
          "compass",
          "water bottle",
          "tent",
          "torch",
          "knife",
          "sleeping bag",
          "first-aid kit",
        ]
      }
    ],
  },
  3: { // Exercise num
    unit: 'Unit 6',
    id: 'SB5-U6-P40-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page40/E3/Key/answerKey.png',
    titleQuestion: [
      {
        num: "2",
        title:
          `<audioimage name="0.6"></audioimage> Josh and Matt are on the Desert Challenge course.It’s night time.What things in the picture do you think they will talk about? Then listen and tick what you hear.`,
        color: "rgb(245, 128, 36)",
        star: 2
      },
    ],
    selectStyle: { color: '#00ADFE', width: 76, textAlign: 'center', fontSize: 20, },
    selectInputSize: 'large', // large, middle, small
    selectOptionValues: [
      "tick",
      "not",
    ],
    questionImage: [],
    questions: [
      {
        title: `
					<div style='display: flex; justify-content: center; width: 1100px; position: relative; '>
            <div><img src='img/FriendsPlus/Page40/E3/1.jpg' /></div>
            <div style=" position: absolute; top: 428px; left: 515px; "><select id='0' /></select></div>
						<div style=" position: absolute; top: 472px; left: 772px; "><select id='1' /></select></div>
						<div style=" position: absolute; top: 335px; left: 800px; "><select id='2' /></select></div>
						<div style=" position: absolute; top: 224px; left: 626px; "><select id='3' /></select></div>
						<div style=" position: absolute; top: 548px; left: 470px; "><select id='4' /></select></div>
						<div style=" position: absolute; top: 404px; left: 290px; "><select id='5' /></select></div>
						<div style=" position: absolute; top: 596px; left: 630px; "><select id='6' /></select></div>
					</div>
				`,
        answer: [
          "not", "tick", "tick", "not", "tick", "tick", "tick",

        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "WB7-U5-P40-E4",
    audio: "Audios/1-06 TA 7 Friends Plus Workbook.mp3",
    video: "",
    component: T6,
    stylesTextInput: { fontSize: 23 },
    inputSize: 800,
    exerciseKey: "img/FriendsPlus/Page40/E4/Key/answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title:
          "<audioimage name='06'></audioimage>Listen again and write <i>true</i> or <i>false</i>.Then correct the false sentences.<br/>Fill in a space for the true answer.",
        color: "rgb(245, 128, 36)",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div style="display: flex">
                  <div>Matt hears a noise inside the tent.</div>
                  <div style="margin-left: auto"><u style="text-decoration: dotted underline;color: grey;"> false</div>
                </div>
                <div><u style="text-decoration: dotted underline;color: grey"><b>Matt hears a noise outside the tent.</b>&emsp;</u></div>
                <div style="display: flex">
                  <div><b>1.</b> Josh can hear the noise.  </div>
                  <div style="margin-left: auto"><input width='80px'></div>
                </div>
                <div>#</div>
                <div style="display: flex">
                  <div><b>2.</b> The other people are all asleep.  </div>
                  <div style="margin-left: auto"><input width='80px'></div>
                </div>
                <div>#</div>
                <div style="display: flex">
                  <div><b>3.</b> Matt goes outside.  </div>
                  <div style="margin-left: auto"><input width='80px'></div>
                </div>
                <div>#</div>
                <div style="display: flex">
                  <div><b>4.</b> Matt’s leg hurts  </div>
                  <div style="margin-left: auto"><input width='80px'></div>
                </div>
                <div>#</div>
                <div style="display: flex">
                  <div><b>5.</b> The noise came from the fire.  </div>
                  <div style="margin-left: auto"><input width='80px'></div>
                </div>
                <div>#</div>
                <div style="display: flex">
                  <div><b>6.</b> The boys have got a really late start tomorrow.  </div>
                  <div style="margin-left: auto"><input width='80px'></div>
                </div>
                <div>#</div>
              </div>
          `,
        answer: [
          "false", "Josh can't hear the noise.",
          "true", "none",
          "true", "none",
          "false", "Matt's finger hurts.",
          "true", "none",
          "false", "The boys have got a really early start in the morning",
        ]
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "WB7-U6-P40-E5",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1000, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "5",
        title:
          "Complete the sentences with your own ideas.",
        color: "rgb(245, 128, 36)",
        star: 3
      },
    ],
    questions: [
      {
        title: `
          <div>A rope is helpful when <span style="color: grey; font-weight: bold">you want to climb a big tree.</span></div>
          <div>
            <div style="display: flex;"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>1</b></div><div><textarea id="0" rows="2"> You need a knife when    </textarea></div></div>
            <div style="display: flex; margin-top: -27px"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>2</b></div><div><textarea id="1" rows="2"> A torch is important when </textarea></div></div>
            <div style="display: flex; margin-top: -27px"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>3</b></div><div><textarea id="2" rows="2"> You need a lighter when </textarea></div></div>
            <div style="display: flex; margin-top: -27px"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>4</b></div><div><textarea id="3" rows="2"> A map is useful when </textarea></div></div>
            <div style="display: flex; margin-top: -27px"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>5</b></div><div><textarea id="4" rows="2"> You need a first-aid kit when </textarea></div></div>
            <div style="display: flex; margin-top: -27px"><div style="display: inline-block; width: 20px; margin-top: 7px;"><b>6</b></div><div><textarea id="5" rows="2"> A compass is helpful when </textarea></div></div>
          </div>
        `,
        answer: [],
      },
    ],
  },
}

export default json;
