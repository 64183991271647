import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import MC2 from '../../components/ExcerciseTypes/MultipleChoice/MC2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
    1: {
        unit: "Cumulative Review",
        id: "WB7-CR-P59-E1",
        component: T6,
        questionImage: [],
        inputSize: 280,
        exerciseKey: "img/FriendsPlus/Page59/E1/Key/answerKey.png",
        stylesTextInput: { marginInline: '10px', fontSize: 24 },

        titleQuestion: [
            {
                num: "1",
                title:
                    "Look at the photo",
                color: "#4D3B98",
            },
        ],
        questions: [
            {
                title: `
                    <div>
					    <div>What is happening? </div>
					    <div>#</div>
					    <div>Do you want to go?</div>
					    <div>#</div>
                        <div><img src='img/FriendsPlus/Page59/E1/1.jpg' /></div>
                    </div>
          `,
                answer: [
                    "There's a music festival.",
                    "",
                ],
            },
        ],
    },
    2: {
        unit: "Cumulative Review",
        id: "WB7-CR-P59-E2",
        component: T6,
        questionImage: [],
        inputSize: 740,
        exerciseKey: "img/FriendsPlus/Page59/E2/Key/answerKey.png",
        stylesTextInput: { marginInline: '10px', fontSize: 24 },

        titleQuestion: [
            {
                num: "2",
                title:
                    "Read the email. Why is Ben happy about the choice of bands?",
                color: "#4D3B98",
            },
        ],
        questions: [
            {
                title: `
                        <div>#</div>
                        <div style="position: relative">
                            <div><img style="width:50%" src='img/FriendsPlus/Page59/E2/1.jpg' /></div>
                            <div style="position: absolute; left: 30px; top: 70px; font-size: 18px">
								Hi Vicky<br/>
                                Thanks for your email. Do you have anything <br/>
                                exciting for this summer?<br/>
                                There’s going to be a music festival for teenagers <br/>
                                where I live next weekend. It’s going to be <br/>
                                amazing!<br/>
                                I’m going to be working every morning, but I’ll <br/>
                                have lots of free time in the afternoons. All of my <br/>
                                favourite bands are playing, so I’m really happy <br/>
                                about that. I’ll be helping at the information <br/>
                                desk – giving advice, helping people who have <br/>
                                lost their friends, etc. I’m really excited, but <br/>
                                a little nervous too. Last year I saw a terrible <br/>
                                accident – a fan climbed up a tree to get a better <br/>
                                look and he fell. He broke his back and had lots <br/>
                                of cuts. He couldn’t move at all. I hope nothing <br/>
                                bad happens this year because I’m not good at <br/>
                                dealing with horrible injuries!<br/>
                                Do you like this music festival? Write back soon!<br/>
                                Ben<br/>
							</div>
                        </div>
          `,
                answer: [
                    "They are all his favourite bands.  ",
                ],
            },
        ],
    },
    3: {
        unit: "Cumulative Review",
        id: "WB7-CR-P59-E3",
        audio: "Audios/1-10 TA 7 Friends Plus Workbook.mp3",
        component: T6,
        questionImage: [],
        stylesTextInput: { fontSize: 24 },
        inputSize: 105,
        exerciseKey: "img/FriendsPlus/Page59/E3/Key/answerKey.png",
        maxLength: 1,

        textAlign: "center",
        titleQuestion: [
            {
                num: "3",
                title:
                    "Read the text again. Write true or false.",
                color: "#4D3B98",
            },
        ],
        questions: [
            {
                title: `
                    <div style="position: relative">
                    <div><img style="width:50%" src='img/FriendsPlus/Page59/E2/1.jpg' /></div>
                    <div style="position: absolute; left: 30px; top: 70px; font-size: 18px">
						Hi Vicky<br/>
                        Thanks for your email. Do you have anything <br/>
                        exciting for this summer?<br/>
                        There’s going to be a music festival for teenagers <br/>
                        where I live next weekend. It’s going to be <br/>
                        amazing!<br/>
                        I’m going to be working every morning, but I’ll <br/>
                        have lots of free time in the afternoons. All of my <br/>
                        favourite bands are playing, so I’m really happy <br/>
                        about that. I’ll be helping at the information <br/>
                        desk – giving advice, helping people who have <br/>
                        lost their friends, etc. I’m really excited, but <br/>
                        a little nervous too. Last year I saw a terrible <br/>
                        accident – a fan climbed up a tree to get a better <br/>
                        look and he fell. He broke his back and had lots <br/>
                        of cuts. He couldn’t move at all. I hope nothing <br/>
                        bad happens this year because I’m not good at <br/>
                        dealing with horrible injuries!<br/>
                        Do you like this music festival? Write back soon!<br/>
                        Ben<br/>
					</div>
                </div>
                <div style="display: flex">
                    <div>
                        <div><b>1.</b> Ben doesn’t have to travel far next weekend.  <input id='0' /></div>
                        <div><b>2.</b> He thinks the music festival will be wonderful.<input id='1' /></div>
                        <div><b>3.</b> He’s going to be busy all day.  <input id='2' /></div>
                        <div><b>4.</b> His main job will be looking after teenagers.   <input id='3' /></div>
                        <div><b>5.</b> The fan stood up after the fall.    <input id='4' /></div>
                        <div><b>6.</b> Ben isn’t keen on helping injured people.   <input id='5' /></div>
                    </div>
			    </div>
        `,
                answer: ["true", "true", "false", "false", "false", "true"],
            },
        ],
    },

    4: { // Exercise num
        unit: "Cumulative Review",
        id: "WB7-CR-P59-E4",
        audio: "Audios/1-12 TA 7 Friends Plus Workbook.mp3",
        video: '',
        component: MC2,
        exerciseKey: 'img/FriendsPlus/Page59/E4/Key/answerKey.png',
        titleQuestion: [
            {
                num: '4',
                title: "<audioimage name='12'></audioimage> Ben is now at the festival. Listen to the conversations.Answer the questions.",
                color: "#4D3B98",
            }
        ],
        imgSize: '100%',
        questions: [
            {
                answers: [
                    { image: "", isCorrect: false, right: "", text: "weak" },
                    { image: "", isCorrect: false, right: "", text: "scared" },
                    { image: "", isCorrect: true, right: "", text: "strict" },
                ],
                title: 'Mrs James sounds #'
            },
            {
                answers: [
                    { image: "", isCorrect: true, right: "", text: " doesn’t like hospital" },
                    { image: "", isCorrect: false, right: "", text: "likes acting" },
                    { image: "", isCorrect: false, right: "", text: "wants to be in the 🌞" },
                ],
                title: 'Anna says she’s OK because she #'
            },
        ]
    },

    5: {
        unit: "Cumulative Review",
        id: "WB7-CR-P59-E5",
        audio: "Audios/1-12 TA 7 Friends Plus Workbook.mp3",
        video: "",
        component: Circle_Write,
        exerciseKey: "img/FriendsPlus/Page59/E5/Key/answerKey.png",
        titleQuestion: [
            {
                num: "5",
                title:
                    "<audioimage name='12'></audioimage> Listen again and choose the correct words.",
                color: "#4D3B98",
            },
        ],
        question: {
            Write: {
                inputStyle: {},
                answers: [],
                initialValue: [],
            },
            Circle: {
                initialWordStyle: {
                    padding: 0,
                    border: "none",
                    borderRadius: "50%",
                    borderColor: "transparent",
                },
                selectWordStyle: {
                    padding: 5,
                    border: "solid 2px",
                    borderColor: "#000000",
                },
                limitSelect: 1,
                listWords: [
                    "brave / silly",
                    "forgets / remembers ",
                    " is / isn’t",
                    "arms / legs",
                    "easy / difficult ",
                    "is / isn’t",
                ],
                answers: ['0-4', '1-0', '2-6', '3-4', '4-4', '5-0'],
                initialValue: [],
            },
            Layout: `
				<div>
					<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> Kim is being <b><input id='0' type='Circle' /></b>.</div></div>
					<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> She <b><input id='1' type='Circle' /></b> her ankle hurts when she hears a song.</div></div>
					<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> She <b><input id='2' type='Circle' /></b> going to leave the festival early.</div></div>
					<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> Anna’s <b><input id='3' type='Circle' /></b> are more painful.</div></div>
					<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> It’s <b><input id='4' type='Circle' /></b> for her to stand up.</div></div>
					<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> She <b><input id='5' type='Circle' /></b> going to leave early.</div></div>
				</div>
          `,
        },
    },
    6: {
        unit: "Cumulative Review",
        id: "WB7-CR-P59-E6",
        component: T6,
        questionImage: [],
        inputSize: 125,
        exerciseKey: "img/FriendsPlus/Page56/E5/Key/answerKey.png",
        textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
        stylesTextInput: { marginInline: '10px', fontSize: 24 },
        hideBtnFooter: true,
        titleQuestion: [
            {
                num: "6",
                title:
                    " A TASK Imagine you helped someone who was hurt in an accident.Write an email about the accident.",
                color: "#4D3B98",
            },
        ],
        questions: [
            {
                title: `
                    <div>
                            <div><textarea id="0" rows="11"></textarea></div>
                    </div>
                    `,
                answer: [],
            },
        ],
    },
    7: {
        unit: "Cumulative Review",
        id: "WB7-CR-P59-E7",
        component: T6,
        questionImage: [],
        inputSize: 125,
        textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
        stylesTextInput: { marginInline: '10px', fontSize: 24 },
        hideBtnFooter: true,
        titleQuestion: [
            {
                num: "6",
                title:
                    " B THINK AND PLAN",
                color: "#4D3B98",
            },
        ],
        questions: [
            {
                title: `
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1; "> How are you going to begin the email? What informal phrases are you going to use? </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1; "> Where were you? When did it happen? Who were you with? Give some details. </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3 </b></div><div style="margin-left: 30px; flex: 1; "> Who had the accident? What happened? </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4 </b></div><div style="margin-left: 30px; flex: 1; "> How did you feel?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5 </b></div><div style="margin-left: 30px; flex: 1; "> How are you going to end the email? What question can you ask?</div></div>
	              </div>
          `,
                answer: [],
            },
        ],
    },
    8: {
        unit: "Cumulative Review",
        id: "WB7-CR-P59-E8",
        component: T6,
        questionImage: [],
        inputSize: 125,
        exerciseKey: "img/FriendsPlus/Page56/E5/Key/answerKey.png",
        textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
        stylesTextInput: { marginInline: '10px', fontSize: 24 },
        hideBtnFooter: true,
        titleQuestion: [
            {
                num: "6",
                title:
                    "  Use the text in exercise 2 and the writing guide below",
                color: "#4D3B98",
            },
        ],
        questions: [
            {
                title: `
                    <div>
						<div style="display: flex">
							<div style="background-color: rgb(206, 201, 227); border-radius: 20px; padding: 5px 30px; margin: 10px; flex: 1; height: fit-content"">Paragraph 1: <span style="color: rgb(87, 88, 90); font-weight: bold; font-style: italic">Hi …</span></div>
							<div style="background-color: rgb(206, 201, 227); border-radius: 20px; padding: 5px 30px; margin: 10px; flex: 1; height: fit-content">Paragraph 2:	<span style="color: rgb(87, 88, 90); font-weight: bold; font-style: italic; display: inline-block; height: 10px !important">Last year I was … with … when … </span></div>
							<div style="background-color: rgb(206, 201, 227); border-radius: 20px; padding: 5px 30px; margin: 10px; flex: 1; height: fit-content">Paragraph 3:	<span style="color: rgb(87, 88, 90); font-weight: bold; font-style: italic; display: inline-block; height: 10px !important">Do you like … ?</span></div>
						</div>
						<div><textarea id="2" rows="11"></textarea></div>
                    </div>
          `,
                answer: [],
            },
        ],
    },

    9: {
        unit: "Cumulative Review",
        id: "WB7-CR-P59-E9",
        component: T6,
        questionImage: [],
        inputSize: 125,
        exerciseKey: "img/FriendsPlus/Page56/E5/Key/answerKey.png",
        textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
        hideBtnFooter: true,

        internetSearch: {
            content: `
				<b> Did you know ...</b><br />
                <i>Each year, more than 135,000 people go to the most famous UK music festival, Glastonbury.</i>
                <br />
                Find how much a Glastonbury ticket cost in 1970 and how much it costs today.
            `
        },
        stylesTextInput: { marginInline: '10px', fontSize: 24 },
        questions: [
            {
                title: `
              <div>
                <internetsearch></internetsearch>
              </div>
          `,
                answer: [],
            },
        ],
    },
}

export default json;