import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
	1: {
		unit: "Unit 8",
		id: "WB7-U8-P55-E1",
		component: T6,
		questionImage: [],
		inputSize: 120,
		exerciseKey: "img/FriendsPlus/Page55/E1/Key/answerKey.png",

		stylesTextInput: { marginInline: '10px', fontSize: 24, textAlign: 'center' },
		titleQuestion: [
			{
				num: "1",
				title:
					"Complete the sentences with <i>so</i> or <i>because</i>.",
				color: "#00a23b",
				star: 1
			},
		],
		questions: [
			{
				title: `
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> Fossil fuels cause pollution # we must look for alternative sources.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> People like flying cars # they can avoid traffic jams.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> Amphibious buses can run on water and land # we needn’t build more roads.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> Green energy sources are great # they keep our planet unpolluted.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> Solar energy is not new to us # we use it to dry things every day.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> Plane tickets are cheaper and cheaper # more and more passengers fly</div></div>
              </div>
          `,
				answer: [
					"so ",
					"because",
					"so ",
					"because",
					"because",
					"so ",
				],
			},
		],
	},
	2: { // Exercise num
		unit: 'Unit 8',
		id: 'WB7-U8-P55-E2',
		audio: '',
		video: '',
		component: T6,
		questionImage: [],
		exerciseKey: "img/FriendsPlus/Page55/E2/Key/answerKey.png",
		titleQuestion: [
			{
				num: "2",
				title:
					" Match 1–6 with a–f. Then write new sentences with <i>so</i> and <i>because</i>.",
				color: "#00a23b",
				star: 2
			},
		],
		questions: [
			{
				title: `
        <div style="display:flex; gap:30px;">
					<div>
            <div><b>1 &emsp;</b>I will choose to take showers</div>
            <div><b>2 &emsp;</b>I will choose to use light tubes</div>
            <div><b>3 &emsp;</b>I will choose to walk to school</div>
            <div><b>4 &emsp;</b>I will choose to install a solar system</div>
            <div><b>5 &emsp;</b>I will choose to plant more trees</div>
            <div><b>6 &emsp;</b>I will choose to stop using plastic bottles.</div>
					</div>
					<div>
            <div><b>a &emsp;</b>...... I don't want to pollute the environment.</div>
            <div><b>b &emsp;</b>...... I can have more shade here.</div>
            <div><b>c &emsp;</b>...... baths use more water.</div>
            <div><b>d &emsp;</b>...... bulbs use more electricity.</div>
            <div><b>e &emsp;</b>...... I want to reduce electricity bills.</div>
            <div style="margin-left: 5px;"><b>f &emsp;</b>...... I want to do more exercise.</div>
					</div>
				</div>
<br/>
				<div><b><i>Match:</i></b></div>
				<div style="display:flex; gap:40px;">
            <div><b>1 🠪</b><input width="50px"/></div>
            <div><b>2 🠪</b><input width="50px"/></div>
            <div><b>3 🠪</b><input width="50px"/></div>
            <div><b>4 🠪</b><input width="50px"/></div>
            <div><b>5 🠪</b><input width="50px"/></div>
            <div><b>6 🠪</b><input width="50px"/></div>
          </div>

<br/>
				<div><b><i>Write:</i></b></div>
            <div><b>1 &ensp;</b><input width="1050px"/></div>
            <div><b>2 &ensp;</b><input width="1050px"/></div>
            <div><b>3 &ensp;</b><input width="1050px"/></div>
            <div><b>4 &ensp;</b><input width="1050px"/></div>
            <div><b>5 &ensp;</b><input width="1050px"/></div>
            <div><b>6 &ensp;</b><input width="1050px"/></div>
          `,
				answer: [
					"c", "d", "f", "e", "b", "a",
					`I will choose to take showers because baths use more water. / Baths use more water so I will choose to take showers.`,
					`I will choose to use light tubes because bulbs use more electricity. / Bulbs use more electricity so I will choose to use light tubes.`,
					`I will choose to walk to school because I want to do more exercise. / I want to do more exercise so I will choose to walk to school.`,
					`I will choose to install a solar system because I want to reduce electricity bills. / I want to reduce electricity bills so I will choose to install a solar system.`,
					`I will choose to plant more trees because I can have more shade here. / I can have more shade here so I will choose to plant more trees.`,
					`I will choose to stop using plastic bottles because I don't want to pollute the environment. / I don't want to pollute the environment so I will choose to stop using plastic bottles.`,
				],
			},
		],
	},

	3: {
		unit: "Unit 8",
		id: "WB7-U8-P55-E3",
		audio: "",
		video: "",
		component: T6,
		questionImage: [],
		stylesTextInput: { borderBottom: "none", fontSize: 20 },
		inputSize: 170,
		exerciseKey: "img/FriendsPlus/Page55/E3/Key/answerKey.png",
		maxLength: 16,

		isHiddenCheck: true,
		textAlign: "center",
		titleQuestion: [
			{
				num: "3",
				title:
					" Complete the email with the words and phrases.<br/>Put the verbs in the correct form.",
				color: "#00a23b",
				star: 2
			},
		],
		questions: [
			{
				title: `
          <div style="position: relative; margin: 10px">
            <div><img src='img/FriendsPlus/Page55/E3/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 634px; left: 7px; "><input id='0' width='250px'/></div>
							<div style=" position: absolute; top: 665px; left: 220px; "><input id='1' /></div>
							<div style=" position: absolute; top: 728px; left: 42px; "><input id='2' /></div>
							<div style=" position: absolute; top: 760px; left: 129px; "><input id='3' /></div>
							<div style=" position: absolute; top: 791px; left: 134px; "><input id='4' /></div>
							<div style=" position: absolute; top: 884px; left: 50px; "><input id='5' /></div>
							<div style=" position: absolute; top: 947px; left: 122px; "><input id='6' /></div>
							<div style=" position: absolute; top: 978px; left: 109px; "><input id='7' /></div>
							<div style=" position: absolute; top: 1102px; left: 45px; "><input id='8' /></div>
							<div style=" position: absolute; top: 1165px; left: 50px; "><input id='9' /></div>
							<div style=" position: absolute; top: 1197px; left: 274px; "><input id='10' /></div>
							<div style=" position: absolute; top: 1228px; left: 50px; "><input id='11' /></div>

            </div>
          </div>
        `,
				answer: [
					"Thanks for your email ",
					"do",
					"Guess what",
					"broke",
					"because ",
					"playing",
					"fell",
					"so",
					"so",
					"because ",
					"visit ",
					"Write soon ",
				]
			}
		],
	},


	4: {
		unit: "Unit 8",
		id: "WB7-U8-P55-E4",
		audio: "",
		video: "",
		component: T6,
		questionImage: [],
		textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
		hideBtnFooter: true,
		titleQuestion: [
			{
				num: "4",
				title:
					" Imagine you had an accident at home in the first week of the holidays.Write an email to a friend and tell them about it",
				color: "#00a23b",
				star: 3
			},
		],
		questions: [
			{
				title: `
          <div><textarea id="0" rows="11"></textarea></div>
        `,
				answer: [],
			},
		],
	},
}


export default json;