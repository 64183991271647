import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: "Unit 5",
    id: "WB7-U5-P32-E1",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    inputSize: 180,
    exerciseKey: "img/FriendsPlus/Page32/E1/Key/answerKey.png",
    maxLength: 1,
    textAlign: "center",
    isHiddenCheck: true,
    hintBox: [{
      src: [
        "decade",
        "fraction",
        "half ",
        "metre",
        "month",
        "<s>quarter</s>",
      ],
      borderColor: "#1d71a9",
      width: "100%"
    }],
    titleQuestion: [
      {
        num: "1",
        title:
          " Label the pictures.",
        color: "#1d71a9",
        star: 1
      },
    ],
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
				<div style="font-size: 22px">
				  <tr>
					<td style="padding: 20px; text-align: center ">
					  <img style="width: 80%" src='img/FriendsPlus/Page32/E1/1.jpg'/>
					  <div style="text-align: center; color: grey; font-weight: bold"> quarter</div>
					</td>
					<td style="padding: 20px; text-align: center ">
					  <img style="width: 80%" src='img/FriendsPlus/Page32/E1/2.jpg'/>
					  <div ><b>1</b> # </div>
					</td>
				  </tr>
				  <tr>
					<td style="padding: 20px; text-align: center ">
					  <img style="width: 80%" src='img/FriendsPlus/Page32/E1/3.jpg'/>
					  <div ><b>2</b> # </div>
					</td>
					<td style="padding: 20px; text-align: center ">
					  <img style="width: 80%" src='img/FriendsPlus/Page32/E1/4.jpg'/>
					  <div ><b>3</b> # </div>
					</td>
				  </tr>
				  <tr>
					<td style="padding: 20px; text-align: center ">
					  <img style="width: 80%" src='img/FriendsPlus/Page32/E1/6.jpg'/>
					  <div ><b>4</b> #</div>
					</td>
					<td style="padding: 20px; text-align: center ">
					  <img style="width: 80%" src='img/FriendsPlus/Page32/E1/5.jpg'/>
					  <div ><b>5</b> #</div>
					</td>
				  </tr>
				</div>
			  `,
        answer: [
          "fraction",
          "metre",
          "decade",
          "half",
          "month",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "WB7-U5-P32-E2",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page32/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "Do the quiz. Then check your answers at the bottom of the page.",
        color: "#1d71a9",
        star: 2
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
          fontSize: 20
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          " thousand / million / billion", //0
          "kilometres / kilos / tons", //1
          " minutes / hours / days ", //0
          "thousand / million / billion", //1
          "hundred / thousand / million", //0
          " seconds / minutes / hours  ", //1
        ],
        answers: ['0-10', '1-4', '2-6', '3-4', '4-0', '5-6'],
        initialValue: [],
      },
      Layout: `
              <div style="position: relative;">
                <div><img src='img/FriendsPlus/Page32/E2/1.jpg'/><div>
                <div style=" position: absolute; top: 97px; left: 128px; "><b><input id='0' type='Circle'/></b></div>
                <div style=" position: absolute; top: 173px; left: 128px; "><b><input id='1' type='Circle'/></b></div>
                <div style=" position: absolute; top: 243px; left: 275px; "><b><input id='2' type='Circle'/></b></div>
                <div style=" position: absolute; top: 315px; left: 167px; "><b><input id='3' type='Circle'/></b></div>
                <div style=" position: absolute; top: 358px; left: 290px; "><b><input id='4' type='Circle'/></b></div>
                <div style=" position: absolute; top: 459px; left: 292px; "><b><input id='5' type='Circle'/></b></div>
              </div>
          `,
    },
  },
  3: {
    unit: "Unit 5",
    id: "WB7-U5-P32-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 23 },
    hintBox: [{
      src: [
        "a century",
        "a kilometre",
        "a millennium",
        "a ton ",
        "a year",
        "an hour",
        "days",
        "<s>months</s>",
        "seconds",
      ],
      borderColor: "#1d71a9",
      width: "100%"
    }],
    inputSize: 175,
    exerciseKey: "img/FriendsPlus/Page32/E3/Key/answerKey.png",
    textAlign: 'center',

    titleQuestion: [
      {
        num: "3",
        title:
          " Complete the sentences with the words.",
        color: "#1d71a9",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <hintbox id="0"></hintbox>
                <div><u style="text-decoration: dotted underline;">&emsp;<b>month</b>&emsp;</u> There are twelve <b><s>days</s></b> in a year</div>
                <div><b>1</b> # There are a hundred years in <b>a decade</b>.</div>
                <div><b>2</b> # There are sixty <b>hours</b> in a minute.</div>
                <div><b>3</b> # There are a thousand kilos in <b>a gram</b>. </div>
                <div><b>4</b> # There are sixty minutes in <b>a day</b>.</div>
                <div><b>5</b> # There are a thousand years in <b>a century</b>.</div>
                <div><b>6</b> # There are usually 365 <b>weeks</b> in a year. </div>
                <div><b>7</b> # There are fifty-two weeks in <b>a millennium</b>. </div>
                <div><b>8</b> # There are a thousand metres in <b>a centimetre</b>.  </div>
              </div>
          `,
        answer: [" a century", "seconds", "a ton ", "an hour", "a millennium", "days", "a year", "a kilometre"],
      },
    ],
  },

  4: {
    unit: "Unit 5",
    id: "WB7-U5-P32-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page32/E4/Key/answerKey.png",
    maxLength: 16,

    isHiddenCheck: true,
    textAlign: "center",
    hintBox: [{
      src: [
        "century",
        "decades",
        "hours",
        "<s>kilometres</s>",
        "months ",
        "seconds",
        "Thousands",
        "years",
      ],
      width: "580px",
      borderColor: "#1d71a9",
    }],
    titleQuestion: [
      {
        num: "4",
        title:
          " Complete the text with the words.",
        color: "#1d71a9",
        star: 2
      },
    ],
    questions: [
      {
        title: `
          <hintbox id='0'></hintbox>
          <div style="position: relative;">
            <img src='img/FriendsPlus/Page32/E4/1.jpg' />
              <div style=" position: absolute; top: 344px; left: 162px; "><input id='0' /></div>
              <div style=" position: absolute; top: 367px; left: 303px; "><input id='1' /></div>
              <div style=" position: absolute; top: 389px; left: 157px; "><input id='2' /></div>
              <div style=" position: absolute; top: 435px; left: 303px; "><input id='3' /></div>
              <div style=" position: absolute; top: 458px; left: 183px; "><input id='4' /></div>
              <div style=" position: absolute; top: 480px; left: 210px; "><input id='5' /></div>
              <div style=" position: absolute; top: 549px; left: 203px; "><input id='6' /></div>
         </div> 
        `,
        answer: [
          "decades",
          "years",
          "Thousands",
          "months",
          "hours",
          "seconds",
          "century",
        ]
      }
    ],
  },

}

export default json;