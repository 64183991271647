import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: {
    unit: "Unit 2",
    id: "WB7-U2-P18-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page18/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Read the text. Choose the correct words.",
        color: "#dc2c39",
        star: 1
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          "green / red / black", //0
          "engineers / students / office_workers", //1
        ],
        answers: ["0-0", "1-4"],
        initialValue: [],
      },
      Layout: `
              <div >
                <div>The new phone box in London is <b><input id='0' type='Circle' /></b> .It is the idea of two <b style="display: inline-block"><input id='1' type='Circle' /></b>.</div>
                <div><img style="width:40%"src="img/FriendsPlus/Page18/E1/1.jpg"/></div>
              </div>
          `,
    },
  },
  2: {
    unit: "Unit 2",
    id: "WB7-U2-P18-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 20 },
    inputSize: 105,
    exerciseKey: "img/FriendsPlus/Page18/E2/Key/answerKey.png",
    maxLength: 1,

    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title:
          "Read the text again. Match paragraphs A–E with phrases 1–4.",
        color: "#dc2c39",
        star: 2
      },
    ],
    questions: [
      {
        title: `
        <div style="display: flex">
          <div><img style="width:70%" src="img/FriendsPlus/Page18/E1/1.jpg"/></div>
          <div style="width: 700px">
            <div>You can see all the usual things in London <b style="color: grey"><i> A </i></b></div>
            <div><b>1</b> What one person says about Solarbox <input id='0' /></div>
            <div><b>2</b> A phone box without a phone!   <input id='1' /></div>
            <div><b>3</b> Why Solarbox is useful in today’s world  <input id='2' /></div>
            <div><b>4</b> How Solarbox gets its power  <input id='3' /></div>
          </div>
			  </div>
        `,
        answer: ["E", "B", "D", "C",],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "WB7-U2-P18-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 20 },
    inputSize: 105,
    exerciseKey: "img/FriendsPlus/Page18/E3/Key/answerKey.png",
    maxLength: 1,

    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title:
          " Read the text again. Write true or false.",
        color: "#dc2c39",
        star: 2
      },
    ],
    questions: [
      {
        title: `
        <div style="display: flex">
          <div><img style="width:62%"s src="img/FriendsPlus/Page18/E1/1.jpg"/></div>
          <div style="width: 600px">
            <div>The new solar box...</div>
            <div>is in London. <b style="color: grey"><i> true </i></b></div>
            <div><b>1</b> is different from usual phone boxes <input id='0' /></div>
            <div><b>2</b> has got a green phone inside it   <input id='1' /></div>
            <div><b>3</b> uses power from the wind  <input id='2' /></div>
            <div><b>4</b> is useful for workers in London.   <input id='3' /></div>
            <div><b>5</b> isn’t very popular at the moment.    <input id='4' /></div>
          </div>
			  </div>
        `,
        answer: ["true", "false", "false", "true", "false"],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "WB7-U2-P18-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],

    inputSize: 700,
    exerciseKey: "img/FriendsPlus/Page18/E4/Key/answerKey.png",

    titleQuestion: [
      {
        num: "4",
        title:
          "Answer the questions. Write complete sentences.",
        color: "#d01435",
        star: 3
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div>What can tourists see on the streets of London? </div>
                <div><u style="text-decoration: dotted underline;color: grey">&emsp;<b>They can see red buses, black taxis, red telephoneboxes and a green telephone box.</b>&emsp;</u></div>
                <div><b>1.</b> What can people do inside the Solarbox?</div>
                <div>#</div>
                <div><b>2.</b> How many hours of sun does the Solarbox need every day?</div>
                <div>#</div>
                <div><b>3.</b> Why don’t people use the red phone boxes?</div>
                <div>#</div>
                <div><b>4.</b> What does Bhavesh Morar think of the Solarbox?</div>
                <div>#</div>
                <div><b>5.</b> Do you think the Solarbox is a good idea? Why / Why not? </div>
                <div>#</div>
                <div><b>6.</b> Do you need a Solarbox in your town or city? Why / Why not?</div>
                <div>#</div>
              </div>
          `,
        answer: [
          " They can charge their phones.",
          " It needs three hours.",
          " Because they've got mobile phones.",
          " He thinks it's a good idea.",
          "",
          "",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "WB7-U2-P18-E5",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    hintBox: [{
      src: [
        "colourful",
        "creative",
        "funny ",
        "<s>international</s>",
        "useful",
      ],
      borderColor: "#d01435",
      width: "100%"
    }],
    inputSize: 175,
    exerciseKey: "img/FriendsPlus/Page18/E5/Key/answerKey.png",
    textAlign: 'center',
    titleQuestion: [
      {
        num: "5",
        title:
          "<b style='color: #d01435;'>VOCABULARY PLUS </b> Complete the sentences with the words.",
        color: "#d01435",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <hintbox id="0"></hintbox>
                <div>This is a big <u style="text-decoration: dotted underline;">&emsp;<b>international</b>&emsp;</u> airport. You can go to a lot of different countries from here.</div>
                <div><b>1</b> Mary is # – look at her beautiful picture. She writes good stories too!</div>
                <div><b>2</b> When I see something #, I laugh.</div>
                <div><b>3</b> Toby’s T-shirt is very # – it’s blue, red, yellow and pink</div>
                <div><b>4</b> Emojis are very #– you can communicate different feelings with them.</div>
              </div>
          `,
        answer: ["creative", "funny", "colourful", "useful"],
      },
    ],
  },

}

export default json;