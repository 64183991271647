import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 1",
    id: "WB7-U1-P7-E1",
    component: T6,
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 121,
    exerciseKey: "img/FriendsPlus/Page7/E1/Key/answerKey.png",
    maxLength: 11,

    hintBox: [{
      src: [
        "’s got",
        "<s>’ve got</s> ",
        "got",
        "Has",
        "has",
        "hasn’t",
        "hasn’t got",
        "have",
        "haven’t",
        "haven’t got",
      ],
      width: "61%",
      borderColor: "#77cedf",
    }],
    titleQuestion: [
      {
        num: "1",
        title:
          "Complete the table with the words.",
        color: "#69d2e4",
        star: 1
      },
    ],
    textAlign: "center",
    questions: [
      {
        title: `
          <div style="position: relative;">
            <div><hintbox id="0"></hintbox></div>
            <div><img style="width:59%" src='img/FriendsPlus/Page7/E1/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 168px; left: 260px; ">#</div>
              <div style=" position: absolute; top: 238px; left: 260px; ">#</div>
              <div style=" position: absolute; top: 268px; left: 260px; ">#</div>
              <div style=" position: absolute; top: 342px; left: 260px; ">#</div>
              <div style=" position: absolute; top: 369px; left: 14px; ">#</div>
              <div style=" position: absolute; top: 494px; left: 14px; ">#</div>
              <div style=" position: absolute; top: 524px; left: 134px; ">#</div>
              <div style=" position: absolute; top: 494px; left: 260px; ">#</div>
              <div style=" position: absolute; top: 524px; left: 414px; ">#</div>
          </div>
        `,
        answer: ["'s got", "haven't got", "hasn't got", "got", "Has", "have", "has", "haven't", "hasn't"],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "WB7-U1-P7-E2",
    component: T6,
    stylesTextInput: { fontSize: 25 },
    inputSize: 400,
    exerciseKey: "img/FriendsPlus/Page7/E2/Key/answerKey.png",

    hintBox: [{
      src: [
        "a camera",
        "<s>a laptop</s>",
        "a lot of books",
        "a new bag",
        "a new teacher",
        "good marks",
      ],
      width: "61%",
      borderColor: "#77cedf",
    }],
    titleQuestion: [
      {
        num: "2",
        title:
          "Complete the table with the words.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
          <div style="position: relative;">
            <div><hintbox id="0"></hintbox></div>
            <div><img style="width:54%" src='img/FriendsPlus/Page7/E2/1.jpg' /></div>
            <div>
            <div>Martin<b style="color: grey">’s got a laptop.</b></div>
            <div><b>1</b> Freya #</div>
            <div><b>2</b> Nina and Mariam #</div>
            <div><b>3</b> Anna #</div>
            <div><b>4</b> James and Ali  #</div>
            <div><b>5</b> I #</div>
          </div>
        `,
        answer: [
          " hasn't got a new bag.",
          " have got good marks.",
          " have got good marks.",
          "  haven't got a new teacher",
          " 've got a lot of books.",
        ]
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "WB7-U1-P7-E3",
    component: T6,
    stylesTextInput: { fontSize: 25 },
    inputSize: 400,
    exerciseKey: "img/FriendsPlus/Page7/E3/Key/answerKey.png",

    titleQuestion: [
      {
        num: "3",
        title:
          "Write the questions and answers using have got and the words.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
          <div style="position: relative;">
            <tr>
              <td><b style="padding: 20px">Ryan</b></td>
              <td>Hello Tyler. <b style="color: grey"> Have you got your timetable </b> (you / your timetable)?</td>
            </tr>
            <tr>
              <td><b style="padding: 20px">Tyler</b></td>
              <td>Yes, it’s tough! <b>1</b> # (I / two hours of history) on Monday. What <b>2</b> # (you)?</td>
            </tr>
            <tr>
              <td><b style="padding: 20px">Ryan</b></td>
              <td><b>3</b> # (I / music).</td>
            </tr>
            <tr>
              <td><b style="padding: 20px">Tyler</b></td>
              <td>Cool. <b>4</b> # (you / Mr Wills) for music this year? He’s strict!</td>
            </tr>
            <tr>
              <td><b style="padding: 20px">Ryan</b></td>
              <td>No, <b>5</b> # (I / a new teacher).<b>6</b> # (he / an Italian name) – Mr Moretti.</td>
            </tr>
            <tr>
              <td><b style="padding: 20px">Tyler</b></td>
              <td>Oh. <b>7</b> # (he / brown hair) and a blue jacket?</td>
            </tr>
            <tr>
              <td><b style="padding: 20px">Ryan</b></td>
              <td>Yes, he <b>8</b> #.</td>
            </tr>
            <tr>
              <td><b style="padding: 20px">Tyler</b></td>
              <td>Oh, I know him. He looks nice.</td>
            </tr>
          </div>
        `,
        answer: [
          " I've got two hours of history",
          " have you got on Monday morning",
          " I've got music",
          " Have you got Mr Wills",
          "  I've got a new teacher",
          "  He's got an Italian name",
          "  Has he got brown hair",
          "  has",
        ]
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "WB7-U1-P7-E4",
    component: T6,
    questionImage: [],
    exerciseKey: 'img/FriendsPlus/Page7/E4/Key/answerKey.png',
    stylesTextInput: { textAlign: 'center', fontSize: 22 },
    inputSize: 140,
    titleQuestion: [
      {
        num: "4",
        title:
          " Complete the sentences using the correct form of there’s or there are.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div style="color: grey"><b>There’s</b>  a sports field next to my house.</div>
              <div><b>1</b> # five new teachers this year. That’s a lot!</div>
              <div><b>2</b> #  a new computer lab in your school?</div>
              <div><b>3</b> #  a Turkish girl in my class. She’s friendly.</div>
              <div><b>4</b> That book is difficult.  # any pictures in it. </div>
              <div><b>5</b> #  any notes in your notebook?</div>
              <div><b>6</b> #  a maths test this week. It’s next week.</div>
            </div>
        `,
        answer: ["There are", "Is there", " There's", "There aren't", "Are there", "There isn't"]
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "WB7-U1-P7-E5",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 600 },
    hideBtnFooter: true,
    hintBox: [{
      src: [
        "atmosphere",
        "book",
        "computer",
        "lab exam",
        "field",
        "homework",
        "noise",
        "poster",
        "science",
        "lab",
      ],
      width: "88%",
      borderColor: "#77cedf",
    }],
    titleQuestion: [
      {
        num: "5",
        title:
          "Write sentences about your dream school. Use the words to help you.",
        color: "#69d2e4",
        star: 3
      },
    ],
    questions: [
      {
        title: `
          <div><hintbox id="0"></hintbox></div>
          <div><textarea id="0" rows="7" width="88%">There’s a nice library with a lot of interesting books.</textarea></div>
        `,
        answer: []
      },
    ]
  },
};

export default json;
