import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P67-E1",
    component: T6,
    questionImage: [],
    inputSize: 240,
    exerciseKey: "img/FriendsPlus/Page67/E1/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'center' },
    hintBox: [{
      src: [
        "do",
        "listen",
        "not buy",
        "not sing ",
        "not walk",
        "<s>play</s>",
        "watch",
      ],
      width: "700px",
      borderColor: "#4D3B98",
    }],
    titleQuestion: [
      {
        num: "1",
        title:
          "Complete the sentences using be going to and the verbs.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div style="width: 1000px; display: flex; justify-content: center; margin-block: 10px"><hintBox id='0'></hintbox></div>
                <div>
                  <div>Yusuf <u style="text-decoration: dotted underline;color: grey">&emsp;<b>is going to play</b>&emsp;</u>the guitar in the new band.</div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> I # to the radio later. My favourite show is at 7:00.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> He # to the station because it’s raining.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> We # the music video online.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> I # that T-shirt. It’s very expensive.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> Dasha # her music homework this evening.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> We # that song. It’s really terrible!</div></div>
              </div>
          `,
        answer: [
          "'m going to listen",
          "isn't going to walk",
          "'re going to watch",
          "'m not going to buy",
          "is going to do",
          "aren't going to sing",
        ],
      },
    ],
  },
  2: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P67-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 22 },
    inputSize: 625,
    exerciseKey: "img/FriendsPlus/Page67/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "Write affirmative (✔) and negative (✘) sentences using be going to.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div>Jamie T / play / a new song ✔ <br /><u style="text-decoration: dotted underline; color: grey"><i><b>Jamie T is going to play a new song.</b></i>&emsp;</u></div>
              <div><b>1 &emsp;</b> I / learn / these cool lyrics ✔ <br /> &emsp; #</div>
              <div><b>2 &emsp;</b> we / download / the reggae song ✘<br /> &emsp; #</div>
              <div><b>3 &emsp;</b> Harry and Niall / talk / to the fans ✔ <br /> &emsp; #</div>
              <div><b>4 &emsp;</b> that video / be / on TV tonight ✘ <br /> &emsp; #</div>
              <div><b>5 &emsp;</b> you / write / the music for the album ✔ <br /> &emsp; #</div>
              <div><b>6 &emsp;</b> they / change / the title of the song ✘  <br /> &emsp; #</div>
            </div>
        `,
        answer: [
          "I'm going to learn these cool lyrics.",
          "We aren't going to download the reggae song.",
          "Harry and Niall are going to talk to the fans.",
          "That video isn't going to be on TV tonight.",
          "You're going to write the music for the album.",
          "They aren't going to change the title of the song.",
        ],
      },
    ],
  },

  3: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P67-E3",
    component: T6,
    questionImage: [],
    inputSize: 210,
    exerciseKey: "img/FriendsPlus/Page67/E3/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'center' },
    titleQuestion: [
      {
        num: "3",
        title:
          "Complete the sentences using will or be going to and the verbs in brackets.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <div>They think the music festival <u style="text-decoration: dotted underline;color: grey">&emsp;<b>will be</b>&emsp;</u>(be) good.</div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> We # (study) a lot because we want to do well in the exams.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> Italy is a nice place. I’m sure they # (have) a good time.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> I # (visit) my grandmother after school tomorrow.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> Let’s go to that new museum in London. I think we # (enjoy) it.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> If you like heavy metal, we know you # (love) this new song!</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> Bill has got a new guitar and he # (start) guitar lessons.</div></div>
              </div>
          `,
        answer: [
          "'re going to study",
          "'ll have",
          "'m going to visit",
          "'ll enjoy ",
          "'ll love",
          "'s going to start",
        ],
      },
    ],
  },

  4: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P67-E4",
    component: T6,
    questionImage: [],
    inputSize: 300,
    exerciseKey: "img/FriendsPlus/Page67/E4/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'center' },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Complete the questions using be going to and the verbs.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <div><u style="text-decoration: dotted underline;color: grey">&emsp;<b> Are you going to practise </b>&emsp;</u>  (you / practise) the keyboard every day?</div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; ">  # (Fatma / learn) the bass?</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; ">  # (you / do) a heavy metal version of that song?</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; ">  # (Archie / become) a singer?</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; ">  #  (they / sing) some of their old songs?</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; ">  What # (Paul / play) in the band?</div></div>
              </div>
          `,
        answer: [
          "Is Fatma going to learn",
          "Are you going to do",
          "Is Archie going to become",
          "Are they going to sing",
          "is Paul going to play",
        ],
      },
    ],
  },
  5: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P67-E5",
    component: T6,
    questionImage: [],
    inputSize: 200,
    exerciseKey: "img/FriendsPlus/Page67/E5/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'center' },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "5",
        title:
          "Look at the table. Then write sentences using the present continuous.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <table>
                  <tr style="border: 1px solid black; padding: 10px">
                    <td style="border: 1px solid black; padding: 10px"></td>
                    <td style="border: 1px solid black; padding: 10px"><b>Friday</b></td>
                    <td style="border: 1px solid black; padding: 10px"><b>Saturday</b></td>
                    <td style="border: 1px solid black; padding: 10px"><b>Sunday</b></td>
                  </tr>
                  <tr style="border: 1px solid black; padding: 10px">
                    <td style="border: 1px solid black; padding: 10px">Yasmin</td>
                    <td style="border: 1px solid black; padding: 10px">play basketball</td>
                    <td style="border: 1px solid black; padding: 10px">go shopping</td>
                    <td style="border: 1px solid black; padding: 10px">do homework</td>
                  </tr>
                  <tr style="border: 1px solid black; padding: 10px">
                    <td style="border: 1px solid black; padding: 10px">Owen and Ben</td>
                    <td style="border: 1px solid black; padding: 10px">watch a film</td>
                    <td style="border: 1px solid black; padding: 10px">practise with the school band</td>
                    <td style="border: 1px solid black; padding: 10px">meet friends</td>
                  </tr>
                </table>
                <div style="margin-top: 20px">
                  <div>Next Friday, Yasmin <u style="text-decoration: dotted underline;color: grey">&emsp;<b> is playing </b>&emsp;</u>  basketball. </div>
                  <div>She <u style="text-decoration: dotted underline;color: grey">&emsp;<b> isn’t watching </b>&emsp;</u>  (you / practise)  a film.</div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; ">  On Saturday, Yasmin # with the school band. She # shopping.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; ">  On Sunday, Yasmin # homework. She # friends.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; ">  On Friday, Owen and Ben # basketball. They # a film.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; ">  On Saturday, Owen and Ben # with the school band. They # shopping.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; ">  On Sunday, Owen and Ben # friends. They # homework. </div></div>
               </div>
          `,
        answer: [
          "isn't practising", "'s going",
          "is doing", "isn't meeting",
          "aren't playing", "'re watching",
          "are practising", "aren't going",
          "are meeting", "aren't doing",
        ],
      },
    ],
  },
}

export default json;