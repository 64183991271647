import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 1",
    id: "WB7-U1-P12-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 1,
    exerciseKey: "img/FriendsPlus/Page12/E1/Key/answerKey.png",
    titleImage: "",
    questionImage: [
      [{ url: "img/FriendsPlus/Page12/E1/1.jpg" }],
      [{ url: "img/FriendsPlus/Page12/E1/12.jpg" }],
      [{ url: "img/FriendsPlus/Page12/E1/13.jpg" }],
      [
        { url: "img/FriendsPlus/Page12/E1/2.jpg" },
        { url: "img/FriendsPlus/Page12/E1/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page12/E1/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page12/E1/5.jpg" },
        { url: "img/FriendsPlus/Page12/E1/6.jpg", input: 1 },
        { url: "img/FriendsPlus/Page12/E1/7.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page12/E1/8.jpg" },
        { url: "img/FriendsPlus/Page12/E1/9.jpg", input: 1 },
        { url: "img/FriendsPlus/Page12/E1/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page12/E1/11.jpg" }],
    ],
  },
  2: {
    unit: "Unit 1",
    id: "WB7-U1-P12-E2",
    component: T6,

    inputSize: 100,
    exerciseKey: "img/FriendsPlus/Page12/E2/Key/answerKey.png",
    maxLength: 5,

    textAlign: "center",
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "2",
        title: "Read the text again and write true or false.",
        color: "#d01435",
        star: 2,
      },
    ],
    questions: [
      {
        title: `
          <div style="display: flex; width: 1400px">
            <div style="padding: 20px"><img style="width: 83%"src='img/FriendsPlus/Page12/E1/12.jpg'/></div>
            <div>
              <div>Ollie’s grandfather has got three grandsons.<b> false</b></div>
              <div><b>1 &emsp;</b> Ollie’s grandfather has got three grandsons. <input id='0' /></div>
              <div><b>2 &emsp;</b> Ollie’s a good student. <input id='1' /></div>
              <div><b>3 &emsp;</b> Ollie spends a lot of time in front of a screen.  <input id='2' /></div>
              <div><b>4 &emsp;</b> Ollie’s grandfather works at a school. <input id='3' /></div>
              <div><b>5 &emsp;</b> Ollie’s grandfather likes photography <input id='4' /></div>
              <div><b>6 &emsp;</b> Ollie’s grandfather is happy when Ollie is on his phone. <input id='5' /></div>
            </div>
          </div>
        `,
        answer: ["false", "true", "true", "false", "true", "false"],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "WB7-U1-P12-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],

    inputSize: 110,
    exerciseKey: "img/FriendsPlus/Page12/E3/Key/answerKey.png",
    textAlign: "center",
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the text again. Complete the sentences with one word from the text.",
        color: "#d01435",
        star: 2,
      },
    ],
    questions: [
      {
        title: `
           <div style="display: flex; width: 1400px">
            <div style="padding: 20px"><img style="width: 83%"src='img/FriendsPlus/Page12/E1/12.jpg'/></div>
              <div>
                <div>Ollie is <u style="text-decoration: dotted underline;">&emsp;<b>thirteen</b>&emsp;</u> years old.</div>
                <div><b>1 &emsp;</b> Ollie often visits his grandfather at #.</div>
                <div><b>2 &emsp;</b> Every evening, Ollie # for one or two hours.</div>
                <div><b>3 &emsp;</b> Ollie is often on his #.</div>
                <div><b>4 &emsp;</b> Ollie’s grandfather has got a lot of different #.</div>
                <div><b>5 &emsp;</b> Ollie sometimes uses his grandfather’s #.</div>
                <div><b>6 &emsp;</b> Ollie and his grandfather have got different # about phones.</div>
              </div>
            </div>
          `,
        answer: ["home", "studies", "phone", "hobbies", "camera", "ideas"],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "WB7-U1-P12-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],

    inputSize: 700,
    exerciseKey: "img/FriendsPlus/Page12/E4/Key/answerKey.png",

    titleQuestion: [
      {
        num: "4",
        title: "Answer the questions. Write complete sentences.",
        color: "#d01435",
        star: 3,
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div>What is Ollie like? <u style="text-decoration: dotted underline;">&emsp;<b>He's friendly.</b>&emsp;</u></div>
                <div><b>1</b> When does Ollie visit his grandfather?</div>
                <div>#</div>
                <div><b>2</b> What do Ollie and his grandfather talk about?</div>
                <div>#</div>
                <div><b>3</b> What is Ollie’s grandfather good at?</div>
                <div>#</div>
                <div><b>4</b> What does Ollie’s grandfather do every day?</div>
                <div>#</div>
                <div><b>5</b> Who do you often visit? What do you do at their home?</div>
                <div>#</div>
                <div><b>6</b> Do you spend a lot of time in front of a screen? Why / Why not?</div>
                <div>#</div>
              </div>
          `,
        answer: [
          "He visits his grandfather after school.",
          "They talk about sport, cars, films and Ollie's day at school.",
          "He's good at maths.",
          "He runs.",
          "",
          "",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "WB7-U1-P12-E5",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],

    inputSize: 175,
    exerciseKey: "img/FriendsPlus/Page12/E5/Key/answerKey.png",
    textAlign: "center",
    titleQuestion: [
      {
        num: "5",
        title:
          "<b style='color: #d01435;'>VOCABULARY PLUS </b> Complete the sentences with one of the verbs in brackets.",
        color: "#d01435",
        star: 2,
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div>Our school <u style="text-decoration: dotted underline;">&emsp;<b>doesn't allow</b>&emsp;</u> food in the science labs. (doesn’t allow / doesn’t let)</div>
                <div><b>1</b> My mum and dad only # me watch TV when I finish all my homework. (allow / let)</div>
                <div><b>2</b> Our parents # us to play video games for more than an hour a day. (don’t allow / let)</div>
                <div><b>3</b> Our school # phones in class. (lets / bans)</div>
                <div><b>4</b> Our dad # TVs in our bedrooms. We always watch the TV downstairs together. (doesn’t allow / doesn’t let)</div>
              </div>
          `,
        answer: ["let", "don't allow", "bans", "doesn't allow"],
      },
    ],
  },
};

export default json;
