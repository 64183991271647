import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: "Unit 5",
		id: "WB7-U5-P32-E1",
		audio: "",
		video: "",
		component: T6,
		questionImage: [],
		inputSize: 140,
		stylesTextInput: { fontSize: 20, textAlign: 'center' },
		exerciseKey: "img/FriendsPlus/Page34/E1/Key/answerKey.png",
		maxLength: 1,
		titleQuestion: [
			{
				num: "1",
				title:
					"Look at the pictures. Correct the words.",
				color: "rgb(245, 128, 36)",
				star: 1
			},
		],
		questions: [
			{
				title: `
				<div style="font-size: 22px">
				  <tr>
					<td style="padding: 20px; text-align: center">
					  <img style="width: 100%" src='img/FriendsPlus/Page34/E1/1.jpg'/>
					  <div style=" font-weight: bold"><s> chess player</s></div>
					  <div style="width: 40%; text-align: center; color: grey; font-weight: bold; border-bottom: 1px dotted grey; margin-left: 70px"> inventor</div>
					</td>
					<td style="padding: 20px; text-align: center">
					  <img style="width: 100%" src='img/FriendsPlus/Page34/E1/2.jpg'/>
					  <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 10px;"><b>1 </b></div>
                  <div>
                    <div>musician</div>
                    <div>#</div>
                  </div>
                </div>
					</td>

					<td style="padding: 20px; text-align: center">
					  <img style="width: 100%" src='img/FriendsPlus/Page34/E1/3.jpg'/>
					  <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 10px;"><b>2 </b></div>
                  <div>
                    <div>composer</div>
                    <div>#</div>
                  </div>
                </div>
					</td>
					<td style="padding: 20px; text-align: center">
					  <img style="width: 100%" src='img/FriendsPlus/Page34/E1/4.jpg'/>
					  <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 10px;"><b>3 </b></div>
                  <div>
                    <div>writer</div>
                    <div>#</div>
                  </div>
                </div>
					</td>
				  </tr>
				  <tr>
					<td style="padding: 20px; text-align: center">
					  <img style="width: 100%" src='img/FriendsPlus/Page34/E1/5.jpg'/>
					  <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 10px;"><b>4 </b></div>
                  <div>
                    <div>scientist</div>
                    <div>#</div>
                  </div>
                </div>
					</td>
					<td style="padding: 20px; text-align: center">
					  <img style="width: 100%" src='img/FriendsPlus/Page34/E1/6.jpg'/>
					  <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 10px;"><b>5 </b></div>
                  <div>
                    <div>inventor</div>
                    <div>#</div>
                  </div>
                </div>
					</td>
					<td style="padding: 20px; text-align: center">
					  <img style="width: 100%" src='img/FriendsPlus/Page34/E1/7.jpg'/>
					  <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 10px;"><b>6 </b></div>
                  <div>
                    <div>artist</div>
                    <div>#</div>
                  </div>
                </div>
					</td>
					<td style="padding: 20px; text-align: center">
					  <img style="width: 100%" src='img/FriendsPlus/Page34/E1/8.jpg'/>
					  <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 10px;"><b>7 </b></div>
                  <div>
                    <div>mathematician</div>
                    <div>#</div>
                  </div>
                </div>
					</td>
				  </tr>
				</div>
			  `,
				answer: [
					"mathematician",
					"artist",
					"scientist",
					"chess player",
					"musician",
					"composer",
					"writer",
				],
			},
		],
	},
	2: {
		unit: "Unit 5",
		id: "WB7-U5-P34-E2",
		audio: "",
		video: "",
		component: T6,
		questionImage: [],
		hintBox: [{
			src: [
				"artist",
				"<s>champion</s>",
				"expert",
				"genius ",
				"inventor",
				"mathematician",
				"professor ",
				"programmer",
				"winner",
			],
			borderColor: "rgb(245, 128, 36)",
			width: "100%"
		}],
		inputSize: 200,
		exerciseKey: "img/FriendsPlus/Page34/E2/Key/answerKey.png",
		textAlign: 'center',
		titleQuestion: [
			{
				num: "2",
				title:
					"Read the clues and write the words.",
				color: "rgb(245, 128, 36)",
				star: 2
			},
		],
		questions: [
			{
				title: `
              <div>
                <hintbox id="0"></hintbox>
                <div> The best person in a big competition is the <u style="text-decoration: dotted underline;">&emsp;<b>champion</b>&emsp;</u></div>
                <div><b>1.</b>  If you’re the first person in a race, you’re the # </div>
                <div><b>2.</b> If you’re a very intelligent person like Einstein, you’re a # !</div>
                <div><b>3.</b> A painter is a type of # .</div>
                <div><b>4.</b> If you know a lot about one thing, you’re an # .</div>
                <div><b>5.</b> A # is very interested in numbers.</div>
                <div><b>6.</b> An important teacher at a university is a #</div>
                <div><b>7.</b> A # writes computer programs</div>
                <div><b>8.</b> An # is very creative and makes new things</div>
              </div>
          `,
				answer: ["winner", "genius", "artist", "expert", "mathematician", "professor", "programmer", "inventor"],
			},
		],
	},
	3: {
		unit: "Unit 5",
		id: "WB7-U5-P34-E3",
		audio: "Audios/1-05 TA 7 Friends Plus Workbook.mp3",
		video: "",
		component: Circle_Write,
		exerciseKey: "img/FriendsPlus/Page34/E3/Key/answerKey.png",
		titleQuestion: [
			{
				num: "3",
				title:
					"<audioimage name='05'></audioimage>Listen to the conversation about Ruth Lawrence and choose the correct words.",
				color: "rgb(245, 128, 36)",
				star: 2
			},
		],
		question: {
			Write: {
				inputStyle: {},
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					border: "none",
					borderRadius: "50%",
					borderColor: "transparent",
				},
				selectWordStyle: {
					padding: 5,
					border: "solid 2px",
					borderColor: "#000000",
				},
				limitSelect: 1,
				listWords: [
					"inventors /scientists / computer_programmers", //0
					"a_prodigy / an_average_student / a_good_student", //1
					"musician / mathematician / composer ", //0
					"twelve / thirteen / eighteen", //1
					"writer / chess_champion / professor ", //0
					"a_student / an_expert / a_genius ", //1
				],
				answers: ['0-6', '1-0', '2-4', '3-0', '4-8', '5-4'],
				initialValue: [],
			},
			Layout: `
							<div style="display: flex">
								<div>
									<div>Ruth Lawrence is <b style="border: solid 2px #000000; border-radius: 50%; padding: 5px; "> British</b> / <b> American / French </b> .</div>
									<div><b>1.</b> Ruth’s parents were <b><input id='0' type='Circle' /></b> you can.</div>
									<div><b>2.</b> As a child, Ruth was <b><input id='1' type='Circle' /></b> a few minutes it walked away</div>
									<div><b>3.</b> She wanted to be a <b><input id='2' type='Circle' /></b> we were walking in the forest, a monkey jumped down in front of us.</div>
									<div><b>4.</b> She started university at the age of <b><input id='3' type='Circle' /></b></div>
									<div><b>5.</b> She was a <b><input id='4' type='Circle' /></b> at the age of 22.</div>
									<div><b>6.</b> She’s now <b><input id='5' type='Circle' /></b> in a type of maths.</div>
								</div>
								<div><img style="width:150%" src='img/FriendsPlus/Page34/E3/1.jpg'/></div>
							</div>
          `,
		},
	},
	4: {
		unit: "Unit 5",
		id: "WB7-U5-P34-E4",
		audio: "Audios/1-05 TA 7 Friends Plus Workbook.mp3",
		video: "",
		component: T6,
		questionImage: [],
		inputSize: 150,
		exerciseKey: "img/FriendsPlus/Page34/E4/Key/answerKey.png",

		stylesTextInput: { textAlign: 'center', marginInline: '10px' },
		titleQuestion: [
			{
				num: "4",
				title:
					"<audioimage name='05'></audioimage> Listen again. Complete the sentences with the correct words.",
				color: "rgb(245, 128, 36)",
				star: 2
			},
		],
		questions: [
			{
				title: `
              <div>
                <div> Ruth was <u style="text-decoration: dotted underline;color: grey">&emsp;<b>born</b>&emsp;</u> in Brighton.</div>
                <div><b>1.</b> As a child, Ruth didn’t go to #</div>
                <div><b>2.</b> Ruth’s # taught her maths.</div>
                <div><b>3.</b> Ruth could do more # maths work than other children at an early age.</div>
                <div><b>4.</b> Ruth was # the student in the entrance exam to Oxford University</div>
                <div><b>5.</b> Ruth was the # student to get a degree from Oxford University in modern times.</div>
                <div><b>6.</b> She became a professor at an # university when she was 22.</div>
              </div>
          `,
				answer: [
					"school",
					"father",
					"difficult",
					"best",
					"youngest",
					"American",

				],
			},
		],
	},

	5: {
		unit: "Unit 5",
		id: "WB7-U5-P34-E5",
		audio: "",
		video: "",
		component: T6,
		questionImage: [],
		inputSize: 220,
		hintBox: [{
			src: [
				"archaeology",
				"conduct",
				"<s>decorate</s>",
				"economy",
				"electric",
				"farm",
				"fashion design",
				"magic",
				"mine",
				"optic",
				"pharmacy",
				"piano",
				"politics",
				"sail",
				"teach",
				"translate",
			],
			borderColor: "rgb(245, 128, 36)",
			width: "81%"
		}],
		exerciseKey: "img/FriendsPlus/Page34/E5/Key/answerKey.png",
		checkDuplicated: true,
		stylesTextInput: { textAlign: 'center' },
		titleQuestion: [
			{
				num: "5",
				title:
					" Make jobs from the words in the box and add them to the table.Use a dictionary to help you.",
				color: "rgb(245, 128, 36)",
				star: 3
			},
		],
		questions: [
			{
				title: `
					<hintbox id="0"></hintbox>
          <div style="display: flex; font-weight: 500; margin-top: 20px ">
						<div style="border: 2px solid rgb(251, 187, 135); border-radius: 10px 0 0 10px; overflow: hidden;">
							<div style=" font-weight: 400; color: white; background-color: rgb(251, 187, 135);"><b style="padding: 10px">Word ending</b></div>
							<div style="margin: 10px">-er</div>
							<div style="margin: 10px; height: 37.7px"></div>
							<div style="margin: 10px">-or</div>
							<div style="margin: 10px; height: 37.7px"></div>
							<div style="margin: 10px">-ist</div>
							<div style="margin: 10px; height: 37.7px"></div>
							<div style="margin: 10px">-ian</div>
							<div style="margin: 10px; height: 37.7px"></div>
						</div>
						<div style="border: 2px solid rgb(251, 187, 135); border-radius: 0 10px 10px 0; overflow: hidden;">
							<div style=" font-weight: 400; color: white; background-color: rgb(251, 187, 135);"><b style="padding: 10px">Jobs</b></div>
							<div style="margin: 10px">composer # # </div>
							<div style="margin: 10px"># #</div>
							<div style="margin: 10px ">professor <span style="color: grey; font-weight: bold; text-align: center; border-bottom: 1px dotted; padding-bottom: -40px; margin-inline: 10px">decorator</span> # </div>
							<div style="margin: 10px"># #</div>
							<div style="margin: 10px">artist # #</div>
							<div style="margin: 10px"># #</div>
							<div style="margin: 10px">mathematician # </div>
							<div style="margin: 10px"># # #</div>
						</div>
					</div>
        `,
				answer: [
					"farmer/ fashion designer/ miner/ teacher", "farmer/ fashion designer/ miner/ teacher", "farmer/ fashion designer/ miner/ teacher", "farmer/ fashion designer/ miner/ teacher",
					"conductor/ sailor/ translator", "conductor/ sailor/ translator", "conductor/ sailor/ translator",
					" archaeologist/ economist/ pharmacist/ pianist", " archaeologist/ economist/ pharmacist/ pianist", " archaeologist/ economist/ pharmacist/ pianist", " archaeologist/ economist/ pharmacist/ pianist",
					"electrician/magician/optician/politician", "electrician/magician/optician/politician", "electrician/magician/optician/politician", "electrician/magician/optician/politician"
				],
			},
		],
	},
}

export default json;