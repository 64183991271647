import React from 'react'
import styles from './videoimage.module.css'
export default function VideoImage() {
  return (
    <div className={styles.container}>
      <div className={styles.icon_container}>
        <i className={`fas fa-play ${styles.icon_container_content}`}/>
      </div>
    </div>
  )
}
