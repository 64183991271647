import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';



const json = {
	1: {
		unit: "Unit 6",
		id: "WB7-U6-P38-E1",
		audio: "",
		video: "",
		component: MatchDots,
		exerciseKey: "img/FriendsPlus/Page38/E1/Key/answerKey.png",
		titleQuestion: [
			{
				num: "1",
				title: "Match 1–8 with a–h.",
				color: "#0167B4",
				star: 1
			},
		],
		// titleQuestion: [{ num: '2', title: "Listen again and draw the route to Leo's school.", color: "#2E479B" }],
		question: {
			DrawLines: {
				multipleLine: false,
				boxMatch: [
					{ boxMatchStyle: { position: "absolute", top: 115, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //0
					{ boxMatchStyle: { position: "absolute", top: 115, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //0
					{ boxMatchStyle: { position: "absolute", top: 155, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //1
					{ boxMatchStyle: { position: "absolute", top: 155, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //2
					{ boxMatchStyle: { position: "absolute", top: 195, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //3
					{ boxMatchStyle: { position: "absolute", top: 195, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //5
					{ boxMatchStyle: { position: "absolute", top: 235, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //4
					{ boxMatchStyle: { position: "absolute", top: 235, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //7
					{ boxMatchStyle: { position: "absolute", top: 270, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //8
					{ boxMatchStyle: { position: "absolute", top: 270, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //9
					{ boxMatchStyle: { position: "absolute", top: 310, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //10
					{ boxMatchStyle: { position: "absolute", top: 310, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //11
					{ boxMatchStyle: { position: "absolute", top: 355, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //8
					{ boxMatchStyle: { position: "absolute", top: 355, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //9
					{ boxMatchStyle: { position: "absolute", top: 395, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //10
					{ boxMatchStyle: { position: "absolute", top: 395, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //11
				],
				answers: ['0-3', '13-2', '1-4', '11-6', '5-8', '10-15', '12-7', '14-9'],
				initialValue: ["0-3"],
			},
			Layout: `
        <div style="background-color: rgb(209, 210, 212); border-radius: 10px; padding: 10px 5px;width: fit-content; font-size: 24px; color: black; font-weight: bold; margin: 20px 0">How to survive in the jungle</div>
        <div style="font-size: 26px;">
          <div style='display: flex;'>
            <div style='width: 200px;'><b>1</b> Build</div>
						<div><input id='0' type= 'boxMatch' /></div>
            <div><input id='1' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>a.</b> cool when it’s very hot.</div>
          </div>

					<div style='display: flex;'>
            <div style='width: 200px;'><b>2</b> Climb</div>
						<div><input id='2' type= 'boxMatch' /></div>
            <div><input id='3' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>b.</b> a shelter.</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 200px;'><b>3</b> Keep</div>
						<div><input id='4' type= 'boxMatch' /></div>
            <div><input id='5' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>c.</b> a fire at night.</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 200px;'><b>4</b> Run</div>
						<div><input id='6' type= 'boxMatch' /></div>
            <div><input id='7' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>d.</b> water</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 200px;'><b>5</b> Light</div>
						<div><input id='8' type= 'boxMatch' /></div>
            <div><input id='9' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>e.</b> dangerous plants.</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 200px;'><b>6</b> Pick</div>
						<div><input id='10' type= 'boxMatch' /></div>
            <div><input id='11' type= 'boxMatch' /></div>
						<div style='margin-left: 120px; margin-top: 4px;'><b>f.</b> away from tigers!</div>
          </div>
          <div style='display: flex;'>
            <div style='width: 200px;'><b>7</b>  Find drinking</div>
						<div><input id='12' type= 'boxMatch' /></div>
            <div><input id='13' type= 'boxMatch' /></div>
						<div style='margin-left: 120px; margin-top: 4px;'><b>g.</b> a tree.</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 200px;'><b>8</b> Avoid all </div>
						<div><input id='14' type= 'boxMatch' /></div>
            <div><input id='15' type= 'boxMatch' /></div>
						<div style='margin-left: 120px; margin-top: 4px;'><b>h.</b> fruit, but be very careful!</div>
          </div>
        </div>
      `,
		},
	},
	2: {
		unit: "Unit 6",
		id: "WB7-U6-P38-E2",
		audio: "",
		video: "",
		component: T6,
		questionImage: [],
		stylesTextInput: { fontSize: 23 },
		hintBox: [{
			src: [
				"build",
				"follow",
				"make",
				"move",
				"<s>Stand</s>",
				"Stay",
				"use",
			],
			borderColor: "#0167B4",
			width: "65%"
		}],
		inputSize: 110,
		exerciseKey: "img/FriendsPlus/Page38/E2/Key/answerKey.png",
		textAlign: 'center',

		titleQuestion: [
			{
				num: "2",
				title:
					" Complete the dialogues with the words.",
				color: "#0167B4",
				star: 2
			},
		],
		questions: [
			{
				title: `
							<div><hintbox id='0'></hintbox></div>
               <div>
                <div>‘Look! There’s a big spider on my foot.’ </div>
                <div>‘Don’t move. <u style="text-decoration: dotted underline;color: grey">&emsp;<b>Stand</b>&emsp;</u> still and it will go away.’</div>
                </div>
                <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 10px;"><b>1 </b></div>
                  <div>
                    <div>‘I can hear something in the trees. What is it?’</div>
                    <div>‘Be quiet for a moment. Don’t # a noise. Let’s listen.’</div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 10px;"><b>2 </b></div>
                  <div>
                    <div>‘Our tent is broken! What can we do?’</div>
                    <div>‘Don’t worry. We can # a shelter and sleep in it tonight.’</div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 10px;"><b>3 </b></div>
                  <div>
                    <div> ‘I can’t walk in this hot sun!’ </div>
                    <div> ‘I think we need to # at night when it’s dark and a lot cooler.’</div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 10px;"><b>4 </b></div>
                  <div>
                    <div>‘How can we find the next village?’</div>
                    <div>‘Let’s try to # the river. There are often villages next to water.’</div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 10px;"><b>5 </b></div>
                  <div>
                    <div>‘Help! There’s a snake!’</div>
                    <div>‘Don’t run away. # where you are. That’s the best thing to do.’</div>
                  </div>
                </div>
              </div>
                <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 10px;"><b>6 </b></div>
                  <div>
                    <div>‘Which direction is east?’ </div>
                    <div>‘I think we need to # the sun in the sky. It always comes up in the east.’</div>
                  </div>
                </div>
              </div>
          `,
				answer: [" make", "build", "move", "follow", "Stay", "use"],
			},
		],
	},
	3: {
		unit: "Unit 6",
		id: "WB7-U6-P38-E3",
		audio: "",
		video: "",
		component: T6,
		questionImage: [],
		stylesTextInput: { borderBottom: "none", fontSize: 20 },
		inputSize: 170,
		exerciseKey: "img/FriendsPlus/Page38/E3/Key/answerKey.png",
		maxLength: 16,

		isHiddenCheck: true,
		textAlign: "center",
		hintBox: [{
			src: [
				"born",
				"buy",
				"died",
				"eventually",
				"exciting",
				"first ",
				"history",
				"<s>love</s>",
				"sold",
			],
			width: "420px",
			borderColor: "#0167B4",
		}],
		titleQuestion: [
			{
				num: "3",
				title:
					"  Complete the text using the past simple form of the correct verbs in exercises 1 and 2. Use the irregular verbs list on page 70 to help you.",
				color: "#0167B4",
				star: 2
			},
		],
		questions: [
			{
				title: `
          <div style="position: relative; margin: 10px">
            <div><img src='img/FriendsPlus/Page38/E3/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 233px; left: 150px; "><input id='0' /></div>
							<div style=" position: absolute; top: 260px; left: 346px; "><input id='1' /></div>
							<div style=" position: absolute; top: 347px; left: 219px; "><input id='2' /></div>
							<div style=" position: absolute; top: 375px; left: 268px; "><input id='3' /></div>
							<div style=" position: absolute; top: 402px; left: 286px; "><input id='4' /></div>
							<div style=" position: absolute; top: 456px; left: 84px; "><input id='5' /></div>

            </div>
          </div>
        `,
				answer: [
					"avoided",
					"found",
					"climbed",
					"followed",
					"kept/stayed",
					"moved",
				]
			}
		],
	},
	4: {
		unit: "Unit 6",
		id: "WB7-U6-P38-E4",
		audio: "",
		video: "",
		component: T6,
		questionImage: [],
		textareaStyle: { width: 1000, resize: "none", borderBottom: "none" },
		hideBtnFooter: true,
		titleQuestion: [
			{
				num: "4",
				title:
					"Write ideas for these survival situations. Use some of the vocabulary from this page.",
				color: "#0167B4",
				star: 3
			},
		],
		questions: [
			{
				title: `
          <div>You’re in a very hot country and you’re worried about getting sunburned.</div>
					<div style="color: grey; font-weight: bold">I need to build a shelter and sit inside it when the sunis hot.</div>
          <div>
            <div style="display: flex">
							<div style="display: inline-block; width: 20px;"><b>1</b></div>
							<div>
								<div>You see an animal in the jungle. You don’t know if it’s dangerous.</div>
								<div><textarea id="0" rows="2">I need to </textarea></div>
							</div>
						</div>
            <div style="display: flex">
							<div style="display: inline-block; width: 20px;"><b>2</b></div>
							<div>
								<div>You’re in the desert and it’s cold at night.</div>
								<div><textarea id="1" rows="2">I need to </textarea></div>
							</div>
						</div>
            <div style="display: flex">
							<div style="display: inline-block; width: 20px;"><b>3</b></div>
							<div>
								<div>You’re lost in the jungle and you see a plane in the sky. You want the plane to rescue you.</div>
								<div><textarea id="2" rows="2">I need to </textarea></div>
							</div>
						</div>
            <div style="display: flex">
							<div style="display: inline-block; width: 20px;"><b>4</b></div>
							<div>
								<div>You’re in a big forest. You fell down and now you can’t walk. Your friend goes for help. You’re alone.</div>
								<div><textarea id="3" rows="2">I need to </textarea></div>
							</div>
						</div>
          </div>
        `,
				answer: [],
			},
		],
	},

}

export default json;
