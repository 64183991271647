import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {},
  2: {
    unit: 'Unit 1',
    id: 'WB7-U1-P4-E2',
    component: T6,
    inputSize: '130px',
    exerciseKey: 'img/FriendsPlus/Page4/E2/Key/answerKey.png',

    stylesTextInput: { fontSize: 18, borderBottom: 'none', textAlign: 'center' },
    hintBox: [{
      src: [
        "aunt",
        "brother",
        "cousins",
        "dad",
        "daughter",
        "grandfather",
        "grandmother",
        "husband",
        "<s>mum</s>",
        "twin",
        "son",
        "uncles",
        "wife"
      ],
      borderColor: "#8fadd4",
      width: "92%"
    }],
    titleQuestion: [
      {
        num: "2",
        title:
          " Look at the family tree. Complete the text with the words.",
        color: "#1d71a9",
        star: 2
      },
    ],
    questions: [
      {
        title: `
        <div style=" position: relative">
          <div><hintbox id="0"></hintbox></div>
          <img src='img/FriendsPlus/Page4/E2/1.jpg'>
          <div style=" position: absolute; top: 190px; left: 615px; ">#</div>
          <div style=" position: absolute; top: 215px; left: 592px; ">#</div>
          <div style=" position: absolute; top: 240px; left: 589px; ">#</div>
          <div style=" position: absolute; top: 265px; left: 761px; ">#</div>
          <div style=" position: absolute; top: 315px; left: 589px; ">#</div>
          <div style=" position: absolute; top: 315px; left: 826px; ">#</div>
          <div style=" position: absolute; top: 340px; left: 771px; ">#</div>
          <div style=" position: absolute; top: 365px; left: 632px; ">#</div>
          <div style=" position: absolute; top: 391px; left: 588px; ">#</div>
          <div style=" position: absolute; top: 416px; left: 616px; ">#</div>
          <div style=" position: absolute; top: 416px; left: 879px; ">#</div>
          <div style=" position: absolute; top: 441px; left: 695px; ">#</div>
        </div>`,
        answer: ["dad", "twin", "brother", "uncles", "aunt", "wife", "daughter", "son", "cousins", "grandmother", "husband", "grandfather"]
      }
    ]
  },
  3: {
    unit: 'Unit 1',
    id: 'WB7-U1-P4-E3',
    component: T6,
    inputSize: '220px',
    exerciseKey: 'img/FriendsPlus/Page4/E3/Key/answerKey.png',

    maxLength: 13,
    hintBox: [{
      src: [
        "<s>brother</s>",
        " child",
        "cousin",
        "granddaughter",
        "grandson",
        "nephew",
        "niece"
      ],
      borderColor: "#8fadd4",
      width: "74%"
    }],
    titleQuestion: [
      {
        num: "3",
        title:
          "Look back at the family tree in exercise 2. Correct the sentences with the words.",
        color: "#1d71a9",
        star: 2
      },
    ],
    questions: [
      {
        title: `
        <div style=" display: flex; justify-content: center;">
          <div>
            <div style="margin-block: 10px">
              <hintbox id="0"></hintbox>
            </div>
            <div><img style="width:74%" src='img/FriendsPlus/Page4/E3/1.jpg'></div>
            </div>
          <div>
            <div>Sam is Bill's <b style="text-decoration: line-through">uncle</b>.</div>
            <div style="color: grey;">⇒ Sam is Bill's brother.</div>
            <div><b>1</b>. Lucy is Betty's <b>daughter</b>.</div>
            <div>#</div>
            <div><b>2</b>. Kate is Mary's <b>aunt</b>.</div>
            <div>#</div>
            <div><b>3</b>. Kate is Lucy's twin <b>sister</b>.</div>
            <div>#</div>
            <div><b>4</b>. Kate is Jane's <b>mum</b>.</div>
            <div>#</div>
            <div><b>5</b>. Dan is Sam's <b>cousin</b>.</div>
            <div>#</div>
            <div><b>6</b>. Tom is John's <b>son</b>.</div>
            <div>#</div>
            </div>
          </div>
        </div>`,
        answer: ["granddaughter", "niece", "cousin", "child", "nephew", "grandson"]
      }
    ]
  },
  4: {
    unit: "Unit 1",
    id: "WB7-U1-P4-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 600 },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Write sentences about the people in your family.",
        color: "#1d71a9",
        star: 3
      },
    ],
    questions: [
      {
        title: `
          <div><textarea id="0" rows="7">My uncle’s name is Anton. His partner is from Germany. Her name is Katja.</textarea></div>
        `,
        answer: []
      },
    ]
  },
};

export default json;
