import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: {
    unit: "Unit 5",
    id: "WB7-U5-P33-E1",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page33/E1/Key/answerKey.png",
    maxLength: 16,

    isHiddenCheck: true,
    textAlign: "center",
    hintBox: [{
      src: [
        "braver",
        "more important",
        "thinner",
        "better ",
        "bravest",
        "easiest",
        "best",
        "worst",
        "most important ",
        "<s>slower</s>",
        "thinnest",
        "worse",
        "easier",
        "slowest",
      ],
      width: "550px",
      borderColor: "#69d2e4",
    }],
    titleQuestion: [
      {
        num: "1",
        title:
          " Complete the table with the words.",
        color: "#69d2e4",
        star: 1
      },
    ],
    questions: [
      {
        title: `
        <div><hintbox id='0'></hintbox></div>
          <div style="position: relative; margin: 10px">
            <div><img src='img/FriendsPlus/Page33/E1/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 45px; left: 390px; "><input id='0' /></div>
              <div style=" position: absolute; top: 76px; left: 200px; "><input id='1' /></div>
              <div style=" position: absolute; top: 76px; left: 390px; "><input id='2' /></div>
              <div style=" position: absolute; top: 106px; left: 200px; "><input id='3' /></div>
              <div style=" position: absolute; top: 106px; left: 390px; "><input id='4' /></div>
              <div style=" position: absolute; top: 135px; left: 200px; "><input id='5' /></div>
              <div style=" position: absolute; top: 135px; left: 390px; "><input id='6' /></div>
              <div style=" position: absolute; top: 187px; left: 200px; "><input id='7' /></div>
              <div style=" position: absolute; top: 214px; left: 195px; "><input id='8' /></div>
              <div style=" position: absolute; top: 187px; left: 390px; "><input id='9' /></div>
              <div style=" position: absolute; top: 214px; left: 358px; "><input id='10' /></div>
              <div style=" position: absolute; top: 270px; left: 200px; "><input id='11' /></div>
              <div style=" position: absolute; top: 270px; left: 390px; "><input id='12' /></div>
              <div style=" position: absolute; top: 300px; left: 200px; "><input id='13' /></div>
              <div style=" position: absolute; top: 300px; left: 390px; "><input id='14' /></div>
            </div>
          </div>
        `,
        answer: [
          "slowest",
          "braver",
          "bravest",
          "thinner",
          "thinnest",
          "easier",
          "easiest",
          "more", "important",
          "most", "important",
          "better",
          "best",
          "worse",
          "worst"
        ]
      }
    ],
  },
  2: {
    unit: "Unit 5",
    id: "WB7-U5-P33-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    inputSize: 220,
    exerciseKey: "img/FriendsPlus/Page33/E2/Key/answerKey.png",

    stylesTextInput: { textAlign: 'center' },
    titleQuestion: [
      {
        num: "2",
        title:
          "Complete the sentences with the comparative form of the adjectives.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div> Susana is <u style="text-decoration: dotted underline;color: grey">&emsp;<b>quieter</b>&emsp;</u>  than her baby sister.</div>
                <div><b>1.</b> Kevin is often # (slow) than Andy.</div>
                <div><b>2.</b> Whales have got # (big) brains than humans.</div>
                <div><b>3.</b> This rucksack is # (expensive) than that bag.</div>
                <div><b>4.</b> Fruit is # (good) for you than sweets.</div>
                <div><b>5.</b> The new sports centre is # (modern) than the old stadium</div>
                <div><b>6.</b> Their classroom is # (dirty) than our nice clean room.</div>
              </div>
          `,
        answer: [
          "slower",
          "bigger",
          "more expensive",
          "better",
          "more modern",
          "dirtier",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "WB7-U5-P33-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    inputSize: 250,
    exerciseKey: "img/FriendsPlus/Page33/E3/Key/answerKey.png",

    stylesTextInput: { textAlign: 'center' },
    titleQuestion: [
      {
        num: "3",
        title:
          "Complete the questions with the superlative form of one of the adjectives.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div> Who’s  <u style="text-decoration: dotted underline;color: grey">&emsp;<b>the fastest</b>&emsp;</u> runner in your class?</div>
                <div><b>1.</b>  Where’s # building in the city? (heavy, tall)</div>
                <div><b>2.</b> What’s # picture in this museum? (beautiful, strict)</div>
                <div><b>3.</b> When’s # bus in the evening? (easy, early)</div>
                <div><b>4.</b> Who’s # singer on the TV talent show? (bad, cruel)</div>
                <div><b>5.</b> What’s # subject at school? (brave, easy)</div>
                <div><b>6.</b> When’s # time of year to visit your town? (patient, good)</div>
              </div>
          `,
        answer: [
          "the tallest",
          "the most beautiful",
          "the earliest ",
          "the worst ",
          "the easiest",
          "the best",
        ],
      },
    ],
  },

  4: {
    unit: "Unit 5",
    id: "WB7-U5-P33-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    inputSize: 250,
    exerciseKey: "img/FriendsPlus/Page33/E4/Key/answerKey.png",

    stylesTextInput: { textAlign: 'center' },
    hintBox: [{
      src: [
        "exciting",
        "good",
        "old",
        "small",
        "tall",
        "young",
      ],
      width: "550px",
      borderColor: "#69d2e4",
    }],
    titleQuestion: [
      {
        num: "4",
        title:
          "Complete the sentences with the most suitable comparison of the adjectives.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div style="text-align: center">
                <div style="display: flex; justify-content: center; margin: 10px"><hintbox id='0'></hintbox></div>
                <div><img style="width: 39%" src='img/FriendsPlus/Page33/E4/1.jpg' /></div>
              </div>
              <div style="; font-size: 22px">
                <div> Jamal and Ed are not <u style="text-decoration: dotted underline;color: grey">&emsp;<b>as tall as</b>&emsp;</u> Ricky</div>
                <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 10px;"><b>1 </b></div>
                  <div>
                    <div>Ed is # – he’s 1.89 m.</div>
                    <div>He’s # Jamal and Ricky.</div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 10px;"><b>2 </b></div>
                  <div>
                    <div>Ricky and Ed are not # Jamal.</div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 10px;"><b>3 </b></div>
                  <div>
                    <div> Ricky is # person – he’s still at school! He’s a lot # Ed and Jamal.</div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 10px;"><b>4 </b></div>
                  <div>
                    <div>Ed is not # Jamal. </div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 14px;"><b>5 </b></div>
                  <div>
                    <div>Ed is # player in the team because he can run really fast. I think he’s # the other players.</div>
                  </div>
                </div>
              </div>
          `,
        answer: [
          "the smallest", "smaller than",
          " as old as",
          "the youngest ", "younger than",
          "as good as ",
          "the most exciting", "more exciting than",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "WB7-U5-P33-E5",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    inputSize: 380,
    exerciseKey: "img/FriendsPlus/Page33/E5/Key/answerKey.png",

    stylesTextInput: { textAlign: 'left', fontSize: 25 },
    hintBox: [{
      src: [
        "exciting",
        "good",
        "old",
        "small",
        "tall",
        "young",
      ],
      width: "550px",
      borderColor: "#69d2e4",
    }],
    titleQuestion: [
      {
        num: "5",
        title:
          " Rewrite the sentences using the words given in brackets.",
        color: "#69d2e4",
        star: 3
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 10px;"><b>1 </b></div>
                  <div>
                    <div>This book is not similar to that one.</div>
                    <div>This book # . (from)</div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 10px;"><b>2 </b></div>
                  <div>
                    <div>She studies the best in this class.</div>
                    <div>No one else in # . (well)</div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 10px;"><b>3 </b></div>
                  <div>
                    <div> He looks different from his brother.</div>
                    <div> He doesn't # . (like)</div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 10px;"><b>4 </b></div>
                  <div>
                    <div>Peter was born in 1982. Nancy was born in 1985. </div>
                    <div>Nancy is # . (than) </div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="display: inline-block; width: 20px; margin-right: 10px;"><b>5 </b></div>
                  <div>
                    <div>Nothing else is as precious as independence.</div>
                    <div>Independence is # . (most)</div>
                  </div>
                </div>
              </div>
          `,
        answer: [
          "is different from that one",
          "this class studies as well as her",
          "look like his brother",
          "younger than Peter",
          "the most precious",
        ],
      },
    ],
  },
}

export default json;