import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'WB7-U6-P43-E1',
    audio: 'img/FriendsPlus/Page42/Audio/audio-e2-p42.mp3',
    video: 'Videos/e2.p42.mp4',
    component: UI,
    recorder: true,
    titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      // [
      //   // Column1
      //   { url: 'img/FriendsPlus/Page42/E2/1.jpg' },
      //   { url: 'img/FriendsPlus/Page42/E2/2.jpg', audioUrl: "img/FriendsPlus/Page42/Audio/tieude-e2-p42.mp3" },
      //   { url: 'img/FriendsPlus/Page42/E2/3.jpg' },
      // ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page42/E2/4.jpg', audioUrl: "img/FriendsPlus/Page42/Audio/hinh1-e2-p42.mp3" },
        { url: 'img/FriendsPlus/Page42/E2/5.jpg', audioUrl: "img/FriendsPlus/Page42/Audio/hinh2-e2-p42.mp3" },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page42/E2/6.jpg', audioUrl: "img/FriendsPlus/Page42/Audio/hinh3-e2-p42.mp3" },
        { url: 'img/FriendsPlus/Page42/E2/7.jpg', audioUrl: "img/FriendsPlus/Page42/Audio/hinh4-e2-p42.mp3" },
      ],
    ],
  },
  2: {
    unit: "Unit 6",
    id: "WB7-U6-P43-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 23 },
    hintBox: [{
      src: [
        "be",
        "<s>bring</s>",
        "eat",
        "go",
        "remember",
        "sing",
        "walk ",
        "watch",
        "wear",
      ],
      borderColor: "#00a23b",
      width: "100%"
    }],
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page43/E2/Key/answerKey.png",
    textAlign: 'center',
    titleQuestion: [
      {
        num: "2",
        title:
          "Complete the sentences using the affirmative or negative imperatives of the verbs.",
        color: "#00a23b",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <hintbox id="0"></hintbox>
                <div><u style="text-decoration: dotted underline;">&emsp;<b>Don't bring</b>&emsp;</u> expensive things in your school bag.</div>
                <div><b>1.</b> # videos on your mobile phone at school. </div>
                <div><b>2.</b> Always # to do your homework! </div>
                <div><b>3.</b> # food in class! You should never do that. </div>
                <div><b>4.</b> And always # polite to your teachers, too </div>
                <div><b>5.</b> #  in the science block. Don’t run!</div>
                <div><b>6.</b> #  songs or listen to them on your phone.</div>
                <div><b>7.</b> And # sports clothes in PE lessons</div>
                <div><b>8.</b> # to bed late when there’s school in the morning</div>
                </div>
                `,
        answer: [" Don't watch / Do not watch", "remember", "Don't eat /Do not eat", "be", "Walk", "Don't sing / Do not sing", "wear", "Don't go / Do not go"],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "WB7-U6-P43-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    inputSize: 500,
    exerciseKey: "img/FriendsPlus/Page43/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          " Read the notes and complete the text.",
        color: "#00a23b",
        star: 2
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
            <div style="display: flex;">
            <div style="flex:1;">
              <img src='img/FriendsPlus/Page43/E3/1.jpg' />
              <br/><br/>
              <div><b>1</b>&ensp;#</div>
              <div><b>2</b>&ensp;#</div>
              <div><b>3</b>&ensp;#</div>
              <div><b>4</b>&ensp;#</div>
              <div><b>5</b>&ensp;#,</div>
              <div>&emsp;#</div>
              <div><b>6</b>&ensp;#,</div>
              <div>&emsp;#</div>
            </div>
            <div style="flex:1;"><img src='img/FriendsPlus/Page43/E3/2.jpg' /></div>
            </div>
        `,
        answer: [
          `important to have the right equipment`,
          `forget your trainers`,
          `you should arrive early`,
          `a good idea to chat to the other people`,
          `you talk to them`, `they'll think you're friendly / they will think you are friendly`,
          `you need help`, `speak to the club organiser`,
        ]
      }
    ],
  },
  4: {
    unit: "Unit 6",
    id: "WB7-U6-P43-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1000, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    hintBox: [{
      src: [
        "learning an instrument ",
        "starting a new club",
        "going on a school camp",
      ],
      borderColor: "#00a23b",
      width: "100%"
    }],
    titleQuestion: [
      {
        num: "4",
        title:
          "   Imagine your friend is doing something new. Write the friend an email with some advice. Use the text in exercise 3 to help you. Choose one of the ideas in the box or use your own ideas.",
        color: "#00a23b",
        star: 3
      },
    ],
    questions: [
      {
        title: `
          <hintbox id="0"></hintbox>
          <div><textarea id="0" rows="11"></textarea></div>
        `,
        answer: [],
      },
    ],
  },
}

export default json;