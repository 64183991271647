import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import DesignUnderLine from "../../components/ExcerciseTypes/Design/UnderLine";
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
	1: {
		unit: "Cumulative Review",
		id: "WB7-CR-P56-E1",
		component: T6,
		questionImage: [],
		inputSize: 280,
		exerciseKey: "img/FriendsPlus/Page56/E1/Key/answerKey.png",
		stylesTextInput: { marginInline: '10px', fontSize: 24 },

		titleQuestion: [
			{
				num: "1",
				title:
					"Look at the photo",
				color: "#4D3B98",
			},
		],
		questions: [
			{
				title: `
              <div>
								<div>What is the boy doing?</div>
								<div>#</div>
								<div>Do you like doing this activity?</div>
								<div>#</div>
                <div><img src='img/FriendsPlus/Page56/E1/1.jpg' /></div>
              </div>
          `,
				answer: [
					"Making a film. ",
					"",
				],
			},
		],
	},
	2: {
		unit: "Cumulative Review",
		id: "WB7-CR-P56-E2",
		component: T6,
		questionImage: [],
		inputSize: 740,
		exerciseKey: "img/FriendsPlus/Page56/E2/Key/answerKey.png",
		stylesTextInput: { marginInline: '10px', fontSize: 24 },

		titleQuestion: [
			{
				num: "2",
				title:
					"Read the online profile. Why is Michael writing this profile? ",
				color: "#4D3B98",
			},
		],
		questions: [
			{
				title: `
              <div>
								<div>#</div>
                <div><img style="width: 74%"src='img/FriendsPlus/Page56/E2/1.jpg' /></div>
              </div>
          `,
				answer: [
					"Because he wants to find some friends to make films with. ",
				],
			},
		],
	},
	3: {
		unit: "Cumulative Review",
		id: "WB7-CR-P56-E3",
		audio: "",
		video: "",
		component: Circle_Write,
		exerciseKey: "img/FriendsPlus/Page56/E3/Key/answerKey.png",
		titleQuestion: [
			{
				num: "3",
				title:
					"Read the text again. Choose the correct words.",
				color: "#4D3B98",
			},
		],
		question: {
			Write: {
				inputStyle: {},
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					border: "none",
					borderRadius: "50%",
					borderColor: "transparent",
				},
				selectWordStyle: {
					padding: 5,
					border: "solid 2px",
					borderColor: "#000000",
				},
				limitSelect: 1,
				listWords: [
					"thirteen / fourteen",
					"alone / with_friends",
					"can / can’t",
					"blogging_about / making ",
					"creative / old",
					"funny / scary",
					"sport / music",
					"phone / email",
				],
				answers: ['0-4', '1-0', '2-0', '3-4', '4-0', '5-4', '6-4', '7-4'],
				initialValue: [],
			},
			Layout: `
              <div style="display: flex">
								<div><img style="width: 74%"src='img/FriendsPlus/Page56/E2/1.jpg' /></div>
								<div>
									<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> Michael is <b><input id='0' type='Circle' /></b>.</div></div>
									<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> At the moment, he does his hobby <b><input id='1' type='Circle' /></b>.</div></div>
									<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> He <b><input id='2' type='Circle' /></b> watch films in English.</div></div>
									<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> He prefers <b><input id='3' type='Circle' /></b> films.</div></div>
									<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> He wants to find people who are <b><input id='4' type='Circle' /></b>.</div></div>
									<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> He wants to make a <b><input id='5' type='Circle' /></b> film.</div></div>
									<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>7. </b></div><div style="margin-left: 30px; flex: 1; "> He’s looking for people who like <b><input id='6' type='Circle' /></b>.</div></div>
									<div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>8. </b></div><div style="margin-left: 30px; flex: 1; "> You can <b><input id='7' type='Circle' /></b> him</div></div>
								</div>
              </div>
          `,
		},
	},

	4: {
		// Exercise num
		unit: "Cumulative Review",
		id: "WB7-CR-P56-E4",
		audio: "Audios/1-09 TA 7 Friends Plus Workbook.mp3",
		video: "",
		component: DesignUnderLine,
		totalInput: 1,
		titleQuestion: [
			{
				num: "4",
				title:
					"<audioimage name='09'></audioimage>Michael is now talking on Skype with Dasha and Adam.Listen to the conversation. Which picture do they not talk about?",
				color: "#4D3B98",
			},
		],
		exerciseKey: "img/FriendsPlus/Page56/E4/Key/answerKey.png",
		questionImage: [
			[
				// Column2
				{ url: "img/FriendsPlus/Page56/E4/1.jpg", input: 1, },
				{ url: "img/FriendsPlus/Page56/E4/2.jpg", input: 1 },
				{ url: "img/FriendsPlus/Page56/E4/3.jpg", input: 1, isCorrect: true },
			],
		],
	},

	5: {
		unit: "Cumulative Review",
		id: "WB7-CR-P56-E5",
		component: T6,
		questionImage: [],
		audio: "Audios/1-09 TA 7 Friends Plus Workbook.mp3",
		inputSize: 135,
		exerciseKey: "img/FriendsPlus/Page56/E5/Key/answerKey.png",

		stylesTextInput: { marginInline: '10px', fontSize: 24 },
		titleQuestion: [
			{
				num: "5",
				title:
					" <audioimage name='09'></audioimage> Listen again and complete the sentences.",
				color: "#4D3B98",
			},
		],
		questions: [
			{
				title: `
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> Adam is the last person who joins the <b>S</b># call.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> Adam’s <b>c</b># is in the wrong place.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> The <b>f</b># isn’t finished.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> Adam always plays <b>b</b># on Mondays.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> Adam studies in the science <b>l</b># at lunchtime.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> There are more <b>s</b># who are making the film.</div></div>
              </div>
          `,
				answer: [
					"Skype ",
					"camera  ",
					"film ",
					"basketball ",
					"lab ",
					"students",
				],
			},
		],
	},
	6: {
		unit: "Cumulative Review",
		id: "WB7-CR-P56-E6",
		component: T6,
		questionImage: [],
		inputSize: 125,
		exerciseKey: "img/FriendsPlus/Page56/E5/Key/answerKey.png",
		textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
		stylesTextInput: { marginInline: '10px', fontSize: 24 },
		hideBtnFooter: true,
		titleQuestion: [
			{
				num: "6",
				title:
					" A TASK Imagine you are looking for students to join a club. Write a profile on your school website.",
				color: "#4D3B98",
			},
		],
		questions: [
			{
				title: `
              <div>
								<div><textarea id="0" rows="11"></textarea></div>
              </div>
          `,
				answer: [],
			},
		],
	},
	7: {
		unit: "Cumulative Review",
		id: "WB7-CR-P56-E7",
		component: T6,
		questionImage: [],
		inputSize: 125,
		textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
		stylesTextInput: { marginInline: '10px', fontSize: 24 },
		hideBtnFooter: true,
		titleQuestion: [
			{
				num: "6",
				title:
					" B THINK AND PLAN",
				color: "#4D3B98",
			},
		],
		questions: [
			{
				title: `
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1; "> What’s your name, how old are you and whose class are you in?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1; "> What’s your hobby? Give some details.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3 </b></div><div style="margin-left: 30px; flex: 1; "> What do the students need to be like?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4 </b></div><div style="margin-left: 30px; flex: 1; "> What do you want to do at the club?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5 </b></div><div style="margin-left: 30px; flex: 1; "> How can the students contact you?</div></div>
	              </div>
          `,
				answer: [],
			},
		],
	},
	8: {
		unit: "Cumulative Review",
		id: "WB7-CR-P56-E8",
		component: T6,
		questionImage: [],
		inputSize: 125,
		exerciseKey: "img/FriendsPlus/Page56/E5/Key/answerKey.png",
		textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
		stylesTextInput: { marginInline: '10px', fontSize: 24 },
		hideBtnFooter: true,
		titleQuestion: [
			{
				num: "6",
				title:
					" C WRITE Use the text in exercise 2 and the writing guide below to help you.",
				color: "#4D3B98",
			},
		],
		questions: [
			{
				title: `
              <div>
								<div style="display: flex">
									<div style="background-color: rgb(206, 201, 227); border-radius: 20px; padding: 5px 30px; margin: 10px; flex: 1">Paragraph 1:	<span style="color: rgb(87, 88, 90); font-weight: bold; font-style: italic">How about joining me? I’m looking for …</span></div>
									<div style="background-color: rgb(206, 201, 227); border-radius: 20px; padding: 5px 30px; margin: 10px; flex: 1; height: fit-content">Paragraph 2:	<span style="color: rgb(87, 88, 90); font-weight: bold; font-style: italic; display: inline-block; height: 10px !important">My name’s … </span></div>
									<div style="background-color: rgb(206, 201, 227); border-radius: 20px; padding: 5px 30px; margin: 10px; flex: 1; height: fit-content">Paragraph 3:	<span style="color: rgb(87, 88, 90); font-weight: bold; font-style: italic; display: inline-block; height: 10px !important">I love …</span></div>
								</div>
								<div><textarea id="2" rows="11"></textarea></div>
              </div>
          `,
				answer: [],
			},
		],
	},

	9: {
		unit: "Cumulative Review",
		id: "WB7-CR-P56-E9",
		component: T6,
		questionImage: [],
		inputSize: 125,
		exerciseKey: "img/FriendsPlus/Page56/E5/Key/answerKey.png",
		textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
		hideBtnFooter: true,

		internetSearch: {
			content: `
				<b> Did you know ...</b><br />
        <i>The first vlog, or video blog, appeared on the
          internet in 2000.</i>
        <br />
        Find Zoella’s real name and what she vlogs about.
			`
		},
		stylesTextInput: { marginInline: '10px', fontSize: 24 },
		questions: [
			{
				title: `
              <div>
                <internetsearch></internetsearch>
              </div>
          `,
				answer: [],
			},
		],
	},
}

export default json;