import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {

	1: {
		unit: "Unit 2",
		id: "WB7-U2-P16-E1",
		audio: "",
		video: "",
		component: T6,
		questionImage: [],
		inputSize: 280,
		exerciseKey: "img/FriendsPlus/Page16/E1/Key/answerKey.png",
		maxLength: 1,
		textAlign: "center",
		hintBox: [{
			src: [
				"dial a number",
				"download a ringtone",
				"hang up ",
				"<s>leave a voicemail</s> ",
				"put on speakerphone",
				"send a text message",
			],
			borderColor: "#F58024",
			width: "60%"
		}],
		titleQuestion: [
			{
				num: "1",
				title:
					" Label the pictures with the words.",
				color: "#F58024",
				star: 1
			},
		],
		questions: [
			{
				title: `
				<hintbox id='0'></hintbox>
				<div style="font-size: 22px">
				  <tr>
					<td style="padding: 20px ">
					  <img src='img/FriendsPlus/Page16/E1/1.jpg'/>
					  <div style="text-align: center"> <u>leave a voicemail </u></div>
					</td>
					<td style="padding: 20px ">
					  <img src='img/FriendsPlus/Page16/E1/2.jpg'/>
					  <div ><b>1</b> # </div>
					</td>
				  </tr>
				  <tr>
					<td style="padding: 20px ">
					  <img src='img/FriendsPlus/Page16/E1/3.jpg'/>
					  <div ><b>2</b> # </div>
					</td>
					<td style="padding: 20px ">
					  <img src='img/FriendsPlus/Page16/E1/4.jpg'/>
					  <div ><b>3</b> # </div>
					</td>
				  </tr>
				  <tr>
					<td style="padding: 20px ">
					  <img src='img/FriendsPlus/Page16/E1/5.jpg'/>
					  <div ><b>4</b> #</div>
					</td>
					<td style="padding: 20px ">
					  <img src='img/FriendsPlus/Page16/E1/6.jpg'/>
					  <div ><b>5</b> #</div>
					</td>
				  </tr>
				</div>
			  `,
				answer: [
					"send a text message",
					"put on speakerphone",
					"download a ring tone",
					"dial a number",
					"hang up",
				],
			},
		],
	},
	2: {
		unit: 'Unit 2',
		id: 'WB7-U2-P16-E2',
		component: T6,
		inputSize: '220px',
		exerciseKey: 'img/FriendsPlus/Page16/E2/Key/answerKey.png',

		maxLength: 13,
		hintBox: [{
			src: [
				"call back",
				"credit",
				"engaged",
				"hang up",
				"speak up",
				"<s>text message</s>",
				"wrong number",
			],
			borderColor: "#F58024",
			width: "74%"
		}],
		titleQuestion: [
			{
				num: "2",
				title:
					"Read the clues and write the words.",
				color: "#F58024",
				star: 2
			},
		],
		questions: [
			{
				title: `
			<div>
			  <div style="text-align: center">
				<div style="display: flex; justify-content: center">
				  <hintbox id="0"></hintbox>
				</div>
			  </div>
			  <div style="font-size: 25px">
				<div>If you send a few words on your phone, you send a <strong style="color: grey;">text message.</strong></div>
				<div><b>1</b>.If you can’t hear somebody, you say ‘Please # '</div>
				<div><b>2</b>. When you end a phone call, you #</div>
				<div><b>3</b>. If a phone is busy, it is # </div>
				<div><b>4</b>. f you don’t put any money on your phone, you haven’t got any #</div>
				<div><b>5</b>. If you phone somebody again, you #</div>
				<div><b>6</b>. If you call a number and it isn’t correct, you have the #</div>
				</div>
			  </d>
			</div>`,
				answer: ["speak up ", "hang up ", "engaged", "credit", " call back", "wrong number",]
			}
		]
	},
	3: {
		unit: "Unit 2",
		id: "WB7-U2-P16-E3",
		component: T6,

		inputSize: 120,
		audio: "Audios/1-02 TA 7 Friends Plus Workbook.mp3",
		exerciseKey: "img/FriendsPlus/Page16/E3/Key/answerKey.png",
		maxLength: 5,

		textAlign: 'center',
		titleQuestion: [
			{
				num: "3",
				title:
					"<audioimage name='02'></audioimage>Listen to the phone conversations and write true or false.",
				color: "#F58024",
				star: 2
			},
		],
		questions: [
			{
				title: `
			  <div style="display: flex">
				<div>
				  <div>Sam and his uncle are going to the cinema.<b style="color: grey"><i> false</i></b></div>
				  <div><b>1</b> Sam and his uncle are going to the cinema <input id='0' /></div>
				  <div><b>2</b> Sam wants his dad to speak up.  <input id='1' /></div>
				  <div><b>3</b> Sam wants to call back.  <input id='2' /></div>
				  <div><b>4</b> Sam calls back on a landline.  <input id='3' /></div>
				  <div><b>5</b> There’s a problem because Sam’s money is at home. <input id='4' /></div>
				  <div><b>6</b> Dad can come into town and meet Sam. <input id='5' /></div>
				</div>
			  </div>
			`,
				answer: [
					"false",
					"true",
					"true",
					"false",
					"false",
					"true",
				],
			},
		],
	},
	4: {
		unit: "Unit 2",
		id: "WB7-U2-P16-E4",
		component: T6,
		questionImage: [],
		audio: "Audios/1-02 TA 7 Friends Plus Workbook.mp3",
		exerciseKey: 'img/FriendsPlus/Page16/E4/Key/answerKey.png',
		stylesTextInput: { borderBottom: "dotted", textAlign: 'center' },
		inputSize: 200,
		titleQuestion: [
			{
				num: "4",
				title:
					"<audioimage name='02'></audioimage> Listen again and complete the sentences with one or two words.",
				color: "#F58024",
				star: 2
			},
		],
		questions: [
			{
				title: `
            <div>
              <div>There’s a music <b>concert</b>  the afternoon.</div>
              <div><b>1</b> There’s a # message on Sam’s phone from his dad.</div>
              <div><b>2</b> Sam will # his dad back in a minute.</div>
              <div><b>3</b> Sam hasn’t got any # on his phone.</div>
              <div><b>4</b> The tickets are on the  # at home.</div>
              <div><b>5</b> There are # tickets.</div>
              <div><b>6</b> Sam wants to meet his dad outside the #.</div>
            </div>
        `,
				answer: ["voicemail", "call", " credit", " kitchen table ", "two", "station"]
			},
		],
	},
	5: {
		unit: "Unit 2",
		id: "WB7-U2-P16-E5",
		audio: "",
		video: "",
		component: T6,
		questionImage: [],
		textareaStyle: { width: 700, resize: "none", borderBottom: "none" },
		hideBtnFooter: true,
		titleQuestion: [
			{
				num: "5",
				title:
					"What do you do in these situations?",
				color: "#F58024",
				star: 3
			},
		],
		questions: [
			{
				title: `
            <div>
              <div>You call somebody and you want everybody to hear the conversation.<u style="text-decoration: dotted underline; display: inline-block">&emsp;<i><b>I put my phone on speakerphone.</b></i>&emsp;</u></div>
              <div style="display: flex;"><b style="padding-top: 6px;">1</b><textarea id="0" rows="2" >You don’t have any credit.  </textarea></div>
              <div style="display: flex;margin-top: -30px"><b style="padding-top: 6px;">2</b><textarea id="1" rows="2" >Your mum’s phone is engaged.  </textarea></div>
              <div style="display: flex;margin-top: -30px"><b style="padding-top: 6px;">3</b><textarea id="2" rows="3" >You want to communicate with your friends. They’re outside, but you’re in the library. </textarea></div>
              <div style="display: flex;margin-top: -30px"><b style="padding-top: 6px;">4</b><textarea id="3" rows="2" >The telephone conversation is finished. </textarea></div>
              <div style="display: flex;margin-top: -30px"><b style="padding-top: 6px;">5</b><textarea id="4" rows="2" >You’re bored with the ringtone on your phone. </textarea></div>
              <div style="display: flex;margin-top: -30px"><b style="padding-top: 6px;">6</b><textarea id="5" rows="2" >You phone a number and it isn’t correct.</textarea></div>
            </div>
        `,
				answer: [],
			},
		],
	},
}

export default json;