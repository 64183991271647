import MC2 from '../../components/ExcerciseTypes/MultipleChoice/MC2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P68-E1",
    component: T6,
    questionImage: [],
    inputSize: 300,
    exerciseKey: "img/FriendsPlus/Page68/E1/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'center' },
    isHiddenCheck: true,
    selectStyle: { color: '#00ADFE', width: 66, textAlign: 'center', fontSize: 17, marginInline: '20px' },
    selectOptionRandom: true,
    selectInputSize: 'middle', // large, middle(default), small
    selectOptionValues: [
      "to",
      "∅",
    ],
    titleQuestion: [
      {
        num: "1",
        title:
          "Write to or ∅ in the blanks.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"> want <select id='0'></select>do something</div></div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"> need <select id='1'></select>do something</div></div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"> can <select id='2'></select>do something</div></div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"> should <select id='3'></select>do something</div></div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"> would like <select id='4'></select>do something</div></div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"> may <select id='5'></select>do something</div></div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>7. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"> ought <select id='6'></select>do something</div></div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>8. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"> used <select id='7'></select>do something</div></div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>9. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"> have <select id='8'></select>do something</div></div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>10. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"> must <select id='9'></select>do something</div></div></div>
              </div>
          `,
        answer: [
          "to",
          "to",
          "∅",
          "∅",
          "to",
          "∅",
          "to",
          "to",
          "to",
          "∅",
        ]
      },
    ],
  },

  2: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P68-E2",
    component: T6,
    questionImage: [],
    inputSize: 120,
    exerciseKey: "img/FriendsPlus/Page68/E2/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'center' },
    hintBox: [{
      src: [
        "swim",
        "run",
        "eat",
        "study",
        "play",
        "read",
        "<s>drink</s>",
      ],
      width: "700px",
      borderColor: "#4D3B98",
    }],
    titleQuestion: [
      {
        num: "2",
        title:
          "Complete the sentences using the affirmative or negative form of the verbs.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div style="width: 1000px; display: flex; justify-content: center; margin-block: 10px"><hintBox id='0'></hintbox></div>
                <div>
                  <div>You should <u style="text-decoration: dotted underline;color: grey">&emsp;<b>not drink</b>&emsp;</u>so much Coke because it’s not good for your health.</div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> It’s cold today. You should #.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> You can # many vegetables as they are good for your health.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> You must # soccer in this restricted area.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> You have # hard so as to get high grades.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> You ought # these fairy tales to build your imagination.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> You can # faster if you have lighter shoes.</div></div>
              </div>
          `,
        answer: [
          "not swim",
          "eat",
          "not play",
          "to study",
          "to read",
          "run",
        ],
      },
    ],
  },
  3: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P68-E3",
    component: T6,
    questionImage: [],
    inputSize: 900,
    exerciseKey: "img/FriendsPlus/Page68/E3/Key/answerKey.png",
    stylesTextInput: { marginInline: '1px', fontSize: 22, borderBottom: "0.5px dotted black" },

    titleQuestion: [
      {
        num: "3",
        title:
          "Order the words to make sentences.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div style="position: relative">
                  <div><img w src='img/FriendsPlus/Page68/E3/1.jpg' /></div>
                  <div style="position: absolute; left: 50px; top: 10px">
                    <div style="margin-block: 25px"><b>1 &emsp;</b>or we / stop using fossil fuels / we should / to breathe / will have no fresh air </div>
                    <div style="display: flex; justify-content: start"> &emsp;&emsp; # </div>
                    <div style="margin-block: 25px"><b>2 &emsp;</b>we ought / using coal / pollutes the air / to stop / because it </div>
                    <div style="display: flex; justify-content: start"> &emsp;&emsp; # </div>
                    <div style="margin-block: 25px"><b>3 &emsp;</b> an effective source / but / nuclear power is / very dangerous / of energy / it is </div>
                    <div style="display: flex; justify-content: start"> &emsp;&emsp; # </div>
                    <div style="margin-block: 25px"><b>4 &emsp;</b> look for / we will have / we should / alternative sources of energy / and / a better environment </div>
                    <div style="display: flex; justify-content: start"> &emsp;&emsp; # </div>
                    <div style="margin-block: 25px"><b>5 &emsp;</b> an effective source / although / nuclear power is / very dangerous / of energy / it is</div>
                    <div style="display: flex; justify-content: start"> &emsp;&emsp; # </div>
                  </div>
                </div>
          `,
        answer: [
          " We should stop using fossil fuels or we will have no fresh air to breathe.",
          " We ought to stop using coal because it pollutes the air.",
          " Nuclear power is an effective source of energy but it is very dangerous.",
          " We should look for alternative sources of energy and we will have a better environment.",
          "Although nuclear power is an effective source of energy, it is very dangerous.",
        ],
      },
    ],
  },
  4: { // Exercise num
    unit: "Language Focus Practice",
    id: "WB7-LFS-P68-E4",
    component: MC2,
    exerciseKey: 'img/FriendsPlus/Page68/E4/Key/answerKey.png',
    fontSize: 22,
    titleQuestion: [
      {
        num: '4',
        title: "Choose the best answer for each sentence.",
        color: "#4D3B98",
      }
    ],
    questions: [
      {
        answers: [
          { image: "", isCorrect: false, right: "", text: "A lot" },
          { image: "", isCorrect: true, right: "", text: "Some" },
          { image: "", isCorrect: false, right: "", text: "Any" },
        ],
        title: "# students aren't sporty enough to enjoy the game."
      },
      {
        answers: [
          { image: "", isCorrect: true, right: "", text: "any" },
          { image: "", isCorrect: false, right: "", text: "many" },
          { image: "", isCorrect: false, right: "", text: "some" },
        ],
        title: "There isn't # food left in the fridge."
      },
      {
        answers: [
          { image: "", isCorrect: false, right: "", text: "many" },
          { image: "", isCorrect: false, right: "", text: "lot of" },
          { image: "", isCorrect: true, right: "", text: "much" },
        ],
        title: "He works hard, but he doesn't earn # money."
      },
      {
        answers: [
          { image: "", isCorrect: false, right: "", text: "any" },
          { image: "", isCorrect: true, right: "", text: "some" },
          { image: "", isCorrect: false, right: "", text: "many" },
        ],
        title: "‘Would you like # yogurt for dessert?’ ‘Yes, please.’"
      },
      {
        answers: [
          { image: "", isCorrect: true, right: "", text: "Many" },
          { image: "", isCorrect: false, right: "", text: "Much" },
          { image: "", isCorrect: false, right: "", text: "Any" },
        ],
        title: " # things have changed since last decade."
      },
      {
        answers: [
          { image: "", isCorrect: false, right: "", text: "many" },
          { image: "", isCorrect: false, right: "", text: "much" },
          { image: "", isCorrect: true, right: "", text: "a lot" },
        ],
        title: "Dad buys me # of toys whenever I get good results."
      },
      {
        answers: [
          { image: "", isCorrect: false, right: "", text: "lots" },
          { image: "", isCorrect: false, right: "", text: "many" },
          { image: "", isCorrect: true, right: "", text: "much" },
        ],
        title: "We don't have # time. We must hurry."
      },
      {
        answers: [
          { image: "", isCorrect: true, right: "", text: "any" },
          { image: "", isCorrect: false, right: "", text: "a lot " },
          { image: "", isCorrect: false, right: "", text: "lots of" },
        ],
        title: "‘Are there cafés near here?’ ‘No, there aren't.’"
      },
    ]
  },

}

export default json;