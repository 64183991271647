import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {

  1: {
    unit: "Unit 8",
    id: "WB7-U8-P50-E1",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page50/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: "Match 1–6 with a–f..",
        color: "#0167B4",
        star: 1
      },
    ],
    // titleQuestion: [{ num: '2', title: "Listen again and draw the route to Leo's school.", color: "#2E479B" }],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          { boxMatchStyle: { position: "absolute", top: 20, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //0
          { boxMatchStyle: { position: "absolute", top: 20, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //0
          { boxMatchStyle: { position: "absolute", top: 60, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //1
          { boxMatchStyle: { position: "absolute", top: 60, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //2
          { boxMatchStyle: { position: "absolute", top: 100, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //3
          { boxMatchStyle: { position: "absolute", top: 100, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //5
          { boxMatchStyle: { position: "absolute", top: 140, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //4
          { boxMatchStyle: { position: "absolute", top: 140, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //7
          { boxMatchStyle: { position: "absolute", top: 175, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //8
          { boxMatchStyle: { position: "absolute", top: 175, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //9
          { boxMatchStyle: { position: "absolute", top: 215, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //10
          { boxMatchStyle: { position: "absolute", top: 215, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //11
          { boxMatchStyle: { position: "absolute", top: 260, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //8
          { boxMatchStyle: { position: "absolute", top: 260, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //9
          { boxMatchStyle: { position: "absolute", top: 300, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //10
          { boxMatchStyle: { position: "absolute", top: 300, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //11
        ],
        answers: ['0-3', '2-9', '4-7', '11-6', '1-8', '10-5'],
        initialValue: ["0-3"],
      },
      Layout: `
        <div style="font-size: 26px;">
          <div style='display: flex;'>
            <div style='width: 200px;'><b>1</b> economical</div>
						<div><input id='0' type= 'boxMatch' /></div>
            <div><input id='1' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>a.</b> roomy</div>
          </div>

					<div style='display: flex;'>
            <div style='width: 200px;'><b>2</b> wonderful</div>
						<div><input id='2' type= 'boxMatch' /></div>
            <div><input id='3' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>b.</b> cheap</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 200px;'><b>3</b> fast</div>
						<div><input id='4' type= 'boxMatch' /></div>
            <div><input id='5' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>c.</b> driverless</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 200px;'><b>4</b> convenient</div>
						<div><input id='6' type= 'boxMatch' /></div>
            <div><input id='7' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>d.</b> firmly</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 200px;'><b>5</b> spacious</div>
						<div><input id='8' type= 'boxMatch' /></div>
            <div><input id='9' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>e.</b> enjoyable</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 200px;'><b>6</b> self-driving</div>
						<div><input id='10' type= 'boxMatch' /></div>
            <div><input id='11' type= 'boxMatch' /></div>
						<div style='margin-left: 120px; margin-top: 4px;'><b>f.</b> useful</div>
          </div>
        </div>
      `,
    },
  },

  2: {
    unit: "Unit 8",
    id: "WB7-U8-P50-E2",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page50/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "Choose the correct words",
        color: "#0167B4",
        star: 2
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          "of / on / in",
          "as / like / to",
          "on / at / in",
          "at / in / on ",
          "among / between / during",
          "without / among / about ",
        ],
        answers: ['0-0', '1-4', '2-8', '3-8', '4-8', '5-0'],
        initialValue: [],
      },
      Layout: `
              <div>
                <div><b>1.</b> People always dream <b><input id='0' type='Circle' /></b> flying.</div>
                <div><b>2.</b> They want to fly <b><input id='1' type='Circle' /></b> a bird.</div>
                <div><b>3.</b> Flying helps you stay <b><input id='2' type='Circle' /></b> the air.</div>
                <div><b>4.</b> Nowadays, some flying objects can also run <b><input id='3' type='Circle' /></b> water and land.</div>
                <div><b>5.</b> They can travel quickly <b><input id='4' type='Circle' /></b> rush hours.</div>
                <div><b>6.</b> These objects can even move <b><input id='5' type='Circle' /></b> drivers or pilots.</div>
              </div>
          `,
    },
  },

  3: {
    unit: "Unit 8",
    id: "WB7-U8-P50-E3",
    component: T6,
    questionImage: [],
    inputSize: 100,
    exerciseKey: "img/FriendsPlus/Page50/E3/Key/answerKey.png",

    stylesTextInput: { marginInline: '10px', fontSize: 24, textAlign: 'center' },
    titleQuestion: [
      {
        num: "3",
        title:
          "Complete the sentences with the words given.",
        color: "#0167B4",
        star: 2
      },
    ],
    hintBox: [{
      src: [
        "lift",
        "travel",
        "drive",
        "avoid",
        "run ",
        "move",
      ],
      width: "558px",
      borderColor: "#0167B4",
    }],
    questions: [
      {
        title: `
              <div>
                <hintbox id="0"></hintbox>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> Those flying cars save money because they # on solar energy.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> A jetpack can # you off the ground.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> Flying motorbikes help policemen # faster and avoid traffic jams.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> You can see buses that # on land and water on the Sài Gòn River.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> They hope that tunnel buses can # traffic jams.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> People unable to # can choose to buy self-driving cars.</div></div>
              </div>
          `,
        answer: [
          "run",
          "lift",
          "travel",
          "move",
          "avoid",
          "drive",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "WB7-U8-P50-E4",
    component: T6,
    questionImage: [],
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page50/E4/Key/answerKey.png",
    stylesTextInput: { marginInline: '10px', fontSize: 24, textAlign: 'center' },
    titleQuestion: [
      {
        num: "4",
        title:
          "Complete the text with the words. ",
        color: "#0167B4",
        star: 3
      },
    ],
    hintBox: [{
      src: [
        "turned",
        "in",
        "than",
        "speed",
        "between",
        "engine",
      ],
      width: "558px",
      borderColor: "#0167B4",
    }],
    questions: [
      {
        title: `
              <div>
                <div style="margin-left: 150px";><hintbox id="0"></hintbox></div>
                <div style="border: 8px solid rgb(173, 192, 226); width: 900px; padding: 40px 20px 80px 20px; margin: 20px; box-shadow: 5px 5px 5px grey ">
                  People in Slovakia were surprised to 
                  see a flying car in the sky on Monday 
                  28 June, 2021. <sup>1</sup> # a press
                  release on Wednesday, it flew for 35 
                  minutes and completed a test flight 
                  <sup>2</sup> # two cities, from Nitra to 
                  Bratislava. The aircraft <sup>3</sup> # into 
                  a car in less <sup>4</sup> # three minutes 
                  and continued to run on the streets of 
                  Bratislava after it landed successfully. 
                  They equip the flying car, the AirCar 
                  Prototype 1, with a 160 horsepower BMW 
                  <sup>5</sup> # . A fixed propeller helps it 
                  lift up and fly. The maximum 
                  <sup>6</sup> # of the AirCar Prototype 1 
                  is 190 kilometres per hour (118 miles per 
                  hour). After the successful test flight, they 
                  hope to sell the first AirCar Prototype 1 at 
                  the beginning of 2022.
                </div>
              </div>
          `,
        answer: [
          "In",
          "between",
          "turned",
          "than",
          "engine",
          "speed",
        ],
      },
    ],
  },
}

export default json;
