import T6 from '../../components/ExcerciseTypes/TypeIn/T6';



const json = {

  1: {
    unit: "Unit 8",
    id: "WB7-U8-P51-E1",
    component: T6,
    questionImage: [],
    inputSize: 120,
    exerciseKey: "img/FriendsPlus/Page51/E1/Key/answerKey.png",
    stylesTextInput: { marginInline: '10px', fontSize: 24, textAlign: 'center' },
    titleQuestion: [
      {
        num: "1",
        title:
          " Complete the table with the words.",
        color: "rgb(117, 207, 225)",
        star: 1
      },
    ],
    hintBox: [{
      src: [
        "because",
        "and",
        "but",
        "or",
        "so",
        "although",
      ],
      width: "558px",
      borderColor: "rgb(117, 207, 225)",
    }],
    questions: [
      {
        title: `
              <div>
                <div style="display: flex; justify-content: center; margin: 10px"><hintbox id="0"></hintbox></div>
                <div style="border: 1px solid rgb(117, 207, 225); border-radius: 20px; overflow: hidden;">
                  <tr style="background-color: rgb(117, 207, 225); font-weight: bold; text-align: center">
                    <td style="padding-inline: 10px">Giving addition</td>
                    <td style="padding-inline: 10px">Giving difference</td>
                    <td style="padding-inline: 10px">Giving selection</td>
                    <td style="padding-inline: 10px">Giving result</td>
                    <td style="padding-inline: 10px">Giving reason</td>
                    <td style="padding-inline: 10px">Giving contrast</td>
                  </tr>
                    <td style="border: 1px solid rgb(117, 207, 225); text-align: center">#</td>
                    <td style="border: 1px solid rgb(117, 207, 225); text-align: center">#</td>
                    <td style="border: 1px solid rgb(117, 207, 225); text-align: center">#</td>
                    <td style="border: 1px solid rgb(117, 207, 225); text-align: center">#</td>
                    <td style="border: 1px solid rgb(117, 207, 225); text-align: center">#</td>
                    <td style="border: 1px solid rgb(117, 207, 225); text-align: center">#</td>
                </div>
              </div>
          `,
        answer: [
          "and",
          "but",
          "or",
          "so",
          "because",
          "although",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "WB7-U8-P51-E2",
    component: T6,
    questionImage: [],
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page51/E2/Key/answerKey.png",
    stylesTextInput: { marginInline: '10px', fontSize: 24, textAlign: 'center' },
    titleQuestion: [
      {
        num: "2",
        title:
          "Complete these sentences with the words given in exercise 1.",
        color: "rgb(117, 207, 225)",
        star: 2
      },
    ],
    hintBox: [{
      src: [
        "because",
        "and",
        "but",
        "or",
        "so",
        "although",
      ],
      width: "558px",
      borderColor: "rgb(117, 207, 225)",
    }],
    questions: [
      {
        title: `
                <div style="display: flex; justify-content: center; margin: 10px"><hintbox id="0"></hintbox></div>
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> Amphibious buses can run on water # land.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> Fossil fuels help run machines, # they cause pollution.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> # solar energy is unlimited, not many people can cover its expense.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> People should limit hydropower plants # they sometimes cause terrible floods.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> This seaside is full of sunlight, # they will establish a solar power plant here.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> Either solar energy # wind power is useful and helps protect the environment.</div></div>
              </div>
          `,
        answer: [
          "and",
          "but",
          "Although",
          "because",
          "so",
          "or",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "WB7-U8-P51-E3",
    component: T6,
    questionImage: [],
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page51/E3/Key/answerKey.png",

    stylesTextInput: { marginInline: '10px', fontSize: 24, textAlign: 'center' },
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the text and fill in each blank with the most suitable conjunction.",
        color: "rgb(117, 207, 225)",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div style="background: linear-gradient(0.25turn, rgb(216, 240, 246), white, rgb(216, 240, 246)); padding: 40px">
                  People always try to improve their standard of living, <span style="display: inline-block"><sup>1</sup> #</span> they need a lot of fuels. 
                  <span style="display: inline-block"><sup>2</sup> #</span> a present day estimate by National Geographic says that we use 320 billion 
                  kilowatt-hours of energy every day, the need for energy does not seem to stop ending. Fossil fuels 
                  bring us a lot of energy, <span style="display: inline-block"><sup>3</sup> #</span> they cause greenhouse gas emissions <span style="display: inline-block"><sup>4</sup> #</span>
                  pollution. The time comes to find suitable and better replacements for fossil fuels. Solar energy, 
                  energy from wind <span style="display: inline-block"><sup>5</sup> #</span> bio fuels appears to be a promising source <span style="display: inline-block"><sup>6</sup> #</span>
                  they can bring us a cleaner and greener future.

                </div>
              </div>
          `,
        answer: [
          "so",
          "Although",
          "but",
          "and",
          "or",
          "because",
        ],
      },
    ],
  },

  4: {
    unit: "Unit 8",
    id: "WB7-U8-P51-E4",
    component: T6,
    questionImage: [],
    inputSize: 1000,
    exerciseKey: "img/FriendsPlus/Page51/E4/Key/answerKey.png",

    stylesTextInput: { marginInline: '10px', fontSize: 24 },
    titleQuestion: [
      {
        num: "4",
        title:
          "Use the most suitable conjunctions to make sentences.",
        color: "rgb(117, 207, 225)",
        star: 3
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> use / green sources / energy / you / protect / earth</br>#</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> limit / use / fossil fuels / our future generations / lack energy</br>#</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> green sources / energy / good / we need more time / make / perfect (2 ways)</br>#</br>#</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; ">  Việt Nam / a long seaside / suitable / develop / solar energy and wind power (2 ways)</br>#</br>#</div></div>
              </div>
          `,
        answer: [
          "You should use green sources of energy because you can protect the earth.",
          "We should limit the use of fossil fuels or our future generations will lack energy.",
          "Although green sources of energy are good, we need more time to make them perfect./Green sources of energy are good but we need more time to make them perfect.",
          "Although green sources of energy are good, we need more time to make them perfect./Green sources of energy are good but we need more time to make them perfect.",
          "Viet Nam has a long seaside so it is suitable to develop solar energy and wind power./Because Viet Nam has a long seaside, it is suitable to develop solar energy and win power.",
          "Viet Nam has a long seaside so it is suitable to develop solar energy and wind power./Because Viet Nam has a long seaside, it is suitable to develop solar energy and win power.",
        ],
      },
    ],
  },


}
export default json;
