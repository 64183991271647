import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: {
    unit: "Unit 4",
    id: "WB7-U4-P31-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page31/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Choose the correct words",
        color: "#00a23b",
        star: 1
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          "as_soon_as / when", //0
          "while / after", //1
          "While / When ", //0
          "when / while", //1
          "as_soon_as / while ", //0
          " as_soon_as / after_a_few_minutes  ", //1
        ],
        answers: ['0-0', '1-4', '2-0', '3-0', '4-0', '5-6'],
        initialValue: [],
      },
      Layout: `
              <div>
                <div>I was watching TV <b style="border: solid 2px #000000; border-radius: 50%; padding: 5px; ">when</b> / <b>while</b> I saw the news about the rescue.<div>
                <div><b>1.</b> There’s a terrible accident! Call the police <b><input id='0' type='Circle' /></b> you can.</div>
                <div><b>2.</b> The lion stood and looked at me, but <b><input id='1' type='Circle' /></b> a few minutes it walked away</div>
                <div><b>3.</b> <b><input id='2' type='Circle' /></b> we were walking in the forest, a monkey jumped down in front of us.</div>
                <div><b>4.</b>  They were swimming in the river <b><input id='3' type='Circle' /></b> they saw a big fish.</div>
                <div><b>5.</b> I took a photograph of the beautiful bird <b><input id='4' type='Circle' /></b> e I saw it</div>
                <div><b>6.</b> We sat down on the rock and then <b><input id='5' type='Circle' /></b> we had our lunch.</div>
              </div>
          `,
    },
  },
  2: {
    unit: "Unit 4",
    id: "WB7-U4-P31-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 23 },
    hintBox: [{
      src: [
        "a few minutes",
        "After",
        "soon as",
        "when ",
        "when",
        "<s>while</s>",
        "While",
      ],
      borderColor: "#00a23b",
      width: "100%"
    }],
    inputSize: 175,
    exerciseKey: "img/FriendsPlus/Page31/E2/Key/answerKey.png",
    textAlign: 'center',

    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "2",
        title:
          " Fill in the blank with one word in the box.",
        color: "#00a23b",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div style="display: flex; justify-content: center"><hintbox id="0"></hintbox></div>
                <div style="text-align: center; margin-block: 20px"><img style="width: 40%" src="img/FriendsPlus/Page31/E2/1.jpg"/></div>
                <div style="font-size: 24px">
                  I went to Canada with my family and we hired a 
                  car <u style="text-decoration: dotted underline;">&emsp;<b>while</b>&emsp;</u> we were staying there. <sup>1</sup> # a few days, we visited a forest. 
                  We were driving slowly through the forest 
                  <sup>2</sup> # we saw a bear asleep in a 
                  tree. We stopped our car, opened the windows 
                  and started to take a video of the bear. But 
                  after <sup>3</sup> # , it opened its eyes. As <sup>4</sup> # it saw us, it jumped down and 
                  it started to move nearer. <sup>5</sup> # it 
                  was coming towards us, we quickly closed the 
                  windows and drove away! I told my friends about 
                  the bear <sup>6</sup> # I arrived home.
                </div>
              </div>
          `,
        answer: ["After", "when", "a few minutes", "soon as", "While", "when"],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "WB7-U4-P31-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20, textAlign: 'center' },
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page31/E3/Key/answerKey.png",
    maxLength: 16,

    textAlign: "center",
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "3",
        title:
          " Read the notes and complete the text.",
        color: "#00a23b",
        star: 2
      },
    ],
    questions: [
      {
        title: `
          <div style="position: relative; margin: 10px">
            <div><img src='img/FriendsPlus/Page31/E3/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 749px; left: 200px; "><input id='0' /></div>
              <div style=" position: absolute; top: 749px; left: 303px; "><input id='1' /></div>
              <div style=" position: absolute; top: 749px; left: 404px; "><input id='2' /></div>
              <div style=" position: absolute; top: 775px; left: -18px; "><input id='3' /></div>
              <div style=" position: absolute; top: 775px; left: 80px; "><input id='4' /></div>
              <div style=" position: absolute; top: 775px; left: 170px; "><input id='5' /></div>
              <div style=" position: absolute; top: 775px; left: 276px; "><input id='6' /></div>
              <div style=" position: absolute; top: 827px; left: 428px; "><input id='7' /></div>
              <div style=" position: absolute; top: 853px; left: -18px; "><input id='8' /></div>
              <div style=" position: absolute; top: 853px; left: 80px; "><input id='9' /></div>
              <div style=" position: absolute; top: 853px; left: 170px; "><input id='10' /></div>
              <div style=" position: absolute; top: 853px; left: 276px; "><input id='11' /></div>
              <div style=" position: absolute; top: 853px; left: 369px; "><input id='12' /></div>
              <div style=" position: absolute; top: 879px; left: -18px; "><input id='13' /></div>
              <div style=" position: absolute; top: 879px; left: 80px; "><input id='14' /></div>
              <div style=" position: absolute; top: 943px; left: -12px; "><input id='15' /></div>
              <div style=" position: absolute; top: 943px; left:  85px; "><input id='16' /></div>
              <div style=" position: absolute; top: 943px; left: 185px; "><input id='17' /></div>
              <div style=" position: absolute; top: 995px; left: 174px; "><input id='18' /></div>
              <div style=" position: absolute; top: 995px; left: 279px; "><input id='19' /></div>
              <div style=" position: absolute; top: 995px; left: 381px; "><input id='20' /></div>
              <div style=" position: absolute; top: 1021px; left: -19px; "><input id='21' /></div>
              <div style=" position: absolute; top: 1021px; left: 371px; "><input id='22' /></div>
              <div style=" position: absolute; top: 1047px; left: -19px; "><input id='23' /></div>
              <div style=" position: absolute; top: 1047px; left: 83px; "><input id='24' /></div>
              <div style=" position: absolute; top: 1047px; left: 172px; "><input id='25' /></div>
              <div style=" position: absolute; top: 1047px; left: 277px; "><input id='26' /></div>
              <div style=" position: absolute; top: 1047px; left: 369px; "><input id='27' /></div>
              <div style=" position: absolute; top: 1125px; left: 86px; "><input id='28' /></div>
              <div style=" position: absolute; top: 1125px; left: 176px; "><input id='29' /></div>
              <div style=" position: absolute; top: 1125px; left: 281px; "><input id='30' /></div>
              <div style=" position: absolute; top: 1125px; left: 388px; "><input id='31' /></div>
              <div style=" position: absolute; top: 1151px; left: -19px; "><input id='32' /></div>
              <div style=" position: absolute; top: 1151px; left: 82px; "><input id='33' /></div>

            </div>
          </div>
        `,
        answer: [
          "It", "happened", "while", "our", "team", "was", "playing",
          "my", "best", "friend", "Max", "was", "the", "team", "captain",
          "I", "was", "standing",
          "I", "took", "the", "photo",
          "while", "he", "was", "kicking", "the", "ball",
          "He", "was", "really", "pleased", "with", "it"
        ]
      }
    ],
  },
  4: {
    unit: "Unit 4",
    id: "WB7-U4-P31-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1000, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          " Imagine you took a photo of an amazing moment at your school or in your town.Write an account of it.It could be at a sports, music or dance event.Use the text in exercise 3 to help you.",
        color: "#00a23b",
        star: 3
      },
    ],
    questions: [
      {
        title: `
          <div><textarea id="0" rows="11"></textarea></div>
        `,
        answer: [],
      },
    ],
  },
}

export default json;