import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P63-E1",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 24 },
    inputSize: 575,
    exerciseKey: "img/FriendsPlus/Page63/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Write sentences using the affirmative (✔), negative(✘) or question(?) form of be.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div>They were scared in the big castle. ✘<br /><u style="text-decoration: dotted underline; color: grey"><i><b>They weren't scared in the big castle. </b></i>&emsp;</u></div>
              <div><b>1 &emsp;</b> The tour was expensive. ?<br /> &emsp; #</div>
              <div><b>2 &emsp;</b> My answers weren’t right. ✔<br /> &emsp; #</div>
              <div><b>3 &emsp;</b> I was cold in the tunnel. ✘<br /> &emsp; #</div>
              <div><b>4 &emsp;</b> The tourists were interested in the old town. ?<br /> &emsp; #</div>
              <div><b>5 &emsp;</b> The visit to the museum wasn’t exciting. ✔<br /> &emsp; #</div>
              <div><b>6 &emsp;</b> You were on the school trip last week. ✘<br /> &emsp; #</div>
              <div><b>7 &emsp;</b> Liam was at school yesterday. ?<br /> &emsp; #</div>
              <div><b>8 &emsp;</b> We weren’t happy with our guide. ✔<br /> &emsp; #</div>
            </div>
        `,
        answer: [
          "Was the tour expensive?",
          "My answers were right.",
          " I wasn't cold in the tunnel",
          "Were the tourists interested in the old town?",
          "The visit to the museum was exciting.",
          "You weren't on the school trip last week.",
          "Was Liam at school yesterday?",
          "We were happy with our guide.",
        ],
      },
    ],
  },

  2: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P63-E2",
    component: T6,
    questionImage: [],
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page63/E2/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'center' },
    titleQuestion: [
      {
        num: "2",
        title:
          "Complete the sentences with the affirmative or negative form of there was and there were.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <div>The town was very busy. <u style="text-decoration: dotted underline;color: grey">&emsp;<b>There were</b>&emsp;</u>  a lot of people in the shops.</div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> The weather on our holiday was warm and dry. #  any rain at all.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; ">  # a shop at the museum and I bought some great things for my family.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> I wasn’t scared in the tunnel because  # any ghosts.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> The science museum was amazing.  # hundreds of different things to see.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; ">  # a new boy in our class last week.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; ">  # any good films on TV yesterday and I went to bed early.</div></div>
              </div>
          `,
        answer: [
          "There wasn't",
          "There was",
          "there weren't  ",
          "There were ",
          "There was",
          "There weren't",
        ],
      },
    ],
  },

  3: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P63-E3",
    component: T6,
    questionImage: [],
    inputSize: 90,
    exerciseKey: "img/FriendsPlus/Page63/E3/Key/answerKey.png",
    stylesTextInput: { marginInline: '1px', fontSize: 18, textAlign: 'center' },

    isHiddenCheck: true,
    hintBox: [{
      src: [
        "explore",
        "feel",
        "give",
        "go",
        "meet",
        "stay ",
        "take",
        "<s>travel</s>",
      ],
      width: "650px",
      borderColor: "#4D3B98",
    }],
    titleQuestion: [
      {
        num: "3",
        title:
          "Complete the email using the past simple form of the verbs.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div style="margin-block: 20px"><hintbox id="0"></hintbox></div>
                <div style="position: relative">
                  <div><img style="width:51%" src='img/FriendsPlus/Page63/E3/1.jpg' /></div>
                  <div style="position: absolute; left: 30px; top: 70px; font-size: 18px; line-height: 130%; word-spacing: 7px">
											Hi Helen,</br>
                      Thanks for your email. Yes, my summer holidays </br>
                      were great! I was in Italy with my older cousins. </br>
                      They live in Rome because my aunt and uncle </br>
                      work there.</br>
                      I <u style="text-decoration: dotted underline; color: grey"><i><b>travelled</b></i>&emsp;</u> to Rome by plane and my </br>
                      aunt <sup>1</sup> # me at the airport. </br>
                      I <sup>2</sup> # at their house for ten days. They </br>
                      were very friendly so I <sup>3</sup> # happy at </br>
                      their home. I can’t speak Italian, but they</br>
                      <sup>4</sup> # me a phrase book.</br>
                      I <sup>5</sup> # Rome with my cousins and we</br>
                      <sup>6</sup> # into the tunnels under the city. </br>
                      It was very scary down there. I <sup>7</sup> # </br>
                      some photos – I can’t wait to show you them!</br>
									</div>
                </div>
          `,
        answer: [
          "met",
          "stayed",
          "felt",
          "gave",
          "explored",
          "went",
          "took",
        ],
      },
    ],
  },

  4: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P63-E4",
    component: T6,
    questionImage: [],
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page63/E4/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'center' },
    titleQuestion: [
      {
        num: "4",
        title:
          "Complete the sentences with the past simple negative form of the verbs.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <div>We went to the old library, but we  <u style="text-decoration: dotted underline;color: grey">&emsp;<b>didn’t go</b>&emsp;</u>  to the castle.</div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> Emrah and Kemal <b>played</b> football on Friday, but they # basketball.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> Holly <b>lost</b> her camera, but she # her phone.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> You <b>met</b> Lily today, but you # Dan.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> I <b>helped</b> with the housework, but I # with the cooking.</div></div>
              </div>
          `,
        answer: [
          "didn't play ",
          "didn't lose ",
          "didn't meet ",
          "didn't help ",
        ],
      },
    ],
  },
  5: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P63-E5",
    component: T6,
    questionImage: [],
    inputSize: 180,
    exerciseKey: "img/FriendsPlus/Page63/E5/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'center' },
    titleQuestion: [
      {
        num: "5",
        title:
          "Write past simple questions and short answers.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <div>‘<u style="text-decoration: dotted underline;color: grey">&emsp;<b>Did you like </b>&emsp;</u>  (you / like) the film?’ ‘Yes,  <u style="text-decoration: dotted underline;color: grey">&emsp;<b>I did .’ </b>&emsp;</u></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> ‘# (David / find) his bag?’  ‘No, # .’</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> ‘# (they / go) to New York?’ ‘Yes, # ’</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> ‘# (Katy / leave) school early?’ ‘Yes, # .’.</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> ‘# (we / have) any homework?’ ‘No, # .’</div></div>
              </div>
          `,
        answer: [
          "Did David find", "he didn't ",
          "Did they go", "they did",
          "Did Katy leave", "she did",
          "Did we have", "we didn't",
        ],
      },
    ],
  },

}

export default json;