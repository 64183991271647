import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: "Unit 7",
    id: "WB7-U7-P45-E1",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: {
      borderBottom: "none", fontSize: 20
    },
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page45/E1/Key/answerKey.png",
    maxLength: 16,

    isHiddenCheck: true,
    textAlign: "center",
    hintBox: [{
      src: [
        "aren't",
        "be",
        "isn't",
        "get",
        "going",
        "'m ",
        "'m not",
        "'re",
        "'s",
      ],
      width: "530px",
      borderColor: "#69d2e4",
    }],
    titleQuestion: [
      {
        num: "1",
        title:
          " Complete the table with the words.",
        color: "#69d2e4",
        star: 1
      },
    ],
    questions: [
      {
        title: `
          <div><hintbox id='0'></hintbox></div>
          <div style="position: relative; margin: 10px">
            <div><img src='img/FriendsPlus/Page45/E1/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 72px; left: 65px; "><input id='0' /></div>
              <div style=" position: absolute; top: 101px; left: 98px; "><input id='1' /></div>
              <div style=" position: absolute; top: 71px; left: 303px; "><input id='2' /></div>
              <div style=" position: absolute; top: 175px; left: -37px; "><input id='3' /></div>
              <div style=" position: absolute; top: 205px; left: 60px; "><input id='4' /></div>
              <div style=" position: absolute; top: 233px; left: 103px; "><input id='5' /></div>
              <div style=" position: absolute; top: 205px; left: 190px; "><input id='6' /></div>
              <div style=" position: absolute; top: 197px; left: 305px; "><input id='7' /></div>
            </div>
          </div>
        `,
        answer: [
          " 's",
          "'re",
          "be",
          "'m not",
          "isn't",
          "aren't",
          "going",
          "get",
        ]
      }
    ],
  },
  2: {
    unit: "Unit 7",
    id: "WB7-U7-P45-E2",
    component: T6,
    questionImage: [],
    inputSize: 230,
    exerciseKey: "img/FriendsPlus/Page45/E2/Key/answerKey.png",

    stylesTextInput: { textAlign: 'center', marginInline: '10px', fontSize: 24 },
    titleQuestion: [
      {
        num: "2",
        title:
          " Look at the poster. Complete the sentences using the affirmative <br/>or negative form of <i>be going</i> to and the verb.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div style="text-align: center"><img style="width: 65%"src="img/FriendsPlus/Page45/E2/1.jpg"/></div>
                <div> The Lost Streets <u style="text-decoration: dotted underline;color: grey">&emsp;<b>are going to play </b>&emsp;</u> at the Victoria Theatre.</div>
                <div><b>1.</b> The tickets #  £15.</div>
                <div><b>2.</b> The theatre #  at 7:30.</div>
                <div><b>3.</b> The concert #  at 7:15.</div>
                <div><b>4.</b> The concert #  at 9:30.</div>
                <div><b>5.</b> The Lost Streets songs #  from their old album.</div>
                <div><b>6.</b> The Lost Streets their  # their latest hit.</div>
              </div>
          `,
        answer: [
          "are going to cost",
          "isn't going to open",
          "isn't going to start",
          "is going to finish",
          "aren't going to play",
          "are going to sing",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "WB7-U7-P45-E3",
    component: T6,
    questionImage: [],
    inputSize: 250,
    exerciseKey: "img/FriendsPlus/Page45/E2/Key/answerKey.png",

    hintBox: [{
      src: [
        "<s>be</s>",
        "buy",
        "<s>eat</s>",
        "feel",
        "not like",
        "play",
        "not watch",
        "win",
      ],
      width: "100%",
      borderColor: "#69d2e4",
    }],
    stylesTextInput: { marginInline: '10px', fontSize: 24 },
    titleQuestion: [
      {
        num: "3",
        title:
          "  Complete the sentences using will or be going to and the verbs.",
        color: "#69d2e4",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div><hintbox id='0'></hintbox></div>
                <div> Mia and Ollie  <u style="text-decoration: dotted underline;color: grey">&emsp;<b>are going to eat </b>&emsp;</u> that pizza later.I know you <u style="text-decoration: dotted underline;color: grey">&emsp;<b>’ll be </b>&emsp;</u> a famous singer one day. </div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> Amelia is ill, but I think she # better tomorrow.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> Guess what! I spoke to my dad last night and he # me that old guitar in the music shop.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> ‘What’s your prediction for the match?’ ‘I think our team # 3–0.’ </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> That song is terrible. I’m sure you # it.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> At the school concert on Saturday, we # some samba music.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> I # that programme on TV later. I’ve got different plans.</div></div>
              </div>
          `,
        answer: [
          "'ll feel",
          "'s going to buy",
          "will win",
          "won't like",
          "'re going to play",
          "'m not going to watch",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "WB7-U7-P45-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1100, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Write about your plans and predictions for the future.Use will and be going to and some of the ideas in the table.",
        color: "#69d2e4",
        star: 3
      },
    ],
    questions: [
      {
        title: `
          <div>
            <div style="text-align: center"><img style="width: 40%"src="img/FriendsPlus/Page45/E4/1.jpg"/></div>
            <div>
              <div><b>Plans</b></div>
              <div><textarea id="0" rows="3">I'm going to see my friend Nam on Saturday. </textarea></div>
              <div><b>Predictions</b></div>
              <div><textarea id="1" rows="3">I think I'll become a footballer one day. </textarea></div>
            </div>
          </div>
          
        `,
        answer: [],
      },
    ],
  },
}

export default json;