import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";


const json = {
  1: {
    unit: "Unit 3",
    id: "WB7-U3-P22-E1",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page22/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: "Match 1–8 with a–h.",
        color: "rgb(245, 128, 36)",
        star: 1
      },
    ],
    // titleQuestion: [{ num: '2', title: "Listen again and draw the route to Leo's school.", color: "#2E479B" }],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          { boxMatchStyle: { position: "absolute", top: 115, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //0
          { boxMatchStyle: { position: "absolute", top: 115, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //0
          { boxMatchStyle: { position: "absolute", top: 155, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //1
          { boxMatchStyle: { position: "absolute", top: 155, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //2
          { boxMatchStyle: { position: "absolute", top: 195, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //3
          { boxMatchStyle: { position: "absolute", top: 195, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //5
          { boxMatchStyle: { position: "absolute", top: 235, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //4
          { boxMatchStyle: { position: "absolute", top: 235, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //7
          { boxMatchStyle: { position: "absolute", top: 270, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //8
          { boxMatchStyle: { position: "absolute", top: 270, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //9
          { boxMatchStyle: { position: "absolute", top: 310, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //10
          { boxMatchStyle: { position: "absolute", top: 310, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //11
          { boxMatchStyle: { position: "absolute", top: 355, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //8
          { boxMatchStyle: { position: "absolute", top: 355, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //9
          { boxMatchStyle: { position: "absolute", top: 395, left: 205, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //10
          { boxMatchStyle: { position: "absolute", top: 395, left: 290, width: 20, height: 20, border: "1px solid black", borderRadius: "50%" } }, //11
        ],
        answers: ['0-3', '15-2', '1-4', '13-6', '5-8', '10-9', '12-7', '11-14'],
        initialValue: ["0-3"],
      },
      Layout: `
        <div style="background-color: rgb(209, 210, 212); border-radius: 10px; padding: 10px 5px;width: fit-content; font-size: 24px; color: black; font-weight: bold; margin: 20px 0">Our visit to Paris</div>
        <div style="font-size: 26px;">
          <div style='display: flex;'>
            <div style='width: 200px;'><b>1</b> We all went</div>
						<div><input id='0' type= 'boxMatch' /></div>
            <div><input id='1' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>a.</b> in a nice hotel.</div>
          </div>

					<div style='display: flex;'>
            <div style='width: 200px;'><b>2</b> I saw some</div>
						<div><input id='2' type= 'boxMatch' /></div>
            <div><input id='3' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>b.</b> to Paris by plane.</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 200px;'><b>3</b> We stayed</div>
						<div><input id='4' type= 'boxMatch' /></div>
            <div><input id='5' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>c.</b> some old tunnels.</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 200px;'><b>4</b> I visited the</div>
						<div><input id='6' type= 'boxMatch' /></div>
            <div><input id='7' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>d.</b> me some money to spend.</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 200px;'><b>5</b> We explored</div>
						<div><input id='8' type= 'boxMatch' /></div>
            <div><input id='9' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>e.</b> a lot of nice photos.</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 200px;'><b>6</b> My mum took</div>
						<div><input id='10' type= 'boxMatch' /></div>
            <div><input id='11' type= 'boxMatch' /></div>
						<div style='margin-left: 120px; margin-top: 4px;'><b>f.</b> home after five days.</div>
          </div>
          <div style='display: flex;'>
            <div style='width: 200px;'><b>7</b>  My dad gave</div>
						<div><input id='12' type= 'boxMatch' /></div>
            <div><input id='13' type= 'boxMatch' /></div>
						<div style='margin-left: 120px; margin-top: 4px;'><b>g.</b> Eiffel Tower.</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 200px;'><b>8</b> We came</div>
						<div><input id='14' type= 'boxMatch' /></div>
            <div><input id='15' type= 'boxMatch' /></div>
						<div style='margin-left: 120px; margin-top: 4px;'><b>h.</b> cool places from the plane.</div>
          </div>
        </div>
      `,
    },
  },
  2: {
    unit: "Unit 3",
    id: "WB7-U3-P22-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 110,
    exerciseKey: "img/FriendsPlus/Page22/E2/Key/answerKey.png",
    maxLength: 7,

    textAlign: "center",
    hintBox: [{
      src: [
        "come",
        "<s>have</s>",
        "feel",
        "find",
        "help",
        "leave",
        "look ",
        "meet",
        "travel",
        "watch",
      ],
      width: "80%",
      borderColor: "rgb(245, 128, 36)",
    }],
    titleQuestion: [
      {
        num: "2",
        title:
          " Complete the text using the past simple form of the verbs.",
        color: "rgb(245, 128, 36)",
        star: 2
      },
    ],
    questions: [
      {
        title: `
        <div><hintbox id='0'></hintbox></div>
          <div style="position: relative; margin: 10px">
            <div><img style="width:84%" src='img/FriendsPlus/Page22/E2/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 250px; left: 152px; "><input id='0' /></div>
              <div style=" position: absolute; top: 280px; left: 243px; "><input id='1' /></div>
              <div style=" position: absolute; top: 310px; left: 381px; "><input id='2' /></div>
              <div style=" position: absolute; top: 370px; left: 141px; "><input id='3' /></div>
              <div style=" position: absolute; top: 400px; left: 262px; "><input id='4' /></div>
              <div style=" position: absolute; top: 460px; left: 144px; "><input id='5' /></div>
              <div style=" position: absolute; top: 592px; left: 157px; "><input id='6' /></div>
              <div style=" position: absolute; top: 693px; left: 157px; "><input id='7' /></div>
              <div style=" position: absolute; top: 753px; left: 160px; "><input id='8' /></div>
            </div>
          </div>
        `,
        answer: ["felt", "found", "watched", "travelled", "met", "looked", "helped", "left", "came"]
      }
    ],
  },
  3: {
    unit: "Unit 3",
    id: "WB7-U3-P22-E3",
    audio: "Audios/1-03 TA 7 Friends Plus Workbook.mp3",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page22/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "<audioimage name='03'></audioimage>Listen and choose the correct answers.",
        color: "rgb(245, 128, 36)",
        star: 2
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          "  dad / uncle", //0
          "boring / brilliant", //1
          " Roman / Egyptian",
          " met / listened_to",
          " dinosaurs / scientists",
        ],
        answers: ['0-4', '1-4', '2-6', '3-6', '4-2'],
        initialValue: [],
      },
      Layout: `
              <div>
                <div style="text-align: center; margin: 10px"><img style="width:74%" src='img/FriendsPlus/Page22/E3/1.jpg'/></div>
                <div>Jack went to a museum in <b style="border: solid 2px #000000; border-radius: 50%; padding: 5px; ">Liverpool </b> / <b>Leeds</b>.<div>
                <div><b>1.</b> Jack visited the museum with his <b><input id='0' type='Circle' /></b></div>
                <div><b>2.</b> He thinks the museum is <b><input id='1' type='Circle' /></b>.</div>
                <div><b>3.</b> He explored the <b><input id='2' type='Circle' /></b> part of the museum for a long time.</div>
                <div><b>4.</b> He <b><input id='3' type='Circle' /></b> to an important man from the museum.</b>.</div>
                <div><b>5.</b> He found a DVD about <b><input id='4' type='Circle' /></b> in the museum shop.</div>
              </div>
          `,
    },
  },
  4: {
    unit: "Unit 3",
    id: "WB7-U3-P22-E4",
    audio: "Audios/1-03 TA 7 Friends Plus Workbook.mp3",
    video: "",
    component: T6,
    questionImage: [],
    hintBox: [{
      src: [
        "two",
        "three",
        "<s>five</s>",
        "twenty",
        "150",
        "2,000",
      ],
      borderColor: "rgb(245, 128, 36)",
      width: "100%"
    }],
    inputSize: 175,
    exerciseKey: "img/FriendsPlus/Page22/E4/Key/answerKey.png",
    textAlign: 'center',
    titleQuestion: [
      {
        num: "4",
        title:
          "<audioimage name='03'></audioimage>Listen again. Complete the sentences with the numbers",
        color: "rgb(245, 128, 36)",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <hintbox id="0"></hintbox>
                <div>There are <u style="text-decoration: dotted underline;">&emsp;<b>five</b>&emsp;</u> big museums in Liverpool.</div>
                <div><b>1</b> The World Museum is more than # years old.</div>
                <div><b>2</b> Jack was in the Egyptian part of the museum for nearly # hours.</div>
                <div><b>3</b> Some of the Egyptian mummies are over # years old.</div>
                <div><b>4</b> The guide talked for # minutes.</div>
                <div><b>5</b> The museum has got # cafés</div>
              </div>
          `,
        answer: ["150", "three", "2000", "twenty", "two"],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "WB7-U3-P22-E5",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1000, resize: "none", borderBottom: "none" },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "5",
        title:
          "Imagine you went to a famous museum for the day.Write sentences about what you did. Use the verbs on this page to help you.",
        color: "rgb(245, 128, 36)",
        star: 3
      },
    ],
    questions: [
      {
        title: `
          <div><textarea id="0" rows="11">I visited the Archaeological Museum in Istanbul with my family. I explored the museum with my older brother. </textarea></div>
        `,
        answer: [],
      },
    ],
  },
}

export default json;