import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 1",
    id: "WB7-U1-P5-E1",
    component: T6,
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 85,
    exerciseKey: "img/FriendsPlus/Page5/E1/Key/answerKey.png",
    maxLength: 7,

    hintBox: [
      {
        src: [
          "am",
          "Am",
          "are",
          "Are",
          "aren't",
          "aren't",
          "Is",
          "isn't",
          "isn't",
          "<s>'m</s>",
          "'m not",
          "'re",
          "'s",
        ],
        width: "500px",
        borderColor: "#77cedf",
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title: "Complete the table with the words.",
        color: "#69d2e4",
        star: 1,
      },
    ],
    textAlign: "center",
    questions: [
      {
        title: `
          <div><hintbox id="0"></hintbox></div>
          <div style="position: relative; margin-top: 10px">
            <div><img src='img/FriendsPlus/Page5/E1/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 74px; left: 123px; ">#</div>
              <div style=" position: absolute; top: 102px; left: 151px; ">#</div>
              <div style=" position: absolute; top: 175px; left: 15px; ">#</div>
              <div style=" position: absolute; top: 201px; left: 115px; ">#</div>
              <div style=" position: absolute; top: 229px; left: 147px; ">#</div>
              <div style=" position: absolute; top: 300px; left: 5px; ">#</div>
              <div style=" position: absolute; top: 329px; left: 5px; ">#</div>
              <div style=" position: absolute; top: 357px; left: 5px; ">#</div>
              <div style=" position: absolute; top: 456px; left: 55px; ">#</div>
              <div style=" position: absolute; top: 509px; left: 189px; ">#</div>
              <div style=" position: absolute; top: 483px; left: 420px; ">#</div>
              <div style=" position: absolute; top: 536px; left: 279px; ">#</div>
          </div>
        `,
        answer: [
          "'s",
          "'re",
          "'m not",
          "isn't",
          "aren't",
          "Am ",
          "Is",
          "Are",
          "am",
          "are",
          "ins't",
          "aren't",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "WB7-U1-P5-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 22 },
    inputSize: 80,
    exerciseKey: "img/FriendsPlus/Page5/E2/Key/answerKey.png",
    maxLength: 16,

    titleQuestion: [
      {
        num: "2",
        title:
          "Complete the text using the affirmative or negative form of be.",
        color: "#69d2e4",
        star: 2,
      },
    ],
    // textAlign: "center",
    questions: [
      {
        title: `
          <div style="box-shadow:grey 10px 8px; border-radius: 9px; width: 100%">
              <tr style="text-align: center; font-weight: bold">
                <td></td>
                <td style="vertical-align: middle; border: 1px solid rgb(30 192 217); border-radius: 9px 0 0 0; padding: 5px 5px;">Age</td>
                <td style="vertical-align: middle; border: 1px solid rgb(30 192 217); padding: 5px 5px;">Class</td>
                <td style="vertical-align: middle; border: 1px solid rgb(30 192 217); padding: 5px 5px;">Teacher</td>
                <td style="vertical-align: middle; border: 1px solid rgb(30 192 217); padding: 5px 5px; border-radius: 0 9px 0 0;">Interests</td>
              <tr>
              <tr>
                <td style="vertical-align: middle; border: 1px solid rgb(30 192 217); padding: 5px 5px; border-radius: 9px 0 0 0">Jessica</td>
                <td style="vertical-align: middle; border: 1px solid rgb(30 192 217); padding: 5px 5px;">12</td>
                <td style="vertical-align: middle; border: 1px solid rgb(30 192 217); padding: 5px 5px;">7b</td>
                <td style="vertical-align: middle; border: 1px solid rgb(30 192 217); padding: 5px 5px;">Mr Masani strict but funny</td>
                <td style="vertical-align: middle; border: 1px solid rgb(30 192 217); padding: 5px 5px;"><div>sport ✔</div> <div>football ✘</div></td>
              <tr>
              <tr>
                <td style="vertical-align: middle; border: 1px solid rgb(30 192 217); padding: 5px 5px; border-radius: 0 0 0 9px">David and Hasan</td>
                <td style="vertical-align: middle; border: 1px solid rgb(30 192 217); padding: 5px 5px;">13</td>
                <td style="vertical-align: middle; border: 1px solid rgb(30 192 217); padding: 5px 5px;">7A</td>
                <td style="vertical-align: middle; border: 1px solid rgb(30 192 217); padding: 5px 5px;">Ms Davis not strict</td>
                <td style="vertical-align: middle; border: 1px solid rgb(30 192 217); padding: 5px 5px; border-radius: 0 0 9px 0"><div>photography ✔</div> <div> films✘</div></td>
              <tr>
          </div>
          <div style="width: 787px; margin-top: 33px">
            My name 's Jessica Burton and I <b>1. </b> # 
            twelve. I <b>2. </b> #  in class 7B at school and my teacher 
            <b>3. </b> # Mr Masani. He <b>4. </b> # very strict, but he 
            <b>5. </b> # funny! I <b>6. </b> # interested in sport, but I 
            <b>7. </b> # a football fan.
          </div>
          <div style="width: 787px; margin-top: 33px">
            Our names <b>8. </b> # David and Hasan and 
            we <b>9. </b> # thirteen. At school, we <b>10. </b> # in 
            class 7A. Our teacher <b>11. </b> # Ms Davis – she <b>12. </b> #
            very strict. We <b>13. </b> # interested in 
            photography, but we <b>14. </b> # interested in films
          </div>
        `,
        answer: [
          "'m",
          "'m",
          "is",
          "'s",
          "'s",
          "'m",
          "'m not",
          "are",
          "'re",
          "'re",
          "is",
          "isn't",
          "'re",
          "aren't",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "WB7-U1-P5-E3",
    component: T6,
    questionImage: [],

    inputSize: 90,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Complete the questions with is or are. Then write answers that are true for you.",
        color: "#69d2e4",
        star: 2,
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div><b>Are</b> you from London ? <b>No, I'm not</b></div>
              <div><b>1</b> #you thirteen? #, I #.</div>
              <div><b>2</b> #your mum strict? #, she #.</div>
              <div><b>3</b> #your birthday in May? #, it #.</div>
              <div><b>4</b> #your friends funny? #, they #.</div>
              <div><b>5</b> #red your favourite colour? #, it #.</div>
              <div><b>6</b> #you good at sport? #, I #.</div>
            </div>
        `,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "WB7-U1-P5-E4",
    component: T6,
    inputSize: 90,
    exerciseKey: "img/FriendsPlus/Page5/E4/Key/answerKey.png",
    //  
    maxLength: 5,
    hintBox: [
      {
        src: [
          "<s>her</s>",
          " hers",
          "His",
          "my",
          "our",
          "your",
          "Their",
          "Ours",
          "mine",
        ],
        borderColor: "#69d2e4",
        width: "74%",
      },
    ],
    titleQuestion: [
      {
        num: "4",
        title: "Complete the sentences with the words.",
        color: "#69d2e4",
        star: 2,
      },
    ],
    stylesTextInput: { textAlign: "center" },
    questions: [
      {
        title: `
        <div>
          <div>
            <div style="display: flex; justify-content: center">
              <hintbox id="0"></hintbox>
            </div>
          <div>
            <div style="color: grey;">Noah isn’t Katy’s brother. Robbie is <b>her</b  > brother</div>
            <div><b>1</b>. Holly and Cara are twins. # house is near the school.</div>
            <div><b>2</b>. Is that your scarf? It's similar to #.</div>
            <div><b>3</b>. Hello. I’m Daisy. What’s # name?</div>
            <div><b>4</b>. That's not my book. It’s #.</div>
            <div><b>5</b>. We like maths. It's # favourite subject.</div>
            <div><b>6</b>. That’s my uncle. # wife is a teacher.</div>
            <div><b>7</b>. I’m twelve, but # cousin is fourteen.</div>
            <div><b>8</b>. These are not your gloves. # are over there.</div>
            </div>
          </div>
        </div>`,
        answer: ["Their", "mine", "your", "hers", "our", "his", "my", "ours"],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "WB7-U1-P5-E5",
    component: T6,
    inputSize: "110px",
    exerciseKey: "img/FriendsPlus/Page5/E5/Key/answerKey.png",

    maxLength: 3,
    hintBox: [
      {
        src: [
          "<s>her</s>",
          "hers",
          "His",
          "my",
          "our",
          "your",
          "Their",
          "Yours",
          "mine",
        ],
        borderColor: "#69d2e4",
        width: "74%",
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title: "Complete the sentences with a, an, or the.",
        color: "#69d2e4",
        star: 2,
      },
    ],
    stylesTextInput: { textAlign: "center" },
    questions: [
      {
        title: `
            <div style="color: grey;">He's <b>a</b  > garderner.</div>
            <div><b>1</b>. #  pen on the desk is Mai's.</div>
            <div><b>2</b>. My father is  # engineer.</div>
            <div><b>3</b>. Ricardo's? It's  # Italian restaurant.</div>
            <div><b>4</b>. The plums are 80 pence  # kilo.</div>
            <div><b>5</b>. She's  # old woman.</div>
            <div><b>6</b>. Her teacher is from # Netherlands.</div>
            <div><b>7</b>.This is # smartphone. # smartphone is black.</div>
        `,
        answer: ["The", "an", "an", "a", "an", "the", "a", "The"],
      },
    ],
  },
};

export default json;
