import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 1",
    id: "WB7-U1-P6-E1",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    inputSize: 40,
    exerciseKey: "img/FriendsPlus/Page6/E1/Key/answerKey.png",
    maxLength: 1,
    textAlign: "center",
    isHiddenCheck: true,
    stylesTextInput: { marginInline: '1px' },
    titleQuestion: [
      {
        num: "1",
        title:
          " Look at the pictures. Write the words.",
        color: "#efa162",
        star: 1
      },
    ],
    questions: [
      {
        title: `
            <div style="font-size: 22px">
              <tr>
                <td style="padding: 20px">
                  <div style="text-align: center"><img style="width: 42%"src='img/FriendsPlus/Page6/E1/1.jpg'/></div>
                  <div >r <u>o o m</u></div>
                </td>
                <td style="padding: 20px">
                  <div style="text-align: center"><img style="width: 42%"src='img/FriendsPlus/Page6/E1/2.jpg'/></div>
                  <div ><b>1</b> m # # # #</div>
                </td>
              </tr>
              <tr>
                <td style="padding: 20px">
                  <div style="text-align: center"><img style="width: 42%"src='img/FriendsPlus/Page6/E1/3.jpg'/></div>
                  <div ><b>2</b> t # # # # # # # # </div>
                </td>
                <td style="padding: 20px">
                  <div style="text-align: center"><img style="width: 42%"src='img/FriendsPlus/Page6/E1/4.jpg'/></div>
                  <div ><b>3</b> n # # # # # # #</div>
                </td>
              </tr>
              <tr>
                <td style="padding: 20px">
                  <div style="text-align: center"><img style="width: 42%"src='img/FriendsPlus/Page6/E1/5.jpg'/></div>
                  <div ><b>4</b> s # # # # # #</div>
                </td>
                <td style="padding: 20px">
                  <div style="text-align: center"><img style="width: 42%"src='img/FriendsPlus/Page6/E1/6.jpg'/></div>
                  <div ><b>5</b> t # # # # # #</div>
                </td>
              </tr>
              <tr>
                <td style="padding: 20px">
                  <div style="text-align: center"><img style="width: 42%"src='img/FriendsPlus/Page6/E1/7.jpg'/> </div>
                  <div ><b>6</b> s # # # # # #</div>
                </td>
                <td style="padding: 20px">
                  <div style="text-align: center"><img style="width: 42%"src='img/FriendsPlus/Page6/E1/8.jpg'/></div>
                  <div ><b>7</b> b # # #</div>
                </td>
              </tr>
              
            </div>
          `,
        answer: [
          "a", "t", "h", "s",
          "i", "m", "e", "t", "a", "b", "l", "e",
          "o", "t", "e", "b", "o", "o", "k",
          "t", "u", "d", "e", "n", "t",
          "e", "a", "c", "h", "e", "r",
          "c", "i", "e", "n", "c", "e",
          "o", "o", "k",
        ],
      },
    ],
  },
  2: {
    unit: 'Unit 1',
    id: 'WB7-U1-P6-E2',
    component: T6,
    inputSize: '220px',
    exerciseKey: 'img/FriendsPlus/Page6/E2/Key/answerKey.png',

    maxLength: 13,
    hintBox: [{
      src: [
        "<s>book</s>",
        "exam",
        "exercises",
        "history",
        "homework",
        "maths",
        "notes",
        "room",
        "teacher",
      ],
      borderColor: "#efa162",
      width: "74%"
    }],
    titleQuestion: [
      {
        num: "2",
        title:
          "Read the clues and write the words.",
        color: "#efa162",
        star: 2
      },
    ],
    questions: [
      {
        title: `
        <div>
          <div style="text-align: center">
            <div style="display: flex; justify-content: center">
              <hintbox id="0"></hintbox>
            </div>
          </div>
          <div style="font-size: 25px">
            <div>You read this when you study at school. </div>
            <div style="color: grey;">⇒ book</div>
            <div><b>1</b>. When you do school work in the evening.</div>
            <div>#</div>
            <div><b>2</b>. Questions you need to answer in a school book.</div>
            <div>#</div>
            <div><b>3</b>. You learn about numbers in this subject. </div>
            <div>#</div>
            <div><b>4</b>. A place for lessons at school</div>
            <div>#</div>
            <div><b>5</b>. You write these in your notebook. </div>
            <div>#</div>
            <div><b>6</b>. When you learn about things from a long time ago</div>
            <div>#</div>
            <div><b>7</b>. You need to study a lot for this important test. </div>
            <div>#</div>
            <div><b>8</b>. Students learn things with this person. </div>
            <div>#</div>
            </div>
          </d>
        </div>`,
        answer: ["homework", "exercise", "maths", "room", "notes", "history", "exam", "teacher"]
      }
    ]
  },
  3: {
    unit: "Unit 1",
    id: "WB7-U1-P6-E3",
    component: T6,
    questionImage: [],
    exerciseKey: 'img/FriendsPlus/Page6/E3/Key/answerKey.png',
    stylesTextInput: { borderBottom: "dotted", textAlign: 'center' },
    inputSize: 70,
    titleQuestion: [
      {
        num: "3",
        title:
          " Fill in the gaps with at, in or on.",
        color: "#efa162",
        star: 2
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div>We can meet <b>in</b>  the afternoon.</div>
              <div><b>1</b> The new school year is # September.</div>
              <div><b>2</b> The first class is # 7:00 # the morning</div>
              <div><b>3</b> My maths lesson is # Friday.</div>
              <div><b>4</b> There's a lunch break # noon.</div>
              <div><b>5</b> The weather is very hot in Hồ Chí Minh City # the summer</div>
              <div><b>6</b> My sister hasn't got much homework # the weekend</div>
              <div><b>7</b> The English Club is # Sunday mornings.</div>
              <div><b>8</b> Teachers' Day is # November 20th. </div>
            </div>
        `,
        answer: ["in", "at", " in", "on", "at", "in", "at", "on", "on"]
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "WB7-U1-P6-E4",
    component: T6,
    stylesTextInput: { borderBottom: "dotted", fontSize: 20 },
    inputSize: 200,
    exerciseKey: "img/FriendsPlus/Page6/E4/Key/answerKey.png",

    hintBox: [{
      src: [
        "<s>exercise book</s>",
        "geography",
        "history",
        "music",
        "room",
        "notebook",
        "science",
        "lab",
        "student",
        "teacher",
      ],
      width: "61%",
      borderColor: "#efa162",
    }],
    titleQuestion: [
      {
        num: "4",
        title:
          " Write the words in the correct place. Then use your dictionary to write eight more words.",
        color: "#efa162",
        star: 3
      },
    ],
    textAlign: "center",
    questions: [
      {
        title: `
          <div style="position: relative;">
            <div style="text-align: center"><hintbox id="0"></hintbox></div>
            <div>
              <tr>
                <td >
                  <div>#</div>
                  <div>#</div>
                  <div>#</div>
                  <div>#</div>
                </td>
                <td></td>
                <td style="position: absolute; left: 450px">
                  <div>#</div>
                  <div>#</div>
                  <div>#</div>
                  <div>#</div>
                </td>
              </tr>
              <tr style="text-align: center"><img style="padding: 20px; width: 64%" src='img/FriendsPlus/Page6/E4/1.jpg' /></tr>
              <tr>
                <td>
                  <div>#</div>
                  <div>#</div>
                  <div>#</div>
                  <div>#</div>
                </td>
                <td></td>
                <td style="position: absolute; left: 450px">
                  <div><b><u>exercise book</u></b></div>
                  <div>#</div>
                  <div>#</div>
                  <div>#</div>
                </td>
              </tr>
            </div>
          </div>
        `,
        answer: [
          "geography", "history", "", "",
          "science lab", "music room", "", "",
          "student", "teacher", "", "",
          "notebook", "", "",
        ],
      },
    ],
  },
  //   1: {
  //     unit: "Unit 1",
  //     id: "WB7-U1-P12-E1",
  //     audio: "",
  //     video: "",
  //     component: DesignUnderLine,
  //     totalInput: 1,
  //     exerciseKey: "img/FriendsPlus/Page12/E1/Key/answerKey.png",
  //     titleImage: "",
  //     questionImage: [
  //       [{ url: "img/FriendsPlus/Page12/E1/1.jpg" }],
  //       [{ url: "img/FriendsPlus/Page12/E1/12.jpg" }],
  //       [{ url: "img/FriendsPlus/Page12/E1/13.jpg" }],
  //       [
  //         { url: "img/FriendsPlus/Page12/E1/2.jpg" },
  //         { url: "img/FriendsPlus/Page12/E1/3.jpg", input: 1, isCorrect: true },
  //         { url: "img/FriendsPlus/Page12/E1/4.jpg" },
  //       ],
  //       [
  //         { url: "img/FriendsPlus/Page12/E1/5.jpg" },
  //         { url: "img/FriendsPlus/Page12/E1/6.jpg", input: 1 },
  //         { url: "img/FriendsPlus/Page12/E1/7.jpg" },
  //       ],
  //       [
  //         { url: "img/FriendsPlus/Page12/E1/8.jpg" },
  //         { url: "img/FriendsPlus/Page12/E1/9.jpg", input: 1 },
  //         { url: "img/FriendsPlus/Page12/E1/10.jpg" },
  //       ],
  //       [{ url: "img/FriendsPlus/Page12/E1/11.jpg" }],
  //     ],
  //   },
  //   3: {
  //     unit: "Unit 1",
  //     id: "WB7-U1-P12-E3",
  //     audio: "",
  //     video: "",
  //     component: T6,
  //     questionImage: [],
  //     
  //     inputSize: 110,
  //     exerciseKey: "img/FriendsPlus/Page12/E3/Key/answerKey.png",
  //     textAlign: "center",
  //     questions: [
  //       {
  //         title: `
  //           <div>
  //             <div><img src='img/FriendsPlus/Page12/E3/1.jpg' /></div>
  //             <div><img src='img/FriendsPlus/Page12/E1/12.jpg' /></div>
  //             <div>
  //               <div>Ollie is <u style="text-decoration: dotted underline;">&emsp;<b>thirteen</b>&emsp;</u> years old.</div>
  //               <div><b>1</b> Ollie often visits his grandfather at #.</div>
  //               <div><b>2</b> Every evening, Ollie # for one or two hours.</div>
  //               <div><b>3</b> Ollie is often on his #.</div>
  //               <div><b>4</b> Ollie’s grandfather has got a lot of different #.</div>
  //               <div><b>5</b> Ollie sometimes uses his grandfather’s #.</div>
  //               <div><b>6</b> Ollie and his grandfather have got different # about phones.</div>
  //             </div>
  //           </div>
  //         `,
  //         answer: ["home", "studies", "phone", "hobbies", "camera", "ideas"],
  //       },
  //     ],
  //   },
  //   4: {
  //     unit: "Unit 1",
  //     id: "WB7-U1-P12-E4",
  //     audio: "",
  //     video: "",
  //     component: T6,
  //     questionImage: [],
  //     
  //     inputSize: 700,
  //     exerciseKey: "img/FriendsPlus/Page12/E4/Key/answerKey.png",
  //      
  //     questions: [
  //       {
  //         title: `
  //           <div>
  //             <div><img src='img/FriendsPlus/Page12/E4/1.jpg' /></div>
  //             <div>
  //               <div>What is Ollie like? <u style="text-decoration: dotted underline;">&emsp;<b>He's friendly.</b>&emsp;</u></div>
  //               <div><b>1</b> When does Ollie visit his grandfather?</div>
  //               <div>#</div>
  //               <div><b>2</b> What do Ollie and his grandfather talk about?</div>
  //               <div>#</div>
  //               <div><b>3</b> What is Ollie’s grandfather good at?</div>
  //               <div>#</div>
  //               <div><b>4</b> What does Ollie’s grandfather do every day?</div>
  //               <div>#</div>
  //               <div><b>5</b> Who do you often visit? What do you do at their home?</div>
  //               <div>#</div>
  //               <div><b>6</b> Do you spend a lot of time in front of a screen? Why / Why not?</div>
  //               <div>#</div>
  //             </div>
  //           </div>
  //         `,
  //         answer: [
  //           "He visits his grandfather after school.",
  //           "They talk about sport, cars, films and Ollie's day at school.",
  //           "He's good at maths.",
  //           "He runs.",
  //           "",
  //           "",
  //         ],
  //       },
  //     ],
  //   },
  //   5: {
  //     unit: "Unit 1",
  //     id: "WB7-U1-P12-E5",
  //     audio: "",
  //     video: "",
  //     component: T6,
  //     questionImage: [],
  //     
  //     inputSize: 175,
  //     exerciseKey: "img/FriendsPlus/Page12/E5/Key/answerKey.png",
  //     textAlign: 'center',
  //     questions: [
  //       {
  //         title: `
  //           <div>
  //             <div><img src='img/FriendsPlus/Page12/E5/1.jpg' /></div>
  //             <div>
  //               <div>Our school <u style="text-decoration: dotted underline;">&emsp;<b>doesn't allow</b>&emsp;</u> food in the science labs. (doesn’t allow / doesn’t let)</div>
  //               <div><b>1</b> My mum and dad only # me watch TV when I finish all my homework. (allow / let)</div>
  //               <div><b>2</b> Our parents # us to play video games for more than an hour a day. (don’t allow / let)</div>
  //               <div><b>3</b> Our school # phones in class. (lets / bans)</div>
  //               <div><b>4</b> Our dad # TVs in our bedrooms. We always watch the TV downstairs together. (doesn’t allow / doesn’t let)</div>
  //             </div>
  //           </div>
  //         `,
  //         answer: ["let", "don't allow", "bans", "doesn't allow"],
  //       },
  //     ],
  //   },
};

export default json;
