import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
	1: {
		unit: "Unit 8",
		id: "WB7-U8-P53-E1",
		component: T6,
		questionImage: [],
		inputSize: 250,
		exerciseKey: "img/FriendsPlus/Page53/E1/Key/answerKey.png",

		stylesTextInput: { marginInline: '10px', fontSize: 24, textAlign: 'center' },
		titleQuestion: [
			{
				num: "1",
				title:
					"  Complete the table with 'infinitives with <i>to</i>' or 'infinitives without <i>to</i>'.",
				color: "rgb(117, 207, 225)",
				star: 1
			},
		],
		hintBox: [{
			src: [
				"because",
				"and",
				"but",
				"or",
				"so",
				"although",
			],
			width: "558px",
			borderColor: "rgb(117, 207, 225)",
		}],
		questions: [
			{
				title: `
              <div>
                <div style="border: 1px solid rgb(117, 207, 225); border-radius: 20px; overflow: hidden;">
                  <tr style=" text-align: center">
                    <td style="border: 1px solid rgb(117, 207, 225); padding: 12px; width: 300px">used to, have to, ought to</td>
                    <td style="border: 1px solid rgb(117, 207, 225); padding: 12px; width: 400px"><b>1</b> &emsp; # </td>
                  </tr>
                  <tr style=" text-align: center">
                    <td style="border: 1px solid rgb(117, 207, 225); padding: 12px; width: 300px">must, can, will, should, may, had better, needn’t</td>
                    <td style="border: 1px solid rgb(117, 207, 225); padding: 12px; width: 400px"><b>2</b> &emsp; # </td>
                  </tr>
                  <tr style=" text-align: center">
                    <td style="border: 1px solid rgb(117, 207, 225); padding: 12px; width: 300px">want, need, would like</td>
                    <td style="border: 1px solid rgb(117, 207, 225); padding: 12px; width: 400px"><b>3</b> &emsp; # </td>
                  </tr>
                </div>
              </div>
          `,
				answer: [
					"infinitives without to",
					"infinitives without to",
					"infinitives with to",
				],
			},
		],
	},
	2: {
		unit: "Unit 8",
		id: "WB7-U8-P53-E2",
		component: T6,
		questionImage: [],
		inputSize: 900,
		exerciseKey: "img/FriendsPlus/Page53/E2/Key/answerKey.png",

		stylesTextInput: { marginInline: '10px', fontSize: 24 },
		titleQuestion: [
			{
				num: "2",
				title:
					"Make questions and give short answers.",
				color: "rgb(117, 207, 225)",
				star: 2
			},
		],
		questions: [
			{
				title: `
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> you / use to / commute to school / the school shuttle bus ?</br>#</br>Yes,#</br>No,#</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> you / have / work overtime / Sundays ?</br>#</br>Yes,#</br>No,#</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> you / want / take part in / Young Musician of the Year / contest ?</br>#</br>Yes,#</br>No,#</div></div>
              </div>
          `,
				answer: [
					"Did you use to commute to school by the school shuttle bus ? ", "I did", "I didn't",
					"Do you have to work overtime on Sundays?", "I do", "I don't",
					"Do you want to take part in the Young Musician of the Year contest ? ", "I do", "I don't",
				],
			},
		],
	},

	3: {
		unit: "Unit 8",
		id: "WB7-U8-P53-E3",
		audio: "",
		video: "",
		component: Circle_Write,
		exerciseKey: "img/FriendsPlus/Page53/E3/Key/answerKey.png",
		titleQuestion: [
			{
				num: "3",
				title:
					"Circle the best option to complete the sentences.",
				color: "rgb(117, 207, 225)",
				star: 2
			},
		],
		question: {
			Write: {
				inputStyle: {},
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					border: "none",
					borderRadius: "50%",
					borderColor: "transparent",
				},
				selectWordStyle: {
					padding: 5,
					border: "solid 2px",
					borderColor: "#000000",
				},
				limitSelect: 1,
				listWords: [
					"used_to / has_to / must_to ",
					"to_fly / fly / flying",
					"go / to_go / going",
					"swim / to_swim / swimming",
					"ought_to / had_better_not / should_not",
					"needn’t / don’t_need / not_need",
				],
				answers: ['0-4', '1-4', '2-0', '3-4', '4-0', '5-4'],
				initialValue: [],
			},
			Layout: `
              <div>
                <div><b>1.</b> She <b><input id='0' type='Circle' /></b> stay inside or she will pay a fine.</div>
                <div><b>2.</b> Alice wants <b><input id='1' type='Circle' /></b> to a wonderland.</div>
                <div><b>3.</b> You needn’t <b><input id='2' type='Circle' /></b> with us.</div>
                <div><b>4.</b> Would you like <b><input id='3' type='Circle' /></b>, Jane?</div>
                <div><b>5.</b> You <b><input id='4' type='Circle' /></b> work hard or you will fail the test.</div>
                <div><b>6.</b> We <b><input id='5' type='Circle' /></b> to hurry because we have much time.</div>
              </div>
          `,
		},
	},
	4: {
		unit: "Unit 8",
		id: "WB7-U8-P53-E4",
		component: T6,
		questionImage: [],
		inputSize: 120,
		textareaStyle: { resize: "none", borderBottom: "none" },

		hideBtnFooter: true,
		stylesTextInput: { marginInline: '10px', fontSize: 24, textAlign: 'center' },
		titleQuestion: [
			{
				num: "4",
				title:
					"  Complete the table with more verbs. <br/>Don't use the verbs in exercise 1 again.",
				color: "rgb(117, 207, 225)",
				star: 3
			},
		],
		hintBox: [{
			src: [
				"because",
				"and",
				"but",
				"or",
				"so",
				"although",
			],
			width: "558px",
			borderColor: "rgb(117, 207, 225)",
		}],
		questions: [
			{
				title: `
				<div><b>Don't use verb: </b>used to, have to, ought to, must, can, will, <br/>should, may, had better, needn't, want, need, would like. </div>
              <div>
                <div style="border: 1px solid rgb(117, 207, 225); border-radius: 20px; overflow: hidden; width:560px;">
                  <tr style="background-color: rgb(159, 219, 233); font-weight: bold; text-align: center">
                    <td style="padding: 10px; border: 1px solid rgb(117, 207, 225)">infinitives without to</td>
                    <td style="padding: 10px; border: 1px solid rgb(117, 207, 225)">infinitives with to</td>
                  </tr>
									<tr>
                    <td style="border: 1px solid rgb(117, 207, 225); text-align: center"><textarea id="0" rows="4"></textarea></td>
                    <td style="border: 1px solid rgb(117, 207, 225); text-align: center"><textarea id="1" rows="4"></textarea></td>
									</tr>
                </div>
              </div>
          `,
				answer: [
					"and",
					"but",
					"or",
					"so",
					"because",
					"although",
				],
			},
		],
	},
	5: {
		unit: "Unit 8",
		id: "WB7-U8-P53-E5",
		component: T6,
		questionImage: [],
		inputSize: 150,
		exerciseKey: "img/FriendsPlus/Page53/E5/Key/answerKey.png",

		stylesTextInput: { marginInline: '10px', fontSize: 24, textAlign: 'center' },
		titleQuestion: [
			{
				num: "5",
				title:
					"Complete the sentences using <i>some, any</i> or <i>a lot of / lots of</i>.",
				color: "rgb(117, 207, 225)",
				star: 1
			},
		],
		questions: [
			{
				title: `
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> There are # vehicles in streets so the air is polluted.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> We don’t see # heavy industry in this area, so we can breathe fresh air.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> # cars run on electricity, but many other cars still run on petrol.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> Is there # wind farm to produce electricity here?</div></div>
              </div>
          `,
				answer: [
					"a lot of/lots of",
					"any",
					"Some",
					"any",
				],
			},
		],
	},
}

export default json;