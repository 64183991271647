import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P61-E1",
    component: T6,
    questionImage: [],
    inputSize: 110,
    exerciseKey: "img/FriendsPlus/Page61/E1/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'center' },
    titleQuestion: [
      {
        num: "1",
        title:
          " Write the third person singular (he / she / it) form of the verbs.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
              <div>study &emsp; <u style="text-decoration: dotted underline; color: grey"><i><b>studies</b></i>&emsp;</u></div>
              <div style="display: flex">
                <div>
                  <div style="display: flex"><div style="display: inline-flex; width: 10px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"><div style="width: 100px">like</div> #</div></div></div>
                  <div style="display: flex"><div style="display: inline-flex; width: 10px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"><div style="width: 100px">collect</div> #</div></div></div>
                  <div style="display: flex"><div style="display: inline-flex; width: 10px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"><div style="width: 100px">have</div> #</div></div></div>
                  <div style="display: flex"><div style="display: inline-flex; width: 10px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"><div style="width: 100px">wash</div> #</div></div></div>
                </div>
                <div style="margin-left: 100px">
                  <div style="display: flex"><div style="display: inline-flex; width: 10px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"><div style="width: 100px">do</div> #</div></div></div>
                  <div style="display: flex"><div style="display: inline-flex; width: 10px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"><div style="width: 100px">bake</div> #</div></div></div>
                  <div style="display: flex"><div style="display: inline-flex; width: 10px; text-align: right;"><b>7. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"><div style="width: 100px">carry</div> #</div></div></div>
                  <div style="display: flex"><div style="display: inline-flex; width: 10px; text-align: right;"><b>8. </b></div><div style="margin-left: 30px; flex: 1; "> <div style="display: flex"><div style="width: 100px">draw</div>#</div></div></div>
                </div>
              </div>
          `,
        answer: [
          "likes",
          "collects",
          "has",
          "washes",
          "does",
          "bakes",
          "carries",
          "draws",
        ],
      },
    ],
  },

  2: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P61-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 22 },
    inputSize: 575,
    exerciseKey: "img/FriendsPlus/Page61/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "Write sentences using the present simple affirmative or negative.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div>Ellen / meet / her friends in the playground <br /><u style="text-decoration: dotted underline; color: grey"><i><b>Ellen meets her friends in the playground. </b></i>&emsp;</u></div>
              <div><b>1 &emsp;</b> David / do / martial arts  <br /> &emsp; #</div>
              <div><b>2 &emsp;</b> we / not watch / films on the laptop  <br /> &emsp; #</div>
              <div><b>3 &emsp;</b> you / spend / a lot of time in town <br /> &emsp; #</div>
              <div><b>4 &emsp;</b> my mum / carry / her things in a small bag <br /> &emsp; #</div>
              <div><b>5 &emsp;</b> they / not play football / in the park <br /> &emsp; #</div>
              <div><b>6 &emsp;</b> my father / not allow me / to have a phone  <br /> &emsp; #</div>
            </div>
        `,
        answer: [
          "David does martial arts.",
          "We don't watch films on the laptop.",
          "You spend a lot of time in town.",
          "My mum carries her things in a small bag.",
          "They don't play football in the park.",
          "My father doesn't allow me to have a phone.",
        ],
      },
    ],
  },

  3: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P61-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 22 },
    inputSize: 575,
    exerciseKey: "img/FriendsPlus/Page61/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "Make the sentences negative.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div>I go to bed late. <br /><u style="text-decoration: dotted underline; color: grey"><i><b>I don't go to bed late.</b></i>&emsp;</u></div>
              <div><b>1 &emsp;</b> Olga and Sasha live near the school.<br /> &emsp; #</div>
              <div><b>2 &emsp;</b> I want to watch that film.  <br /> &emsp; #</div>
              <div><b>3 &emsp;</b> You write on your blog every day. <br /> &emsp; #</div>
              <div><b>4 &emsp;</b> We know that boy’s name. <br /> &emsp; #</div>
              <div><b>5 &emsp;</b> Vadim speaks French. <br /> &emsp; #</div>
              <div><b>6 &emsp;</b> This shop opens early. <br /> &emsp; #</div>
            </div>
        `,
        answer: [
          "Olga and Sasha don't live near the school.",
          "I don't want to watch that film.",
          "You don't write on your blog every day.",
          "We don't know that boy's name.",
          "Vadim doesn't speak French.",
          "This shop doesn't open early.",
        ],
      },
    ],
  },


  4: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P61-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 22 },
    inputSize: 575,
    exerciseKey: "img/FriendsPlus/Page61/E4/Key/answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title:
          "Order the words to make present simple sentences.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div>trainers / usually / wear / I <br /><u style="text-decoration: dotted underline; color: grey"><i><b>I usually wear trainers.</b></i>&emsp;</u></div>
              <div><b>1 &emsp;</b> never / make / They / videos  <br /> &emsp; #</div>
              <div><b>2 &emsp;</b> sometimes / father / My / strict / is  <br /> &emsp; #</div>
              <div><b>3 &emsp;</b> always / Mark and Ed / outside / play <br /> &emsp; #</div>
              <div><b>4 &emsp;</b> got / has / Nuran / usually / pen / a <br /> &emsp; #</div>
              <div><b>5 &emsp;</b> stories / These / always / are / interesting <br /> &emsp; #</div>
              <div><b>6 &emsp;</b> often / We / music / to / listen  <br /> &emsp; #</div>
            </div>
        `,
        answer: [
          "They never make videos.",
          "My father is sometimes strict.",
          "Mark and Ed always play outside.",
          "Nuran has usually got a pen.",
          "These stories are always interesting.",
          "We often listen to music.",
        ],
      },
    ],
  },
  5: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P61-E5",
    component: T6,
    questionImage: [],
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page61/E5/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'center' },
    hintBox: [{
      src: [
        "do",
        "go",
        "know",
        "<s>make</s>",
        "play",
        "spend",
        "watch",
      ],
      width: "558px",
      borderColor: "#4D3B98",
    }],
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "5",
        title:
          " Write questions using the present simple form of the verbs.Then write the short answers.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div style="width: 1000px; display: flex; justify-content: center; margin-block: 10px"><hintBox id='0'></hintbox></div>
                <div>
                  <div><u style="text-decoration: dotted underline;color: grey">&emsp;<b>Do </b>&emsp;</u>  you <u style="text-decoration: dotted underline;color: grey">&emsp;<b>make </b>&emsp;</u>videos at school? Yes, <u style="text-decoration: dotted underline;color: grey">&emsp;<b>I do </b>&emsp;</u></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> ‘# your parents # a lot of time on the phone?’ ‘No, # .’</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> ‘# Richard # video games?’ ‘Yes, # .’</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> ‘# you often # TV?’ ‘Yes, # .’</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> ‘# Helen always # her homework?’ ‘No, # .’</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> ‘# your younger sisters # shopping in town?’ ‘No, # .’</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> ‘# we # the answer to that question?’ ‘Yes, # .’</div></div>
              </div>
          `,
        answer: [
          "Do", "spend", "they don't ",
          "Does", "play", "he does ",
          "Do", "watch", "I do ",
          "Does", "do", "she doesn't ",
          "Do", "go", "they don't ",
          "Do", "know", "we do",
        ],
      },
    ],
  },

  6: {
    unit: "Language Focus Practice",
    id: "WB7-LFS-P61-E6",
    component: T6,
    questionImage: [],
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page61/E6/Key/answerKey.png",

    stylesTextInput: { fontSize: 24, textAlign: 'center' },
    hintBox: [{
      src: [
        "do",
        "go",
        "know",
        "<s>make</s>",
        "play",
        "spend",
        "watch",
      ],
      width: "558px",
      borderColor: "#4D3B98",
    }],
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "6",
        title:
          "Complete the questions with who, what, where or when and do or does.",
        color: "#4D3B98",
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <div>‘<u style="text-decoration: dotted underline;color: grey">&emsp;<b> What do </b>&emsp;</u>  you listen to at home?’ ‘Hip-hop music.’</div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; ">  ‘# you live?’ ‘Not far from the school.’</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; ">  ‘# your sister finish school?’ ‘At 4:00.’</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; ">  ‘# we have lunch at school?’ ‘At 1:00.’</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; ">  ‘# Denise play tennis?’ ‘In the park.’</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; ">  ‘# you talk to on the phone?’ ‘Jane.’</div></div>
                  <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; ">  ‘# your mother do?’ ‘She’s a doctor.’</div></div>
              </div>
          `,
        answer: [
          "Where do ",
          "When does",
          "When do",
          "Where does ",
          "Who do",
          "What does",
        ],
      },
    ],
  },




}

export default json;