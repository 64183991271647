import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 3",
    id: "WB7-U3-P20-E1",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "dash", fontSize: 25 },
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page20/E1/Key/answerKey.png",
    maxLength: 9,

    textAlign: "center",
    hintBox: [
      {
        src: [
          "alive",
          "boring",
          "cruel",
          "dead",
          "<s>exciting</s>",
          "kind",
          "poor",
          "popular",
          "familiar",
          "rich",
          "uncommon",
          "unpopular",
          "useful",
          "useless",
        ],
        width: "70%",
        borderColor: "#1d71a9",
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title:
          "Write the adjectives under postive <img src='img/FriendsPlus/Page20/E1/1.jpg' style='width:35px' /> or negative <img src='img/FriendsPlus/Page20/E1/2.jpg' style='width:35px' /> ",
        color: "#1d71a9",
        star: 1,
      },
    ],
    questions: [
      {
        title: `
        <div><hintbox id='0'></hintbox></div>
          <div style='display:flex; margin: 20px'>
            <div style='margin:0px 100px'>

              <div style="color: grey;margin-left:10px">
                <div style='color:black'>
                  Positive <img src='img/FriendsPlus/Page20/E1/1.jpg' style='width:35px' />
                </div>
                <u>&ensp;exciting&emsp;</u>
              </div>
              #<br>
              #<br>
              #<br>
              #<br>
              #<br>
              #<br>
            </div>
            <div >
              <div style="color: grey;margin-left:10px">
                <div style='color:black'>
                  negative <img src='img/FriendsPlus/Page20/E1/2.jpg' style='width:35px' />
                </div>
              </div>
              #<br>
              #<br>
              #<br>
              #<br>
              #<br>
              #<br>
              #<br>
            </div>
          </div>
        `,
        answer: [
          "popular",
          "rich",
          "useful",
          "kind",
          "alive",
          "familiar",
          "boring",
          "unpopular",
          "poor",
          "useless",
          "cruel",
          "dead",
          "uncommon",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "WB7-U3-P20-E2",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page20/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: "Choose the correct words for the definitions.",
        color: "#1d71a9",
        star: 2,
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          " exciting / brilliant / terrible", //0
          "cruel / useful / useless", //1
          " popular / scary / uncommon",
          " brave / tiny / brilliant",
          " enormous / useful / awful",
          "poor / cruel / alive",
          "relaxing / familiar / enormous",
          " brilliant / cruel / dead",
        ],
        answers: ["1-4", "5-0", "6-4", "0-10", "2-10", "3-2", "7-2", "4-10"],
        initialValue: [],
      },
      Layout: `
              <div style='line-height:50px'>
                <div>When someone is nice to other people <b>brave/</b> <b style="border: solid 2px #000000; border-radius: 50%; padding: 5px; ">kind</b> / <b>uncommon</b>.<div>
                <div><b>1.</b> When something is bad, or it makes you feel unpleasant <b><input id='0' type='Circle' /></b></div>
                <div><b>2.</b> When a thing is good and helpful for doing something <b><input id='1' type='Circle' /></b>.</div>
                <div><b>3.</b> When something is rare or unusual or not frequent  <b><input id='2' type='Circle' /></b>.</div>
                <div><b>4.</b> When a person isn’t scared and is ready to do difficult or dangerous things <b><input id='3' type='Circle' /></b>.</div>
                <div><b>5.</b> When something is very bad, for example, the weather<b><input id='4' type='Circle' /></b>.</div>
                <div><b>6.</b> When a person hasn’t got much money  <b><input id='5' type='Circle' /></b>.</div>
                <div><b>7.</b> When something is easy to recognise  <b><input id='6' type='Circle' /></b>.</div>
                <div><b>8.</b> When a book or film is very good  <b><input id='7' type='Circle' /></b>.</div>
              </div>
          `,
    },
  },
  3: {
    unit: "Unit 3",
    id: "WB7-U3-P20-E3",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 24 },
    inputSize: 150,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page20/E3/Key/answerKey.png",
    maxLength: 11,

    textAlign: "center",
    hintBox: [
      {
        src: [
          "<s>boring</s>",
          "cruel",
          "enormous",
          "exciting ",
          "popular",
          "rich",
          "tiny",
        ],
        width: "93%",
        borderColor: "#1d71a9",
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title: "  Complete the text with the words in the box.",
        color: "#1d71a9",
        star: 2,
      },
    ],
    questions: [
      {
        title: `
          <div><hintbox id="0"></hintbox></div>
          <div style="position: relative; margin-block: 10px">
            <div><img style="width:93%" src='img/FriendsPlus/Page20/E3/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 250px; left: 269px; "><input id='0' /></div>
              <div style=" position: absolute; top: 384px; left: 102px; "><input id='1' /></div>
              <div style=" position: absolute; top: 486px; left: 106px; "><input id='2' /></div>
              <div style=" position: absolute; top: 622px; left: 140px; "><input id='3' /></div>
              <div style=" position: absolute; top: 690px; left: 389px; "><input id='4' /></div>
              <div style=" position: absolute; top: 893px; left: 102px; "><input id='5' /></div>
            </div>
          </div>
        `,
        answer: ["exciting", "popular", "enormous", "tiny", "rich", "cruel"],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "WB7-U3-P20-E4",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    exerciseKey: "img/FriendsPlus/Page20/E3/Key/answerKey.png",

    textAlign: "center",
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Complete the sentences with your own ideas.Use because and some of the adjectives on this page.",
        color: "#1d71a9",
        star: 3,
      },
    ],
    questions: [
      {
        title: `
          <div style="display: flex ">
            <div style="background-color: rgb(1, 103, 180); color: white; display: flex; justify-content: center; border-radius: 10px 0 0 10px; border: 1px solid rgb(1, 103, 180)">
              <span style="writing-mode: vertical-lr; transform: rotate(180deg); text-align: center">All about me...</span>
            </div>
            <div style="background-color: rgb(226, 236, 238);border-radius: 0 10px 10px 0; border: 1px solid rgb(1, 103, 180)">
              <div>
                <div style="display: flex">
                  <div style=" flex: 1; padding: 5px 20px ;"> <b>TV programmes </b></div>
                  <div style=" flex: 1; padding: 5px 20px ;" ><b>Famous people </b></div>
                </div>
                <div style="display: flex;">
                  <div style=" flex: 1; padding: 5px 20px ; " >I’m mad about <b style="color:grey">Doctor Who because it is exciting!</b></div>
                  <div style="display: flex ;flex: 1; padding: 5px 20px" >
                    <div style="flex-grow: 0">I’m a big fan of</div>
                    <div style= "display: flex; flex: 1"><input id='0' parentwidth='100%'/></div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="display: flex ; padding: 5px 20px ;flex: 1" >
                    <div style="flex-grow: 0;">I’m not mad about</div>
                    <div style= "flex: 1"><input id='0' parentwidth='100%'/></div>
                  </div>
                  <div style="display: flex ; padding: 5px 20px ;flex: 1" >
                    <div style="flex-grow: 0;">I’m not a big fan of</div>
                    <div style= "display: flex ;flex: 1"><input id='0' parentwidth='100%'/></div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style=" flex: 1; padding: 5px 20px ;"> <b>Hobbies </b></div>
                  <div style=" flex: 1; padding: 5px 20px ;" ><b>Places </b></div>
                </div>
                <div style="display: flex;">
                  <div style="display: flex ;flex: 1; padding: 5px 20px" >
                    <div style="flex-grow: 0">I really like</div>
                    <div style= "display: flex; flex: 1"><input id='0' parentwidth='100%'/></div>
                  </div>
                  <div style="display: flex ;flex: 1; padding: 5px 20px" >
                    <div style="flex-grow: 0">I’d like to visit </div>
                    <div style= "display: flex; flex: 1"><input id='0' parentwidth='100%'/></div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="display: flex ; padding: 20px ;flex: 1" >
                    <div style="flex-grow: 0;">I’m not crazy about</div>
                    <div style= "flex: 1"><input id='0' parentwidth='100%'/></div>
                  </div>
                  <div style="display: flex ; padding: 20px ;flex: 1" >
                    <div style="flex-grow: 0;">I wouldn’t like to visit </div>
                    <div style= "display: flex ;flex: 1"><input id='0' parentwidth='100%'/></div>
                  </div>
                </div>
            </div>
          </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
