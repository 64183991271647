import MC2 from '../../components/ExcerciseTypes/MultipleChoice/MC2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'WB7-U4-P30-E1',
    audio: '',
    video: '',
    component: MC2,
    fontSize: 22,
    exerciseKey: 'img/FriendsPlus/Page30/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: 'Read the text. Choose the correct answers.',
        color: '#dc2c39',
        star: 1
      }
    ],
    titleImage_2: 'img/FriendsPlus/Page30/E1/1.jpg',
    imgSize: '90%',
    isQuestionVertical: true,
    isQuestionVerticalToImage: true,
    questions: [
      {
        title: `This story is about …`,
        answers: [
          { image: "", isCorrect: false, right: "", text: "a monkey taking one photo." },
          { image: "", isCorrect: false, right: "", text: "some monkeys breaking a camera." },
          { image: "", isCorrect: true, right: "", text: "some monkeys taking a lot of photos." },
        ],
        no: 1,
        // title: '',
        type: '',
      },
    ]
  },

  2: {
    unit: "Unit 4",
    id: "WB7-U4-P30-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    inputSize: 90,
    exerciseKey: "img/FriendsPlus/Page30/E2/Key/answerKey.png",
    maxLength: 6,
    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title:
          " Read the text again. Write <i>true</i> or <i>false</i>.",
        color: "#dc2c39",
        star: 2
      },
    ],
    questions: [
      {
        title: `
        <div>
          <div><img style="width: 1040px" src="img/FriendsPlus/Page30/E1/1.jpg"/></div>
          <div>
            <div>The monkeys ….</div>
            <div>are black. <b style="color: grey"><i> true </i></b></div>
            <div><b>1</b> were extremely shy. <input id='0' /></div>
            <div><b>2</b> never came near David Slater.   <input id='1' /></div>
            <div><b>3</b> climbed on Slater’s camera.  <input id='2' /></div>
            <div><b>4</b> ran away at first because of the noise of the camera   <input id='3' /></div>
            <div><b>5</b> are very common animals.    <input id='4' /></div>
          </div>
			  </div>
        `,
        answer: ["false", "false", "true", "true", "false"],
      },
    ],
  },

  3: {
    unit: "Unit 4",
    id: "WB7-U4-P32-E3",
    component: T6,
    questionImage: [],
    inputSize: 170,
    exerciseKey: "img/FriendsPlus/Page30/E3/Key/answerKey.png",

    stylesTextInput: { textAlign: 'center' },
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the text again. Complete the sentences with one or two words from the text.",
        color: "#dc2c39",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div><img style="width: 74%" src="img/FriendsPlus/Page30/E1/1.jpg"/></div>  
              <div>
                <div> David Slater is  <u style="text-decoration: dotted underline;color: grey">&emsp;<b>46</b>&emsp;</u> years old.</div>
                <div><b>1.</b> Slater was with a # on his visit to the monkeys.</div>
                <div><b>2.</b> Slater walked with the monkeys and he also # near to them.</div>
                <div><b>3.</b> The animals were interested in the different things that Slater #. </div>
                <div><b>4.</b>  At first, some of the monkeys felt # of the noise of the camera.</div>
                <div><b>5.</b> The monkeys took # of different photos. </div>
              </div>
          `,
        answer: [
          "local guide",
          "stood",
          "was carrying",
          "scared",
          "hundreds",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "WB7-U4-P32-E4",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { fontSize: 23 },
    inputSize: 800,
    exerciseKey: "img/FriendsPlus/Page30/E4/Key/answerKey.png",

    titleQuestion: [
      {
        num: "4",
        title:
          "Answer the questions. Write complete sentences.",
        color: "#d01435",
        star: 3
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div>What is David Slater’s job? </div>
                <div><u style="text-decoration: dotted underline;color: grey">&emsp;<b>He's a wildlifephotographer.</b>&emsp;</u></div>
                <div><b>1.</b> How many days did Slater spend with the monkeys? </div>
                <div>#</div>
                <div><b>2.</b> What was one of the monkeys doing when Slater came back to his camera?</div>
                <div>#</div>
                <div><b>3.</b> Why did the monkeys like looking into the camera? </div>
                <div>#</div>
                <div><b>4.</b> Why are the photos important?</div>
                <div>#</div>
                <div><b>5.</b> Do you like looking at wildlife photos? Why / Why not? </div>
                <div>#</div>
              </div>
          `,
        answer: [
          " He spent three days with the monkeys.",
          " It was holding on to the camera and playing with it.",
          " Because they were seeing their faces for the first time.",
          " Because the monkeys are very rare and it is an important record of them.",
          "",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "WB7-U4-P30-E5",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 23 },
    hintBox: [{
      src: [
        "<s>ice-cream sticks</s>",
        "success",
        "start-up",
        "decorations",
        "although",
      ],
      borderColor: "#d01435",
      width: "100%"
    }],
    inputSize: 175,
    exerciseKey: "img/FriendsPlus/Page30/E5/Key/answerKey.png",
    textAlign: 'center',

    titleQuestion: [
      {
        num: "5",
        title:
          "<b style='color: #d01435;'>VOCABULARY PLUS </b> Complete the sentences with the words.",
        color: "#d01435",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <hintbox id="0"></hintbox>
                <div>There are many  <u style="text-decoration: dotted underline;">&emsp;<b>ice-cream sticks</b>&emsp;</u> in the fridge.</div>
                <div><b>1</b> It is not necessary to be a small # .</div>
                <div><b>2</b> I love your Christmas # - they're very artistic.</div>
                <div><b>3</b> He's proud of his daughter's # </div>
                <div><b>4</b> # the sun was shining, it wasn't very warm.</div>
              </div>
          `,
        answer: [" start-up", "decorations", "success", "Although"],
      },
    ],
  },


}

export default json;