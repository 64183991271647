import MC2 from '../../components/ExcerciseTypes/MultipleChoice/MC2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'WB7-U6-P42-E1',
    audio: '',
    video: '',
    component: MC2,
    exerciseKey: 'img/FriendsPlus/Page42/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: '  Read the article. What is the best title? Write it in the correct place.',
        color: '#dc2c39',
        star: 1
      }
    ],
    titleImage_2: 'img/FriendsPlus/Page42/E1/1.jpg',
    imgSize: '80%',
    isQuestionVertical: true,
    isQuestionVerticalToImage: true,
    questions: [
      {
        answers: [
          { image: "", isCorrect: false, right: "", text: "Father and son lost in the snow for days." },
          { image: "", isCorrect: true, right: "", text: "Teenager survives alone on mountain." },
          { image: "", isCorrect: false, right: "", text: "Boy phones emergency services from mountain. " },
        ],
        title: 'What is the best title?'
      },
    ]
  },
  2: {
    unit: "Unit 6",
    id: "WB7-U6-P42-E2",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { borderBottom: "none", fontSize: 20 },
    inputSize: 30,
    exerciseKey: "img/FriendsPlus/Page42/E2/Key/answerKey.png",
    maxLength: 1,

    isHiddenCheck: true,
    textAlign: "center",
    titleQuestion: [
      {
        num: "2",
        title:
          "Read the text again. Put the story in the correct order. Number the sentences 1–8.",
        color: "#dc2c39",
        star: 2
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div><img style="width: 80%" src="img/FriendsPlus/Page42/E1/1.jpg"/></div>
              <div style="padding-top: 15px">
                <tr>
                  <td style="padding: 0px 30px;"><b> a </b></td>
                  <td style=" border: 1px solid black; width: 40px; height: 40px">#</td>
                  <td style="padding-left: 40px">He took a different path back to the car. </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> b </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px"> He decided not to move in the dark. </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> c </b></td>
                  <td style=" border: 1px solid black; text-align: center; color: grey; font-weight: bold">1</td>
                  <td style="padding-left: 40px"> One day, Nicholas went skiing with his father. </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> d </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px">He built a shelter and found some drinking water. </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> e </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px"> He was tired and hungry, but he was OK.</td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> f </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px"> He became lost on the mountain. </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> g </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px"> He tried to light a fire. </td>
                </tr>
                <tr>
                  <td style="padding: 0px 30px;"><b> h </b></td>
                  <td style=" border: 1px solid black">#</td>
                  <td style="padding-left: 40px"> Somebody found him at last. </td>
                </tr>
              </div>
            </div>
        `,
        answer: [
          "2",
          "4",
          "5",
          "8",
          "3",
          "6",
          "7",
        ],
      },
    ],
  },

  3: {
    unit: "Unit 6",
    id: "WB7-U6-P42-E3",
    component: T6,
    questionImage: [],
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page42/E3/Key/answerKey.png",

    stylesTextInput: { textAlign: 'center', marginInline: '10px' },
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the text again. Complete the sentences with one or two words from the text.",
        color: "#dc2c39",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div><img style="width: 80%" src="img/FriendsPlus/Page42/E1/1.jpg"/></div>
                <div> Nicholas is from a <u style="text-decoration: dotted underline;color: grey">&emsp;<b>city</b>&emsp;</u> in the USA.</div>
                <div><b>1.</b> Nicholas and his dad went skiing in the month of # </div>
                <div><b>2.</b> Adam stood in the  # and waited for his son.</div>
                <div><b>3.</b> The weather that night was really  # </div>
                <div><b>4.</b> Nicholas walked to a  # and found drinking water.</div>
                <div><b>5.</b> A man found Nicholas at  # in the morning.</div>
                <div><b>6.</b> Sometimes TV survival programmes can be  # !</div>
              </div>
          `,
        answer: [
          "March",
          "car park",
          "bad",
          "river",
          "9:00",
          "useful",
        ],
      },
    ],
  },

  4: {
    unit: "Unit 6",
    id: "WB7-U6-P42-E4",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { fontSize: 23 },
    inputSize: 800,
    exerciseKey: "img/FriendsPlus/Page42/E4/Key/answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title:
          "Answer the questions. Write complete sentences.",
        color: "#d01435",
        star: 3
      },
    ],
    questions: [
      {
        title: `
        <div><img src='img/FriendsPlus/Page42/E4/1.jpg' alt=''/></div>
              <div>
                <div>How old was Nicholas?  </div>
                <div><u style="text-decoration: dotted underline;color: grey">&emsp;<b>He was seventeen.</b>&emsp;</u></div>
                <div><b>1.</b> What did Adam do when his son didn’t return?  </div>
                <div>#</div>
                <div><b>2.</b> Where did Nicholas get his ideas about survival from? </div>
                <div>#</div>
                <div><b>3.</b> How did Nicholas try to light a fire? </div>
                <div>#</div>
                <div><b>4.</b> How long was Nicholas alone on the mountain? </div>
                <div>#</div>
                <div><b>5.</b> Do you watch survival programmes? Why / Why not?  </div>
                <div>#</div>
              </div>
          `,
        answer: [
          " He phoned the emergency services.  ",
          " He got his ideas from TV survival programmes.",
          " He tried to light a fire by quickly moving two branches together.",
          " He was alone on the mountain for two nights.",
          "",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "WB7-U6-P42-E5",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    stylesTextInput: { fontSize: 23 },
    hintBox: [{
      src: [
        "bossy",
        "clever",
        "<s>competitive</s> ",
        "fit",
        "friendly",
        "reserved",
      ],
      borderColor: "#d01435",
      width: "100%"
    }],
    inputSize: 125,
    exerciseKey: "img/FriendsPlus/Page42/E5/Key/answerKey.png",
    textAlign: 'center',

    titleQuestion: [
      {
        num: "5",
        title:
          "<b style='color: #d01435;'>VOCABULARY PLUS </b> Complete the sentences with the words.",
        color: "#d01435",
        star: 2
      },
    ],
    questions: [
      {
        title: `
              <div>
                <hintbox id="0"></hintbox>
                <div>Our team leader really likes winning. She’s  <u style="text-decoration: dotted underline;">&emsp;<b>competitive</b>&emsp;</u> .</div>
                <div><b>1.</b> ‘Is Nam intelligent?’ ‘Yes, he’s very # </div>
                <div><b>2.</b> Lucy prefers to be alone. She’s # </div>
                <div><b>3.</b> He tells people what to do. He’s  # </div>
                <div><b>4.</b> ‘Can Ann run far?’ ‘Yes, she’s really # </div>
                <div><b>5.</b> Sophie is very nice and – # everyone likes her!</div>
              </div>
          `,
        answer: [" clever", "reserved", "bossy", "fit", "friendly"],
      },
    ],
  },

}

export default json;
